export default {
    'add-employee': 'إضافة موظف',
    'edit-employee': 'تعديل موظف',
    'manage-teams': 'إدارة الفرق',
    'manage-roles': 'إدارة الأدوار',
    'personal-information': 'المعلومات الشخصية',
    'employee-photo': 'صورة الموظف',
    'gender': 'الجنس: ',
    'contact-information': 'معلومات الاتصال',
    'work-information': 'معلومات العمل',
    'type': 'النوع:',
    'administrative': 'إداري',
    'medical-rep': 'مندوب',
    'number-of-vacation-days': 'عدد أيام الإجازة',
    'mobile-app-account': 'حساب تطبيق الهاتف المحمول',
    'generation': 'توليد',
    'username-on-app': 'اسم المستخدم على التطبيق',
    'password-on-app': 'كلمة المرور على التطبيق',
    'leave-it-empty-if-you-don\'t-want-to-change': 'اتركه فارغًا إذا كنت لا تريد التغيير',
    'enter-city-to-filter-areas': 'ادخل المدينة للفلترة على المناطق',
    'activated': 'تفعيل',
    'deactivated': 'إلغاء تفعيل',
    "the-selected-employee-account?": ' حساب الموظف المختار',
    'toggle-employee-account-status': 'تبديل حالة موظف',
    'view-employee-details': 'عرض تفاصيل الموظف',
    'view-the-medical-rep-is-plan': 'عرض خطة المندوب',
    'activate-deactivate-employee': 'تفعيل / إلغاء تفعيل حساب موظف',
    'delete-employee': 'حذف الموظف',
    'employee-account-status-has-been-toggled-successfully': 'تم تبديل حالة حساب الموظف بنجاح',
    'employee-account': 'حساب الموظف',
    'employee-is-added': 'تم إضافة الموظف'
}