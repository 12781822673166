<template>
	<div class="mt-2">
		<delete-dialog
            ref="deleteDialog"
            success-message='Data is deleted'
            @on-delete="needToResetShownRows = true; updateJournal()"
        >
			{{$t('JournalDeliveryOfSamples.are-you-sure-you-want-to-delete-this-transfer')}}
        </delete-dialog>
		<v-row>
			<v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.journal-delivery-of-samples')}}
                </h1>
            </v-col>

			<v-col
				cols="12"
				sm="auto"
				align-self="center"
				class="text-end"
			>
				<v-btn
					v-if="isSuperAdmin || permissionsUser.GenerateMaterials !== roleName.nothing"
					small
					:to="{ 
						name: 'supervisor-remaining-samples',
						query: { from: 'delivery' }
					}"
					color="dark-yellow white--text"
				>
					{{$t('JournalDeliveryOfSamples.remaining-materials')}}
				</v-btn>
			</v-col>
		</v-row>
		<!-- dialog -->
		<v-dialog width="400" v-model="dialog">
			<v-card>
				<div class="d-flex primary py-1">
					<v-card-title
						class="text-uppercase flex-grow-1 text-subtitle-1 justify-center pa-0 white--text"
					>
						{{ $t('edit') }}
					</v-card-title>

					<!-- close button -->
					<v-btn icon @click="dialog = false; $refs.form.resetValidation()">
						<v-icon color="white">
							mdi-close-circle-outline
						</v-icon>
					</v-btn>
				</div>

				<v-card-text class="mt-4">
					<v-row>
						<v-col cols="12" sm="6" class="py-1">
							<v-autocomplete
								v-model="sampleData.accountId"
								:items="allAccountsBrief.filter(c => c.id !== employeeId)"
								item-value="accountId"
								item-text="name"
								:disabled="submitLoading"
								:label="$t('inputs.account')"
								outlined
								dense
								hide-details
							></v-autocomplete>
						</v-col>

						<v-col cols="12" sm="6" class="py-1">
							<v-autocomplete
								v-model="sampleData.materialsAmount.materialId"
								:items="myMaterials"
								item-value="id"
								:item-text="(item) => `${item.remaining} - ${item.name}`"
								:label="$t('inputs.item')"
								outlined
								dense
								hide-details
								:disabled="submitLoading"
							></v-autocomplete>
						</v-col>

						<v-col cols="12" sm="6" class="py-1">
							<v-text-field
								v-model.number="sampleData.materialsAmount.amount"
								:label="$t('inputs.amount')"
								outlined
								dense
								hide-details
								:disabled="submitLoading"
							/>
						</v-col>

						<v-col cols="12" sm="6" class="py-1">
							<v-text-field
								v-model.number="sampleData.materialsAmount.sellPrice"
								:label="$t('inputs.price')"
								outlined
								dense
								hide-details
								:disabled="submitLoading || this.entityTab === 0"
							/>
						</v-col>

						<v-col cols="12" sm="12" class="py-1">
							<v-text-field
								v-model="sampleData.description"
								:label="$t('inputs.notes')"
								outlined
								dense
								hide-details
								:disabled="submitLoading"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-btn color="success" small @click="editItem()" :loading="submitLoading">
						{{$t('save')}}
					</v-btn>

					<v-btn color="alert-color white--text" small @click="dialog = false" :disabled="submitLoading">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-row dense align="center">
			<!-- filter -->
			<v-col cols="12" sm="6" md="3" class="mt-3">
				<bee-date-input
					v-model="date"
					:append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
					:prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
					:label="$t('inputs.date')"
					input-format-order="DD-MM-YYYY"
					dense
					:loading="loading"
                    class="mt-3"
					@click:append="
                        needToResetShownRows = true;
                        date = $i18n.locale === 'ar' ? moment(date).add(1, 'day').format('YYYY-MM-DD') : moment(date).subtract(1, 'day').format('YYYY-MM-DD');
                        getJournal(null, null,$route.query.tab);
                    "
					@click:prepend="
                        needToResetShownRows = true;
                        date = $i18n.locale === 'ar' ? moment(date).subtract(1, 'day').format('YYYY-MM-DD') : moment(date).add(1, 'day').format('YYYY-MM-DD');
                        getJournal(null, null,$route.query.tab);
                    "
				></bee-date-input>
			</v-col>

			<v-col v-if="entityTab === 1" md="2">
				<v-autocomplete 
					v-model.number="entity"
					:items="entityTypeArray"
					item-text="name"
					item-value="id"
					label="Type"
					outlined
					dense
					hide-details
					:disabled="loading || submitLoading"
					:loading="loading || submitLoading"
				/>
			</v-col>

			<v-col v-if="entityTab === 1" class="pt-0 pb-1">
				<v-btn
					@click="search()"
					color="success white--text"
					small
					:disabled="loading"
					:loading="loading || submitLoading"
				>
					{{$t('search')}} 
				</v-btn>
			</v-col>
		</v-row>
		<v-tabs
			v-model="entityTab"
			height="40"
			@change="existType = false; resetFilter(); updateTableDueTab()"
		>
			<v-tab
				v-for="item in types"
				:key="item.id"
				:disabled="loading"
			>
				{{item.name}}
			</v-tab>
		</v-tabs>
		<v-divider class="mb-2" />

		<!-- deliver / receive tabs -->
		<v-tabs
			v-model="tab"
			height="30"
			active-class="secondary lighten-1 white--text overflow-hidden rounded-t"
			@change="needToResetShownRows = true; getJournal(null, null)"
		>
			<v-tab :disabled="loading">{{$t('JournalDeliveryOfSamples.deliver')}}</v-tab>
			<v-tab :disabled="loading">{{$t('JournalDeliveryOfSamples.receive')}}</v-tab>
			<v-tabs-slider color="secondary lighten-3"></v-tabs-slider>
		</v-tabs>
		
		<!-- table -->
     	<bee-handy-smart-table
			v-model="tableInputs"
			ref="table"
			:headers="headers"
			:items="[]"
			:show-add-row="((entityTab !== 1 || existType) && permissionsUser.MaterialTransactions !== roleName.read )|| (isSuperAdmin && entityTab !== 1 || existType)"
			:loading="loading"
			:items-per-page="50"
			hide-default-footer 
			dense
			zebra
			:disable-input-fields="loading"
            fixed-header
			:height="((entityTab !== 1 || existType) && permissionsUser.MaterialTransactions !== roleName.read )|| (isSuperAdmin && entityTab !== 1 || existType) ? $vuetify.breakpoint.height - ($vuetify.breakpoint.smAndUp ? 210 : 250) : '70' "
            :options-scroll.sync="scrollOptions"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="getJournal"
			:pagination-on-scroll-auto-reset-shown="false"
			@keypress.enter="addItem()"
			@sheet-add-clicked="addItem"
			@click:row="goToStatement"
			@change-accountId="getMaterialByAccount()"
		>
			<!-- <template v-slot:item.accountId="{ item }">
				{{getAccount(item)}}
			</template> -->

			<!-- accounts -->
			<template v-slot:input.accountId="{ on, attr }">
				<v-autocomplete
					:items="allAccountsBrief.filter(c => c.id !== employeeId)"
					:item-value="entityTab !== 1 ? 'accountId' : 'name'"
					item-text="name"
					v-on="on"
					v-bind="attr"
					:readonly="materialLoading"
				></v-autocomplete>
			</template>

			<template v-slot:item.state="{ item }">
				{{ item.sellPrice !== null ? 'مباع' : 'قابل للإعادة' }}
			</template>
			
            <!-- accounts -->
			<template v-slot:input.accountId="{ on, attr }">
				<v-autocomplete
					:items="allAccountsBrief.filter(c => c.id !== employeeId)"
					item-value="accountId"
					item-text="name"
					v-on="on"
					v-bind="attr"
					:disabled="loading"
				></v-autocomplete>
			</template>

			<!-- product name -->
			<template v-slot:input.materialId="{ on, attr }">
				<v-autocomplete
					:items="myMaterials"
					item-value="id"
					:item-text="(item) => `${item.remaining} - ${item.name}`"
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>

			<template v-slot:item.actions="{ item }">
				<v-btn
					color="success"
					small
					icon
					@click="dialog = true; myItem = item; isEdit = true"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>

				<v-btn
					color="alert-color"
					small
					icon
					@click="isDeleted = true; $refs.deleteDialog.activeDeleteDialog(item.id, 'materialTransactions')"
				>
					<v-icon> mdi-delete </v-icon>
				</v-btn>
            </template>
       	</bee-handy-smart-table>

		<div
			v-if="!existType && entityTab === 1"
			class="d-flex flex-column justify-center align-center"
            :style="{ height: `${$vuetify.breakpoint.height - 300}px` }"
		>
			<template >
				<v-icon size="60" class="mb-1">mdi-invoice-list</v-icon>
				<span class="text-h6">{{$t('SamplesStatement.please-select-a-filter')}}</span>
			</template>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { roleName, entityTypeArray } from '@/helpers/enums'
import moment from 'moment'
import DeleteDialog from '@/components/DeleteDialog'
import rules from '@/validation rules'

export default {
	name: 'JournalDeliveryOfSamples',

	components: {
		DeleteDialog
	},

	watch: {
		dialog (val) {
			if (val) {
				this.sampleData.materialsAmount.materialId = this.myItem.materialId
				this.sampleData.accountId = this.myItem.accountId
				this.sampleData.materialsAmount.amount = this.myItem.in !== null ? this.myItem.in.amount : this.myItem.out.amount
				this.sampleData.description = this.myItem.description
				this.itemId = this.myItem.id
				this.sampleData.date = this.myItem.date
				this.sampleData.type = this.myItem.transactionType
				this.sampleData.materialsAmount.sellPrice = this.myItem.sellPrice
			} else {
				this.sampleData.materialsAmount.materialId = null
				this.sampleData.accountId = null
				this.sampleData.materialsAmount.amount = null
				this.sampleData.description = null
				this.itemId = null
				this.sampleData.date = null
				this.sampleData.type = null
				this.sampleData.materialsAmount.sellPrice = null
				this.isEdit = false
				this.isDeleted = false
			}
		},

		entity(val) {
			if (val === null) {
				this.existType = false
			}
		} 
	},
    data () {
        return {
			isEdit: false,
			isDeleted: false,
			submitLoading: false,
			myItem: null,
			dialog: false,
			editId: null,
			isEditItem: false,
			tab: 0,
			entityTab: 0,
			roleName,
            loading: false,
            tableInputs: {
				// date: this.moment().format('YYYY-MM-DD')
			},
			rowEditIndex: null,
			date: this.moment().format('YYYY-MM-DD'),
			serverItemsLength: 0,
			scrollOptions: {},
			types: [
                { id: 0, name: this.$t('tabs.staff') },
                { id: 1, name: this.$t('tabs.customers') },
                { id: 2, name: this.$t('tabs.external') }
            ],
			briefAccounts: [
                [], // for staff
                [], // for customers
                [], // for external
            ],

			itemId: null,
			sampleData:{
                materialsAmount:{
                    materialId: null,
                    amount: null,
					sellPrice: 0,
                },
                accountId: null,
                date: null,
                description: null,
                type: null,
            },
			entity: null,
			entityTypeArray,
			existType: false,
			materialLoading: false
        }
    },

    computed: {
        ...mapState({
            supervisors: state => state.supervisors.supervisors,
            // materials: state => state.materials.materials,
			myMaterials: state => state.materials.myMaterials,
			isSuperAdmin: state => state.auth.isSuperAdmin,
			permissionsUser: state => state.auth.youPermissions,
			employeeId: state => state.auth.userData.employeeId
        }),

        ...mapGetters({
            // getMaterialById: 'materials/getMaterialById',
            getSupervisorById: 'supervisors/getSupervisorById',
			getAccountById: 'accounts/getAccountById'
        }),

        headers () {
            let headers = [
                {
                    text: this.$t('headers.account'),
                    name: 'accountId',
                    value: 'accountId',
                    setValueExpr: val => this.accounts?.find(c => c.accountId === val)?.name,
                    type: 'string'
                },
                {
                    text: this.$t('headers.item'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.myMaterials?.find(c => c.id === val)?.name,
                    type: 'string'
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount',
					inputRules: rules.IntNumber,
                    type: 'number'
                },
				{
                    text: this.$t('headers.notes'),
                    name: 'description',
                    value: 'description',
                    type: 'string'
                },
				{
                    text: this.$t('headers.deliver-date'),
                    name: 'date',
                    value: 'date',
                    type: 'date',
                    width: "20%"
                },
				{
					text: '',
                    name: 'actions',
                    value: 'actions',
                    noInput: true,
                    noNotAvailableText: true,
                    sortable: false
				}
            ]
			if (this.tab === 0 && this.entityTab !== 0) {
				headers = [
					{
						text: this.$t('headers.account'),
						name: 'accountId',
						value: 'accountId',
						setValueExpr: val => this.accounts?.find(c => c.accountId === val)?.name,
						type: 'string'
					},
					{
						text: this.$t('headers.item'),
						name: 'materialId',
						value: 'materialId',
						setValueExpr: val => this.myMaterials?.find(c => c.id === val)?.name,
						type: 'string'
					},
					{
						text: this.$t('headers.sell-price'),
						name: 'sellPrice',
						value: 'sellPrice',
						type: 'number'
					},
					{
						text: this.$t('headers.amount'),
						name: 'amount',
						value: 'amount',
						inputRules: rules.IntNumber,
						type: 'number'
					},
					{
						text: 'الحالة',
						name: 'state',
						value: 'state',
						type: 'string',
						noInput: true,
						sortable: true
					},
					{
						text: this.$t('headers.notes'),
						name: 'description',
						value: 'description',
						type: 'string'
					},
					{
						text: this.$t('headers.deliver-date'),
						name: 'date',
						value: 'date',
						type: 'date',
						width: "20%"
					},
					{
						text: '',
						name: 'actions',
						value: 'actions',
						noInput: true,
						noNotAvailableText: true,
						sortable: false
					}
				]
			}
			if (this.entityTab === 0) {
				headers = [
					{
						text: this.$t('headers.account'),
						name: 'accountId',
						value: 'accountId',
						setValueExpr: val => this.accounts?.find(c => c.accountId === val)?.name,
						type: 'string'
					},
					{
						text: this.$t('headers.item'),
						name: 'materialId',
						value: 'materialId',
						setValueExpr: val => this.myMaterials?.find(c => c.id === val)?.name,
						type: 'string'
					},
					{
						text: this.$t('headers.amount'),
						name: 'amount',
						value: 'amount',
						inputRules: rules.IntNumber,
						type: 'number'
					},
					{
						text: this.$t('headers.notes'),
						name: 'description',
						value: 'description',
						type: 'string'
					},
					{
						text: this.$t('headers.deliver-date'),
						name: 'date',
						value: 'date',
						type: 'date',
						width: "20%"
					},
					{
						text: '',
                    	name: 'actions',
                    	value: 'actions',
                    	noInput: true,
                    	noNotAvailableText: true,
						sortable: false
					}
				]
			}
			return headers
        },

		allAccountsBrief() {
            return this.briefAccounts[this.entityTab];
        },
		
		currentUserData() {
            return (this.briefAccounts[0].length || this.briefAccounts[1].length || this.briefAccounts[2].length)
                ? this.briefAccounts[0].find(c => c.id === this.employeeId) || {}
                : {};
        },
    },

    methods: {
		editItem() {
			this.submitLoading = true
			this.$store.dispatch('materialTransactions/update', {
				id: this.itemId,
				newSampleData: this.sampleData
			}).then(() => {
				this.dialog = false
				this.needToResetShownRows = true
				this.getJournal()
			}).finally(() => {
				this.submitLoading = false
			})
		},
		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				// this.$refs.table.resetInputsFocus()
			})
		},
		goToStatement(item) {
			console.log('kp')
			if (this.isEdit === false && this.isDeleted === false) {
				this.$router.replace({
					name: 'samples-statement',
					query: {
						id: item.materialId
					}
				})
			}
        },
		
		getAllAccount(isFirstLoad) {
            if (this.allAccountsBrief.length && this.entityTab !== 1) return;

            this.loading = true;
            return this.$store.dispatch('accounts/fetchAllBrief', {
                type: this.entityTab,
				entityType: this.entity
            }).then((data) => {
                this.briefAccounts[this.entityTab].push(...data);
            }).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

		updateTableDueTab() {
            this.$refs.table.resetShownRows();
            this.$refs.table.resetInputs({ date: this.moment().format('YYYY-MM-DD') });
            this.$router.replace({ name: 'journal-delivery-of-samples', query: { tab: this.entityTab } });
			if (this.existType === false && this.entityTab !== 1) {
				this.updateJournal()
			}
        },

		async updateJournal(isFirstLoad = false) {
            this.loading = true;
            return Promise.all([
                await this.getAllAccount(),
                this.getJournal(null, true),
            ]).finally(() => {
                if (!isFirstLoad) this.loading = false;
            });
        },

		moment,
		getAccount(item) {
			const accountName = this.allAccountsBrief.find(c => c.accountId === (
				this.entityTab === 0 && this.tab === 1
					? item.fromAccountId
					: item.accountId
				)
			)
			return accountName ? accountName.name : '-'
		},

        addItem () {
			if (this.$refs.table.validate()) {
				console.log(this.currentUserData)
				this.loading = true
				var materials = {
					materialsAmount: {
						materialId: this.tableInputs.materialId,
						amount: this.tableInputs.amount,
						sellPrice: this.tableInputs.sellPrice
					},
					fromAccountId: this.tab === 0 ? this.currentUserData.accountId : this.tableInputs.accountId,
					toAccountId: this.tab === 0 ? this.tableInputs.accountId : this.currentUserData.accountId,
					// fromAccountId: 
					date: this.tableInputs.date,
					description: this.tableInputs.description,
					// sellPrice: 
				}

				this.$store.dispatch('materialTransactions/createDeliverAndReceive', { materials }).then(async() => {
					this.needToResetShownRows = true
					this.tableInputs.materialId = null
					this.tableInputs.amount = null
					await this.updateJournal()
				}).finally(() => {
					// this.$refs.table.resetInputsFocus()
				})
			}
        },

		getJournal(newOptionsScroll, isFirstLoad = false) {
            let page = 1
            const { itemsPerPage } = this.scrollOptions;
            if (newOptionsScroll) page = newOptionsScroll.page || 1;
            
            this.loading = true
			return this.$store.dispatch('materialTransactions/fetchStatement', {
                Date: this.date,
                Page: page,
                PerPage: itemsPerPage || 50,
                type: this.entityTab,
                // WithVisitTransaction: true,
				SelectInAmount: this.tab === 0,
				SelectOutAmount: this.tab === 1,
				entity: this.entity,
            }).then(({ data, total }) => {
				const items = data.map(c => ({ 
                    ...c, 
                    amount: (c.in || c.out).amount,
	            	materialId: (c.in || c.out).materialId,
                    accountId: c.accountId,
                    notes: c.description,
                }));

                if (this.needToResetShownRows) {
                    this.needToResetShownRows = false;
                    this.$refs.table.resetShownRows();
                }

                this.serverItemsLength = total;
                this.$refs.table.addScrollItems(items);
			}).finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
		},

		search() {
			this.existType = true; 
			this.needToResetShownRows = true; 
			this.updateJournal()
		},

		resetFilter () {
			this.type = null
			this.entity = null
			this.$refs.table.resetShownRows()
		},

		getMaterialByAccount () {
			let employeeId = null
			if (this.tab === 1 && this.entityTab === 0) {
				employeeId = this.allAccountsBrief.find(c => c.accountId === this.tableInputs.accountId)?.id
				this.materialLoading = true
				this.$store.dispatch('materials/fetchMyMaterial', { employeeId: employeeId }).finally(() => {
					this.materialLoading = false
				})
			}
		},
    },

    mounted () {
        this.loading = true;
		this.needToResetShownRows = true;
		this.entityTab = +this.$route.query.tab || 0;

		Promise.all([
		
			this.$store.dispatch('materials/fetchMyMaterial', { 
				employeeId: this.allAccountsBrief.find(c => c.accountId === this.tableInputs.accountId)?.id 
			}).then((data) => {
				// console.log(this.myMaterials)
			}),
			this.updateJournal(true),
        ]).finally(() => {
            this.loading = false
        })
    }
}
</script>
<style scoped>
</style>
