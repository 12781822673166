import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		visits: [],
		visitsAll: []
	},

	actions: {
		fetchAll({ commit }, { day }) {
			return window.axios.get('/visits/mine', { params: { day } })
				.then((response) => {
					commit(types.STORE_VISITS, response.data);
					return response.data;
				})
		},

		fetchAllData({ commit }, { day }) {
			return window.axios.get('/visits/mine/All', { params: { day } })
				.then((response) => {
					commit(types.StoreVisitsAll, response.data);
					return response.data;
				})
		},

		fetchAllDueDates({ commit }, { fromDate, toDate }) {
			return window.axios.get('/visits/mine/All/betweenTwoDates', { params: { fromDate, toDate } })
				.then((response) => response.data)
		},

		fetchById({ commit }, { id }) {
			return window.axios.get(`/visits/${id}`)
				.then((response) => {
					return response.data;
				})
		},

		create({ commit }, { entityId, coreMessage, durationInMinutes, otherSamples, notes, objections, nextVisitNotes, purpose, materialAmounts, unavailableMaterialIds, competitiveSamples, createdAt }) {
			return window.axios.post('/visits/createvisit', {
				entityId,
				coreMessage,
				durationInMinutes,
				otherSamples,
				notes,
				objections,
				nextVisitNotes,
				purpose,
				materialAmounts,
				unavailableMaterialIds,
				competitiveSamples,
				createdAt,
			})
		},
        
		update({ commit }, { id, entityId, coreMessage, durationInMinutes, otherSamples, notes, objections, nextVisitNotes, purpose, materialAmounts, unavailableMaterialIds, competitiveSamples }) {
			return window.axios.put(`/visits/${id}`, {
				entityId,
				coreMessage,
				durationInMinutes,
				otherSamples,
				notes,
				objections,
				nextVisitNotes,
				purpose,
				materialAmounts,
				unavailableMaterialIds,
				competitiveSamples
			})
		},

		delete({ commit }, { id }) {
			return window.axios.delete(`/visits/${id}`);
		},

		reset({ commit }) {
			commit(types.RESET_VISITS);
		}
	},

	mutations: {
		[types.STORE_VISITS](state, visitsAll) {
			state.visitsAll = visitsAll;
		},
		[types.StoreVisitsAll](state, visits) {
			state.visits = visits;
		},
		[types.RESET_VISITS](state) {
			state.visitsAll = [];
			state.visits = []
		}
	},

	getters: {
		getVisitById: state => id => state.visits.find(c => c.id === id)
	}
}