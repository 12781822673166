<template>
    <div class="mt-2">
        <v-row class="mb-1">
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.minimal-interface')}}
                </h1>
            </v-col>
        </v-row>
        
        <bee-handy-table
            :headers="headers"
            :items="underLimitMaterials"
            dense
            zebra
            pagination-on-scroll
            :height="$vuetify.breakpoint.height - 100"
            fixed-header
            :data-table-props="{
                singleExpand: true,
				expandedSync: expanded,
				showExpand: true,
                itemKey: 'employeeId',
                disableSort: true
			}"
            :loading="loading"
            @click:row="goToStatement"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="tab" >
                    <bee-handy-table
						:headers="head"
						:items="item.info.materialAmount"
						hide-default-footer
						class="tab"
						table-class="table-bg rounded-0 elevation-0"
                        zebra
                        :data-table-props="{
                            disableSort: true
			            }"
                        pagination-on-scroll
                        height="300"
                        
					>
                        <template v-slot:item.accountId>
                            {{ accountBrief.find(c => c.accountId === item.info.accountId).name }}
                        </template>
                    </bee-handy-table>
                </td>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    data() {
        return {
            loading: false,
            expanded: [],
            items: [
                {
                    materialId: "name",
                    maxAmount: 20,
                    minAmount: 5,
                    available: 3,
                    "info": {
                        "materialAmount": [
                            {
                                "materialId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                                "amount": 0
                            }
                        ],
                        "accountId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                    }
                }
            ],
            accountBrief: []
        }
    },
    computed: {
        ...mapState({
            underLimitMaterials: state => state.materialTransactions.underLimitMaterials,
            materials: state => state.materials.materials
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById'
        }),

        headers () {
            return [
                {
                    text: this.$t('headers.material-name'),
                    name: 'material-name',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: this.$t('headers.max-amount'),
                    name: 'max-amount',
                    value: 'maxAmount'
                },
                {
                    text: this.$t('headers.min-amount'),
                    name: 'min-amount',
                    value: 'minAmount'
                },
                {
                    text: this.$t('headers.available'),
                    name: 'available',
                    value: 'available'
                }
            ]
        },

        head () {
            return [
                {
                    text: this.$t('headers.employee'),
                    name: 'accountId',
                    value: 'accountId',
                    // setValueExpr: val => this.accountBrief.find(c => c.accountId === val)?.name
                    // accountBrief.find(c => c.accountId === '6ca7b133-6a01-46ff-8bd4-08dc8ab695df').name
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount'
                },
            ]
        }
    },

    methods: {
        goToStatement(item) {
            this.$router.replace({
                name: 'samples-statement',
                query: {
                    id: item.materialId
                }
            })
        },
    },

    created() {
        this.loading = true
        Promise.all([
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            this.$store.dispatch('accounts/fetchAllBrief', { type: 0 }).then((data) => {
                this.accountBrief = data
            })
        ])
        this.$store.dispatch('materialTransactions/fetchUnderLimitMaterial').finally(() => {
            this.loading = false
        })
    }
}
</script>

<style>
td.tab {
    box-shadow: none;
    border: 1px solid #e8dddd
}
tr.v-data-table__expanded.v-data-table__expanded__content {
    box-shadow: none !important;
}
.table-bg {
    background-color: #cddddd
}
</style>
