var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{staticClass:"pb-0"},[_c('h1',{staticClass:"text-h6 primary--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('routes.minimal-interface'))+" ")])])],1),_c('bee-handy-table',{attrs:{"headers":_vm.headers,"items":_vm.underLimitMaterials,"dense":"","zebra":"","pagination-on-scroll":"","height":_vm.$vuetify.breakpoint.height - 100,"fixed-header":"","data-table-props":{
                singleExpand: true,
				expandedSync: _vm.expanded,
				showExpand: true,
                itemKey: 'employeeId',
                disableSort: true
			},"loading":_vm.loading},on:{"click:row":_vm.goToStatement},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
			var headers = ref.headers;
			var item = ref.item;
return [_c('td',{staticClass:"tab",attrs:{"colspan":headers.length}},[_c('bee-handy-table',{staticClass:"tab",attrs:{"headers":_vm.head,"items":item.info.materialAmount,"hide-default-footer":"","table-class":"table-bg rounded-0 elevation-0","zebra":"","data-table-props":{
                            disableSort: true
			            },"pagination-on-scroll":"","height":"300"},scopedSlots:_vm._u([{key:"item.accountId",fn:function(){return [_vm._v(" "+_vm._s(_vm.accountBrief.find(function (c) { return c.accountId === item.info.accountId; }).name)+" ")]},proxy:true}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }