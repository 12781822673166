<template>
	<div id="home" class="ma-2">
		<v-row>
			<!-- 3 -->
			<v-col cols="auto" md="auto" xl="1" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width: 100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name:'day-off-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<div class="d-flex justify-space-between font-weight-bold pb-0 mb-0 pb-0 headline" style="width: 100%">  
							<span class="success--text headline font-weight-bold"> {{leaveRequests}} </span>  
							<!-- <v-spacer /> -->
							<v-icon color="secondary" style="align-self: center"> mdi-calendar-alert  </v-icon>
						</div>
						<!-- <br /> -->
						<p class="mb-0"  style="font-size: 11.4px">
							{{ $t('Home.leave-requests') }}
						</p>
					</div>
					
				</v-btn>
			</v-col>
			<!-- 4 -->
			<v-col cols="auto" md="auto" xl="1" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width:100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name: 'day-off-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{administrativeRequests}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0"  style="font-size: 11.4px">
							{{ $t('Home.administrative-day') }}
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-map-marker </v-icon>
				</v-btn>
			</v-col>
			<!-- 5 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width: 100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name:'modification-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{entityModifications}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 " style="font-size: 11.4px">
							{{ $t('Home.modification-requests') }}
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-circle-edit-outline  </v-icon>
				</v-btn>
			</v-col>
			<!-- 6 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0 px-1">
				<v-btn
					style="background-color: #0b5b6f0f; width:100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
					:to="{ name:'modification-requests' }"
					elevation="0"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{entityCreations}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 " style="font-size: 11.4px">
							{{$t('Home.customer-addition-requests')}}
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-shape-circle-plus  </v-icon>
				</v-btn>
			</v-col>
			<!-- 7 -->
			<v-col cols="auto" md="auto" xl="2" class="pb-0">
				<div
					style="background-color: #0b5b6f0f; width:100%; height: 70px"
					class="px-2 rounded-lg d-flex justify-space-between text-none"
				>
					<div class="text-start d-flex flex-column justify-center align-start ">
						<p class="font-weight-bold pb-0 mb-0 pb-0 headline" > 
							<span class="success--text headline font-weight-bold">{{entityDeletions}}</span>  
						</p>
						<!-- <br /> -->
						<p class="mb-0 " style="font-size: 11.4px">
							{{ $t('Home.customer-deletion-requests') }}
						</p>
					</div>
					<v-icon color="secondary" style="align-self: center"> mdi-delete-circle  </v-icon>
				</div>
			</v-col>
		</v-row>

		<!-- charts -->
		<v-row>
			<!-- <v-col lg="6" md="12" cols="12" class="pt-2">
				<v-row>
					
				</v-row>
			</v-col> -->
			<!-- visits -->
			<v-col cols="6" class="pa-0 py-0">
				<v-card 
					style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
					class="mt-2 mx-2 rounded" 
					height="auto" 
					width=""
				>
					<v-card-text class="">
						<div class="d-flex">
							<v-icon class="mx-1" color="secondary"> mdi-select-marker  </v-icon>
							<p class="font-weight-bold primary--text mb-0"> 
								{{$t('Home.number-of-visits-for-the-last-week-according-to-classification')}}
							</p>
						</div>
						<v-row class="py-2 px-5">
							<v-col class="pb-0 pe-1" cols="4">
								<v-autocomplete 
									v-model="cityId"
									:label="$t('inputs.city')"
									dense
									:items="cities"
									item-text="name"
									item-value="id"
									hide-details
									outlined
									@change="getWorkloadByFilter()"
									:loading="submitLoading || loading"
									:disabled="loading || submitLoading"
								/>
							</v-col>

							<v-col @click="getClass()"  class="pb-0 px-1" cols="4">
								<v-btn 
									@click="getWorkloadByFilter()" 
									color="success" 
									small 
									class="text-none" 
									:disabled="submitLoadingWorkLoad"
									:loading="submitLoadingWorkLoad"
								> 
									{{ $t('search') }} 
								</v-btn>
							</v-col>
						</v-row>
						<apexchart
							type="bar"
							height="400"
							width="100%"
							:loading="loading"
							:series="series"
							:options="options"
						></apexchart>
						<v-progress-linear
							v-if="loading"
							color="primary"
							indeterminate
							reverse
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col lg="6" md="12" cols="12" class="py-0 ">
				<v-card
					style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
					class="mt-4 mx-2 rounded" 
					height="auto" 
					width="98%"
				>
					<v-card-text>
						<!-- materials -->
						<div class="d-flex">
							<v-icon color="secondary"> mdi-ballot   </v-icon>
							<p class="font-weight-bold primary--text px-1 mb-1"> 
								{{ $t('Home.outgoing-and-remaining-materials') }}
							</p>
						</div>					
						<apexchart
							type="bar"
							height="430"
							width="97%"
							:loading="loading"
							:series="seriesMaterials"
							:options="optionsMaterials"
						></apexchart>
						<v-progress-linear
							v-if="loading"
							color="primary"
							indeterminate
							reverse
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-col>
		
			<v-col lg="12" md="12" cols="12" class="">
				<v-card
					style="	box-shadow: -4px 3px 37px -3px #e8e7e76e" 
					class="mt-4 mx-2 rounded" 
					height="250" 
					width="100%"
				>
					<v-card-text>
						<div class="d-flex">
							<v-icon color="secondary" class="mx-1"> mdi-semantic-web  </v-icon>
							<p class="mb-0 primary--text font-weight-bold"> 
								{{ $t('Home.number-of-visits-for-the-last-week-depending-on-the-number-of-customers') }} 
							</p>
						</div>
						<v-row >
							<v-col cols="3">
								<v-autocomplete
									v-model="cityIdVisitsRate"
									:items="cities"
									item-text="name"
									item-value="id"
									:label="$t('inputs.city')"
									outlined
									hide-details
									dense
								/>
							</v-col>

							<v-col>
								<v-btn 
									@click="getVisitsToEntity()" 
									color="success" 
									small 
									class="text-none"
									:disabled="submitLoading"
									:loading="submitLoading"
								>
									{{ $t('search') }}
								</v-btn>
							</v-col>
						</v-row>
						<apexchart
							type="bar"
							height="250"
							width="95%"
							:series="seriesRateVisit"
							:options="chartOptionsRateVisit"
						></apexchart>
						<v-progress-linear
							v-if="loading"
							color="primary"
							indeterminate
							reverse
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>

	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
 
	export default {
		name: 'Home',

		data(){
			return {
				fromDateVisitsToEntity:  moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
				toDateVisitsToEntity: moment().format('YYYY-MM-DD'),
				tab: 0,
				tabTopAndLessMaterials: 0,
				fromDateMaterialsChart: moment(this.moment().startOf('month')).format('YYYY-MM-DD'),
				toDateMaterialsChart: moment(this.moment().endOf('month')).format('YYYY-MM-DD'),
				visitsNumber: null,
				totalVisitsNumber: null,
				leaveRequests: 0,
				administrativeRequests: 0,
				entityModifications: 0,
				entityCreations: 0,
				entityDeletions: 0,
				cityId: null,
				fromDate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
				toDate: moment().format('YYYY-MM-DD'),
				loading: false,
				submitLoading: false,
				cityIdVisitsRate: null,
				fromDateVisitsRate: moment(moment().subtract(6, 'days')).format('YYYY-MM-DD'),
				toDateVisitsRate: moment().format('YYYY-MM-DD'),
				submitLoadingWorkLoad: false,
				isRTL: localStorage.lang === 'ar'
			}
		},
		
		computed:{
			...mapState({
				medicalreps: state => state.medicalreps.medicalreps,
				// employeeId: state => state.auth.userData.id,
				classification: state => state.workload.classification,
				workloadVisits: state => state.statisticsHome.workloadVisits,
				visitsAchievements: state => state.statisticsHome.visitsAchievements,
				visitCount: state => state.statisticsHome.visitCount,
				materialsStat: state => state.statisticsHome.materialsStat,
				materials: state => state.materials.materials,
				teams: state => state.statisticsHome.teams,
				cities: state => state.cities.cities,
				entitiesVisits: state => state.statisticsHome.entitiesVisits
			}),

			...mapGetters({
				getClassificationById: 'workload/getClassificationById',
				getMaterialById: 'materials/getMaterialById',
				getTeamById: 'teams/getTeamById',
				getRegionAllById: 'regions/getRegionAllById'
			}),

			regionsName() {
				// console.log (this.entitiesVisits.map(c => this.getRegionAllById(c.visits.regionId)?.name))/
				return this.entitiesVisits.slice(0, 20).map(c => this.getRegionAllById(c.visits.regionId)?.name)
			},

			// classification
			series() {
				return [
					{
						name: 'عدد الزيارات',
						data: this.workloadVisits.map(c => c.visitCount)
					},
					{
						name: 'عدد العملاء',
						data: this.workloadVisits.map(c => c.entityCount)
					},

				]
			},

			options() { 
				return {
					chart: {
						id: 'vuechart-example'
					},
					xaxis: {
						categories: this.workloadVisits.map(c => this.getClassificationById(c.workloadId).name)
					},
					yaxis: {
						opposite: this.isRTL
					}
				}
			},
			// labels
			labels () {
				return	this.classification.map(c => c.name)
			},

			// Remaining
			seriesMaterials() {
				return [
					{
						name: 'الكمية المسلمة',
						data: this.materialsStat?.materialState.slice(0, 10).map(c => c.amountDeliverd)
						
					},
					{
						name: 'الكمية المتبقية',
						data: this.materialsStat?.materialState.slice(0, 10).map(c => c.amountInWarehouse)
					}
				]
			},
			optionsMaterials() {
				// return {
				// 	chart: {
				// 		id: 'vuechart-example'
				// 	},
				// 	xaxis: {
				// 		categories: this.materialsStat?.materialState.slice(0, 20).map(c => this.getMaterialById(c.materialId)?.name)
				// 	},
                //     dataLabels: {
				// 		enabled: true,
				// 		offsetY: -20,
				// 		style: {
				// 			fontSize: '12px',
				// 			colors: ["#304758"]
				// 		}
				// 	},
				// 	// colors: ['#1ab7ea', '#FF6103', '#39539E'],
				// }
                return {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: this.materialsStat?.materialState.slice(0, 10).map(c => this.getMaterialById(c.materialId)?.name),
                    },
                    yaxis: {
						opposite: this.isRTL
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                            	return val 
							}
                    	}
                	}
            	}
        },
			// labels() {
			// 	return	this.classification.map(c => c.name)
			// },

			// new (medRep-ent-visits) 
        	seriesRateVisit() {
                return [
                    {
                        name: 'عدد الزيارات',
						data: this.entitiesVisits.slice(0, 20).map(c => c.visits.count)
                    }, 
                    {
                        name: 'عدد العملاء',
						data: this.entitiesVisits.slice(0, 20).map(c => c.entities.count)
                    }, 
                ]
			}, 
          	chartOptionsRateVisit () {
				return {
					chart: {
						type: 'bar',
						height: 450,
						stacked: true,
					},
					stroke: {
						width: 1,
						colors: ['#fff']
					},
					xaxis: {
						categories: this.regionsName,
						labels: {
							formatter: function (val) {
							return val
							}
						}
					},
					yaxis: {
						opposite: this.isRTL,
						title: {
							text: undefined
						},
					},
					tooltip: {
					y: {
						formatter: function (val) {
						return val
						}
					}
					},
					fill: {
					opacity: 1
					},
					legend: {
					position: 'top',
					horizontalAlign: 'left',
					offsetX: 40
					}
          		}
			}
        },

		methods:{
			moment,
			
			getWorkloadByFilter() {
				this.submitLoadingWorkLoad = true
				this.$store.dispatch('statisticsHome/fetchWorkloadVisits', { 
					fromDate: this.fromDate, 
					toDate: this.toDate,
					cityId: this.cityId
				}).finally(() => {
					this.submitLoadingWorkLoad = false
				})
			},

			getVisitsToEntity () {
				this.submitLoading = true
				this.$store.dispatch('statisticsHome/fetchEntitiesVisits', {
					cityId: this.cityIdVisitsRate,
					fromDate: this.fromDateVisitsRate,
					toDate: this.toDateVisitsRate,
				}).finally(() => {
					this.submitLoading = false
				})
			}
		},

		created() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('cities/fetchCityMine').then((data) => {
					this.cityIdVisitsRate = data[0].id
					this.cityId = data[0].id
				}),
				// this.$store.dispatch('cities/fetchCityMine').then((data) => {
				// this.cityIdVisitsRate = data[0].id
                // }),
                !this.classification.length ? this.$store.dispatch('workload/fetchAll') : null,
                !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
                !this.materials.length ? this.$store.dispatch('materials/fetchAll', {}) : null,
				
				window.axios.get('/home').then(response => {
					this.leaveRequests = response.data.leaveRequests;
					this.administrativeRequests = response.data.administrativeRequests;
					this.entityModifications = response.data.entityModifications;
					this.entityCreations = response.data.entityCreations;
					this.entityDeletions = response.data.entityDeletions;
					this.visitsNumber = response.data.visitsYesterday;
					this.totalVisitsNumber = response.data.visitTargets;
				}),
		
				// !this.visitsAchievements.length ? this.$store.dispatch('statisticsHome/fetchVisitsAchievements') : null

				this.$store.dispatch('statisticsHome/fetchMaterials', { 
					fomDate: this.fromDateMaterialsChart,
					toDate: this.toDateMaterialsChart
				}),
				// !this.teams.length ? this.$store.dispatch('statisticsHome/fetchTeams') : null,
				this.$store.dispatch('statisticsHome/fetchEntities'),
				
			]).then(() => {
				Promise.all([
					this.$store.dispatch('regions/fetchAllRegions', { cities: this.cities }),
					this.$store.dispatch('statisticsHome/fetchWorkloadVisits', { 
						fromDate: this.fromDate, 
						toDate: this.toDate,
						cityId: this.cities[0].id
					}),
					this.$store.dispatch('statisticsHome/fetchEntitiesVisits', {
						cityId: this.cities[0].id,
						fromDate: this.fromDateVisitsRate,
						toDate: this.toDateVisitsRate,
					})
				]).then(() => {
					this.loading = false
				}).catch(() => {
					this.loading = false
				})
			}).catch(() => {
				this.loading = false
			})
		},

		metaInfo: {
			title: 'Octopharma'
		},
	}
</script>
<style scoped>
.btn-num {
	font-size: 30px;
	font-weight: bolder;
	background-color: #12da1c;
}
.fs-m {
	font-size: 23px;
	font-weight: bolder;
}
</style>
