<template>
    <div>
        <!-- edit samples dialog -->
        <v-dialog
            v-model="sampleDialog"
            transition="dialog-transition"
            max-width="700"
        >
            <v-card elevation="0" >
                <div class="d-flex justify-end background-title-card mb-3">
                    <v-card-title
                        class="d-flex justify-center w-100 text-uppercase text-body-2 justify-center py-0 white--text"
                    >
                        Deliver materials
                    </v-card-title>
                    <!-- close icon button -->
                    <v-btn icon @click="sampleDialog = false">
                        <v-icon color="white" >
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>
                <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <v-card-text class="py-0">
                        <!-- dialog content -->
                        <v-container>
                            <v-row>
                                <!-- type -->
                                <v-col md="4" sm="6" cols="12" class="py-1">
                                    <v-autocomplete
                                        label="Employee"
                                        v-model="sampleData.type"
                                        :items="types"
                                        item-value="id"
                                        item-text="name"
                                        required
                                        ref="accountId"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        @change="getAccount(sampleData.type)"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- accountId -->
                                <v-col md="4" sm="6" cols="12" class="py-1">
                                    <v-autocomplete
                                        label="Employee"
                                        v-model="sampleData.accountId"
                                        :items="accounts"
                                        item-value="accountId"
                                        item-text="name"
                                        :return-object="false"
                                        :rules="rules.required"
                                        required
                                        ref="accountId"
                                        @keydown.alt.backspace="$refs.accountId.internalSearch = null"
                                        outlined
                                        dense
                                        hide-details="auto"
                                        :loading="loading || loadingAccount"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- product name -->
                                <v-col md="4" sm="6" cols="12" class="py-1">
                                    <v-autocomplete
                                        label="Product name"
                                        v-model="sampleData.materialsAmount.materialId"
                                        :items="materials"
                                        item-value="id"
                                        :item-text="getFullMaterialName"
                                        :return-object="false"
                                        :rules="rules.required"
                                        required
                                        ref="productName"
                                        @keydown.alt.backspace="$refs.productName.internalSearch = null"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>

                                <!-- samples amount -->
                                <v-col md="4" sm="6" cols="12" class="py-1">
                                    <v-text-field
                                        type="number"
                                        min='1'
                                        label="Samples amount"
                                        v-model.number="sampleData.materialsAmount.amount"
                                        :rules="rules.number"
                                        required
                                        ref="samplesAmount"
                                        @keydown.alt.backspace="$refs.samplesAmount.internalValue = null"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Deliver date -->
                                <v-col md="4" sm="6" cols="12" class="">
                                    <bee-date-picker
                                        v-model="sampleData.date"
                                        input-label="Deliver date"
                                        elevation="2"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            dense: true,
                                            outlined: true,
                                        }"
                                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                        min="1950-01-01"
                                        :rules="rules.required"
                                    ></bee-date-picker>
                                </v-col>
                                <!-- <v-col md="4" sm="6" cols="12" class="py-1">
                                    <v-menu
                                        ref="menu"
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="sampleData.date"
                                                label="Deliver date"
                                                prepend-icon="mdi-calendar"
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="rules.date"
                                                required
                                                ref="inputeDate"
                                                @keydown.alt.backspace="$refs.inputeDate.internalValue = null"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="sampleData.date"
                                            :active-picker.sync="activePicker"
                                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                            min="1950-01-01"
                                            @change="save"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col> -->

                                <!-- notes -->
                                <v-col md="4" sm="6" cols="12" class="">
                                    <v-text-field
                                        label="Notes"
                                        v-model="sampleData.description"
                                        ref="notes"
                                        @keydown.alt.backspace="$refs.notes.internalValue = null"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <!-- <v-divider/> -->
                    <v-card-actions class="justify-end">
                        <v-btn
                            type="submit"
                            color="success"
                            elevation="0"
                            :loading="submitLoading"
                            text
                            @click="needToResetItems = true"
                        >
                            save
                        </v-btn>
                        <v-btn
                            @click="$refs.form.resetValidation(); deliverSamples();"
                            elevation="0"
                            text
                            :disabled="submitLoading"
                            color="alert-color"
                        >
                            Reset
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>

        <!-- buttons -->
        <div class="d-flex" :class="{'justify-end': ($route.name === 'supervisors-samples') }">
            <h2 v-if="0" class="justify-start flex-grow-1 text-h5 primary--text text-capitalize">Samples</h2>

            <div v-if="0">
                <v-btn
                    small
                	:to="{ name: 'supervisor-remaining-samples' }"
                    color="dark-yellow white--text"
                >
                    Remaining Samples
                </v-btn>
            </div>
        </div>
        
        <!-- dialogs is decleared here to use as ref -->
        <!-- sample card is decleared here to use it as ref -->
        <!-- <sample-card
            title="deliver samples"
            ref="sampleCard"
            @on-save="filter"
        ></sample-card> -->

        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
          ref="deleteDialog"
          @on-delete="needToResetItems = true; filter()"
          :success-message="$t('data-is-deleted')"
        >
            <template v-slot:default>
                <p class="mb-2">
                    {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{ $t('delete') }}</span> sample data?
                </p>
            </template>
        </delete-dialog>
        <!---->

        <!-- filter -->
        <v-row class="mx-1 my-1" align=center>
            <!-- Start date -->
            <v-col cols="6" md="3" class="px-0 pb-0">
                <bee-date-picker
                    v-model="startDate"
                    input-label="Start date"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true
                    }"
                ></bee-date-picker>
            </v-col>

            <!-- End date -->
            <v-col cols="6" md="3" class="pb-0">
                <bee-date-picker
                    v-model="endDate"
                    input-label="End date"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true
                    }"
                ></bee-date-picker>
            </v-col>
            <!-- <v-col cols="6"></v-col> -->
            <!-- product name -->
            <v-col md="2" sm="6" cols="12" class="pb-0 ps-0">
                <v-autocomplete
                    v-model="materialId"
                    :items="materials"
                    :item-text="getFullMaterialName"
                    item-value="id"
                    :return-object="false"
                    label="Product"
                    dense
                    clearable
                    hide-details
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                ></v-autocomplete>
            </v-col>

            <!-- pharmaceutical form -->
            <v-col md="3" sm="6" cols="12" class="pb-0 ps-0">
                <v-autocomplete
                    v-model="materialFormId"
                    :items="materialForms"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    label="Pharmaceutical form"
                    dense
                    clearable
                    hide-details
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                ></v-autocomplete>
            </v-col>

            <v-col md="2" cols="12" class="d-flex ps-0">
                <v-icon class="me-1"> mdi-account-tie </v-icon>
                <v-autocomplete
                    v-model="type"
                    :items="types"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    label="type"
                    dense
                    hide-details
                    clearable
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                    @change="getAccount(type)"
                ></v-autocomplete>
            </v-col>

            <!-- medical rep name -->
            <v-col md="2" cols="12" class="d-flex ps-0">
                <v-icon class="me-1"> mdi-account-tie </v-icon>
                <v-autocomplete
                    v-model="accountId"
                    :items="accountId"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    :label="tabs.label"
                    dense
                    hide-details
                    clearable
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                ></v-autocomplete>
            </v-col>
            <v-spacer />

            <!-- buttons -->
            <v-col cols="auto" class="pb-1 pt-0 py-md-0 px-1">
                <v-btn
                    color="success"
                    class="px-sm-4 "
                    :loading="loading"
                    :disabled="loading || isAllFieldsEmpty"
                    @click="needToResetItems = true; isFiltered = true; filter()"
                    small
                >
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>
                <v-btn
                    class="px-sm-8 mx-2"
                    :disabled="!isFiltered || loading"
                    @click="needToResetItems = true; clearFilter(); filter()"
                    color="alert-color white--text"
                    small
                >
                    Reset
                </v-btn>
            </v-col>

            <v-divider vertical class="my-0 my-md-3" />

            <v-col cols="auto" class="pb-1 pt-0 py-md-0 px-1">
                <!-- deliver samples -->
                <!-- setLabel() -->
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.MaterialTransactions === roleName.action"
                    :to="{
                        name: 'journal-items'
                    }"
                    small
                    color="dark-yellow white--text"
                    class="mx-2"
                >
                    <v-icon small class="me-2">mdi-package-check</v-icon>
                    Deliver samples
                </v-btn>

                <!-- view chart -->
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.Charts === roleName.action"
                    small
                    :to="{ name: 'statistics-chart', params: { type: 'samples' } }"
                    color="dark-yellow white--text"
                >
                    <v-icon small class="me-2">mdi-chart-bar</v-icon>
                    View chart
                </v-btn>
            </v-col>
        </v-row>

        <!-- tables -->
        <!-- medical reps table will appear for supervisor -->
        <bee-handy-table
            ref="table"
            :headers="headersOfMedicalRepsDelivery"
            fixed-header
            :height="$vuetify.breakpoint.height - 205"
            :items="[]"
            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="filter"
            zebra
            dense
        >
            <!-- action -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex">
                    <tooltip text="Edit sample" top>
                        <v-btn color="edit-color" icon small @click="editSample(item)">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete sample" top>
                        <v-btn color="alert-color" text small @click="activeDeleteDialog(item.id, 'materialTransactions')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
        <v-data-table
            v-if="0"
            :headers="headersOfMedicalRepsDelivery"
            :items="!loading ? (isFiltered ? employeesFiltered : transactions) : []"
            :items-per-page="20"
            :options.sync="options"
            :server-items-length="totalTransactions"
            :footer-props="{ 'items-per-page-options': [5, 10, 15, 20, 40] }"
            :loading="loading"
            disable-sort
            loading-text="Loading... Please wait"
            class="elevation-3"
        >
            <!-- date -->
            <template v-slot:item.date="{ item }">
                {{ moment(item.date) || "N/A"}}
            </template>

            <!-- product -->
            <template v-slot:item.product="{ item }">
                {{ dig('getMaterialById', item.materialId, 'name') || 'N/A' }}
            </template>

            <!-- Volume -->
            <template v-slot:item.dose="{ item }">
                {{ dig('getMaterialById', item.materialId, 'dose') || 'N/A' }}
            </template>

            <!-- pharmaceutical form -->
            <template v-slot:item.pharmaceuticalForm="{ item }">
                {{ 
                    dig(
                        'getMaterialFormById', 
                        dig('getMaterialById', item.materialId, 'materialFormId') || null, 
                        'name'
                    ) || 'N/A' 
                }}
            </template>

            <!-- reseceived samples -->
            <template v-slot:item.reseceivedSamples="{ item }">
                {{
                    item.amount || "N/A"
                }}
            </template>

            <!-- City -->
            <template v-slot:item.city="{ item }">
                {{ 
                    dig(
                        'getCityNameBySectorId',
                        dig('getMedicalRepById', item.toEmployeeId, 'sectorId'),
                        'cityName'
                    )
                    || 'N/A' 
                }}
            </template>

            <!-- medical rep name -->
            <template v-slot:item.medicalRepName="{ item }">
                {{ dig('getMedicalRepById', item.toEmployeeId, 'name') }}
            </template>

            <!-- action -->
            <template v-slot:item.action="{item}">
                <div class="d-flex">
                    <tooltip text="Edit sample" top>
                        <v-btn icon small @click="editSample(item)">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete sample" top>
                        <v-btn text small @click="activeDeleteDialog(item.id, 'materialTransactions')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </v-data-table>

        <!-- Supervisors table will appear for admin -->
        <bee-handy-table
            v-if="getUserType === 'admin'"
            ref="table"
            :headers="headersOfSupervisorsDelivery"
            :items="!loading ? (isFiltered ? employeesFiltered : transactions) : []"
            :loading="loading"
            fixed-header
            :height="$vuetify.breakpoint.height - 205"

            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="scrollOptions"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="filter"
            dense
            zebra
        >
            <!-- action -->
            <template v-slot:item.action="{item}">
                <div class="d-flex">
                    <tooltip text="Edit sample" top>
                        <v-btn icon small @click="editSample(item)" color="edit-color">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip text="Delete sample" top>
                        <v-btn icon small @click="activeDeleteDialog(item.id, 'materialTransactions')" color="alert-color">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import SampleCard from '../../components/SampleCard'
import Tooltip from '../../components/Tooltip'
import DeleteDialog from '../../components/DeleteDialog'
import rules from '../../validation rules'
import { roleName } from '@/helpers/enums'

export default {
    data(){
        return {
            type: null,
            types: [
                {
                    name: 'Staff',
                    id: 0
                },
                {
                    name: 'Customers',
                    id: 1
                },
                {
                    name: 'External',
                    id: 2
                }
            ],
            roleName,
            tab: 0,
            tabs:{
                titles: ['medical reps delivery schedules', 'Supervisors delivery schedules'],
                label: 'Employee'
            },

            // filter data
            materialId: null,
            materialFormId: null,
            accountId: null,
            startDate: null,
            endDate: null,

            // pagination props
            options: {},
            totalTransactions: 0,

            // helpers
            loading: false,
            submitLoading: false,
            filterLoading: false,
            isFiltered: false,

            // filtered employees
            employeesFiltered: null,

            sampleDialog: false,
            rules,

            title: '',
            sampleId: null,
            
            sampleData:{
                materialsAmount:{
                    materialId: null,
                    amount: null,
                },
                accountId: null,
                date: null,
                description: null,
                type: null
            },
            
            label: null,

            scrollOptions: {},
            serverItemsLength: 0,
            needToResetItems: false,
            accounts: [],
            loadingAccount: false
        }
    },
    
    components:{
        // SampleCard,
        Tooltip,
        DeleteDialog
    },

    mounted() {
        this.loading = true;
        this.filterLoading = true;
        var promises = []
        if (this.getUserType === 'admin'){
            promises.push(this.$store.dispatch('supervisors/fetchAll', true));
        }
        else if (this.getUserType === 'supervisor'){
            promises.push(this.$store.dispatch('medicalreps/fetchAll', true));
        }
        promises.push(
            this.$store.dispatch('cities/fetchCityMine').then((cities) => {
                this.$store.dispatch('sectors/fetchAll').then(() => {
                    this.$store.dispatch('sectors/fetchSectorCities', { cities });
                })
            }),
            this.$store.dispatch('materials/fetchAll'),
            this.$store.dispatch('materialForms/fetchAll'),
            this.$store.dispatch('medicalreps/fetchUserForUserLogin'),
            this.$store.dispatch('medicalreps/fetchAll', true),
            this.filter()
        )

        Promise.all(promises).finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    watch: {
        options () {
            this.filter();
        }
    },

    methods: {
        dig(getterName, id, prop){
            var object = this[getterName](id);
            if (prop === 'cityName') {
                var array = object;
                return array.join(', ');
            }
            return object ? object[prop] : null;
        },

        getFullMaterialName(material) {
            var details = [
                this.getMaterialFormById(material.materialFormId)?.name,
                material.dose,
                material.strength
            ];

            return `${material.name} (${details.filter(c => c != null).join(' ')})`;
        },

        // sample card function
        // deliverSamples(){
        //     this.$refs.sampleCard.deliverSamples();
        // },
        deliverSamples(){
            this.resetInputs();
            this.title = 'Deliver samples';
            // this.setLabel();
            this.sampleData.date = window.moment().format("YYYY-MM-DD");
        },

        // editSample(sample){
        //     this.$refs.sampleCard.editSample(sample);
        // },
        
        // get data function
        moment(date){
            return window.moment(date).format('YYYY-MM-DD') || null
        },

        fetchTransactions(){
            this.loading = true;
            var type;
            if (this.getUserType === 'admin') { type = 0; }
            else if (this.getUserType === 'supervisor'){ type = 1; }

            this.$store.dispatch('materialTransactions/fetchByFilter', {
                queryType: type

            })
            .then(() => {
                this.loading = false
            })
        },

        clearFilter(){
            this.materialId = null;
            this.materialFormId = null;
            this.accountId = null;
            this.startDate = null;
            this.endDate = null;

            this.isFiltered = false;
        },
        
        filter(newOptionsScroll){
            this.loading = true;
            let page = 1;

            const { itemsPerPage } = this.scrollOptions;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }
            var type;
            if (this.getUserType === 'admin') { type = 0; }
            else if (this.getUserType === 'supervisor'){ type = 1; }
            // const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            return this.$store.dispatch('materialTransactions/fetchByFilter', {
                queryType: type,
                materialId: this.materialId,
                materialFormId: this.materialFormId,
                accountId: this.accountId,
                fromDate: this.startDate,
                ToDate: this.endDate,
                page: page,
                perPage: itemsPerPage,
            })
            .then((response) => {
                if (this.needToResetItems) { 
                    this.$refs.table.resetShownRows();
                    this.needToResetItems = false
                }
                
                this.$refs.table.addScrollItems(response.data);
                this.serverItemsLength = response.total;
                this.employeesFiltered = response.data;
                // this.totalTransactions = response.total;
                this.isFiltered = true
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // delete dialog function
        activeDeleteDialog(id, moduleType){
            this.$refs.deleteDialog.activeDeleteDialog(id, moduleType);
        },

        // for edit dialog
        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                if (!this.sampleId){
                    this.$store.dispatch('materialTransactions/create', this.sampleData)
                    .then(() => {
                        this.message = "Samples is delivered";
                        // this.$emit('on-save');
                        this.filter()
                        this.sampleData.materialsAmount.amount = null;
                        this.sampleData.description = null;
                        this.$refs.form.resetValidation();
                    })
                    .catch((error) => {
                        this.message = error.response.data.message;
                    })
                    .finally(() => {
                        this.snackbar = true;
                        this.submitLoading = false;
                        this.sampleDialog = false;
                        this.focusOn('accountId');
                    });
                }
                else if (this.sampleId){
                    this.$store.dispatch('materialTransactions/update', {
                        id: this.sampleId,
                        newSampleData: this.sampleData
                    })
                    .then(() => {
                        this.message = "Samples is edited";
                        // this.$emit('on-save');
                        this.filter()
                        this.resetInputs();
                        this.deliverSamples();
                        this.sampleDialog = false
                    })
                    .catch((error) => {
                        this.message = error.response.data.message;
                    })
                    .finally(() => {
                        this.snackbar = true;
                        this.submitLoading = false;
                        this.sampleDialog = false
                    });
                }
            }
        },
        editSample(sample) {
            this.sampleDialog = true
            this.expansionPanel = 0;
            this.resetInputs();
            this.title = 'Edit samples';
            // this.setLabel();
            var transaction = sample;
            this.sampleId = transaction.id;

            this.sampleData.materialsAmount.materialId = transaction.materialId;
            this.sampleData.materialsAmount.amount = transaction.amount;
            this.sampleData.accountId = transaction.toEmployeeId;
            this.sampleData.date = window.moment(transaction.date).format('YYYY-MM-DD');
            this.sampleData.description = transaction.description;
        },

        resetInputs() {
            if (this.sampleData.materialsAmount.materialId) this.$refs.form.resetValidation();
            // reset sampleId
            this.sampleId = null;
            // reset fields
            this.sampleData.materialsAmount.materialId = null;
            this.sampleData.materialsAmount.amount = null;
            this.sampleData.accountId = null;
            this.sampleData.date = null;
            this.sampleData.description = null;
            this.sampleData.type = 0
        },
            
        getAccount(type) {
            this.loading = true
            this.loadingAccount = true
            this.$store.dispatch('accounts/fetchAllBrief', { type: type }).then((data) => {
                this.accounts = data
            }).finally(() => {
                this.loading = false
                this.loadingAccount = false
            })
        },

        // setLabel(){
        //     if (this.getUserType === 'admin'){ 
        //         this.label = 'Supervisor'
        //     }
        //     else if (this.getUserType === 'supervisor'){
        //         this.label = 'Medical rep';
        //     }
        // },
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                !this.materialId && !this.materialFormId && !this.accountId && !this.startDate && !this.endDate
            )
        },

        ...mapState({
            transactions: state => state.materialTransactions.transactions,
            materials: state => state.materials.materials,
            materialForms: state => state.materialForms.materialForms,
            supervisors: state => state.supervisors.supervisors,
            medicalReps: state => state.medicalreps.medicalreps,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            usersFlowers: state => state.medicalreps.usersFlowers
        }),

        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            getUserType: 'auth/getUserType',
            getMaterialById: 'materials/getMaterialById',
            getMaterialFormById: 'materialForms/getMaterialFormById',
            getCityNameBySectorId: 'sectors/getCityNameBySectorId',
            getSupervisorById: 'supervisors/getSupervisorById',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
        }),
        
        headersOfMedicalRepsDelivery () {
            return [
                { 
                    text: 'Date', 
                    name: 'date',
                    align: 'start', 
                    sortable: true, 
                    value: 'date', 
                    setValueExpr: val => this.moment(val) || '-',
                    class:'primary--text', 
                    cellClass:'primary--text fixed-width-date'
                },
                { 
                    text: 'Product',
                    name: 'product',
                    align: 'start', 
                    sortable: true, 
                    value: 'materialId', 
                    setValueExpr: val => this.dig('getMaterialById', val, 'name') || '-'

                },
                { 
                    text: 'Volume', 
                    name: 'volume',
                    value: 'materialId', 
                    align: 'start', 
                    setValueExpr: val => this.dig('getMaterialById', val, 'dose') || '-' 
                },
                { 
                    text: 'pharmaceutical form', 
                    name: 'pharmaceuticalForm',
                    value: 'materialId', 
                    setValueExpr: val => this.dig('getMaterialFormById', this.dig('getMaterialById', val, 'materialFormId') || null, 'name') || 'N/A',
                    align: 'start', 
                },
                { 
                    text: 'Received samples', 
                    name: 'amount',
                    value: 'amount', 
                    align: 'start', 
                },
                { 
                    text: 'City', 
                    name: 'city',
                    value: 'toEmployeeId', 
                    setValueExpr: val => this.dig('getCityNameBySectorId', this.dig('getMedicalRepById', val, 'sectorId'), 'cityName') || '-',
                    align: 'start', 
                },
                { 
                    text: 'name',
                    name: 'medicalRepName', 
                    value: 'toEmployeeId', 
                    setValueExpr: val => this.dig('getMedicalRepById', val, 'name'),
                    align: 'start', 
                },
                { 
                    text: 'Action',
                    name: 'actions', 
                    value: 'action', 
                    align: 'center', 
                }
            ]
        },
        
        headersOfSupervisorsDelivery() {
            return [
                { 
                    text: 'Date', 
                    align: 'start', 
                    value: 'date', 
                    setValueExpr: val => this.moment(val),
                    class:'primary--text', 
                    cellClass:'primary--text fixed-width-date',
                    sortable: true, 
                },
                { 
                    text: 'Product', 
                    value: 'materialId', 
                    setValueExpr: val => this.dig('getMaterialById', val, 'name') || '-',
                    align: 'start', 
                    sortable: true, 
                },
                { 
                    text: 'Volume', 
                    value: 'materialId', 
                    setValueExpr: val => this.dig('getMaterialById', val, 'dose') || '-',
                    align: 'start', 
                },
                { 
                    text: 'pharmaceutical form', 
                    value: 'materialId', 
                    setValueExpr: val => this.dig('getMaterialFormById', this.dig('getMaterialById', val, 'materialFormId') || null, 'name') || '-',
                    align: 'start', 
                },
                { 
                    text: 'Received samples', 
                    value: 'amount', 
                    setValueExpr: val => val || "-", 
                    align: 'start', 
                },
                { 
                    text: 'City', 
                    value: 'toEmployeeId', 
                    setValueExpr: val => this.dig('getCityNameBySectorId', this.dig('getSupervisorById', val, 'sectorId'), 'cityName') || '-',
                    align: 'start', 
                },
                { 
                    text: 'Supervisor name', 
                    value: 'toEmployeeId', 
                    setValueExpr: val => this.dig('getSupervisorById', val, 'name'), 
                    align: 'start' 
                },
                { text: 'Action', name: 'action', value: 'action', align: 'center', },
            ]
        }
    },
    
    metaInfo: {
        title: 'Samples',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>

</style>