export default { 
    'visit-date': "Visit date",
    "entity-name": "Entity name",
    'edit-visit': 'Edit visit',
    "delete-visit": 'Delete visit',
    'add-new-visit': 'Add new visit',
    'visit': 'visit',
    'view-materials-visit': 'View Materials Visit',
    'sorry-there-is-no-plan-created-by-the-employee': 'Sorry, there is no plan created by the employee',
    'sorry-there-is-no-plan-created-for-the-selected-month': 'Sorry, there is no plan created for the selected month',
    'add-visit': 'Add visit'
}