<template>
    <div>
        <v-dialog
            v-model="dialog"
            transition="dialog-transition"
            :max-width="moduleType === 'sales' ? 600 : 420"
            ref="dialog"
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <div class="d-flex justify-end py-1 background-title-card ">
                        <v-card-title
                            class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                        >
                            {{ title }}
                        </v-card-title>
                        <!-- close button -->
                        <v-btn icon @click="dialog = false; $refs.form.resetValidation()">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-2 px-3 pb-0">
                        <!-- dialog content -->
                        <v-row dense>
                            <!-- date -->
                            <v-col sm="6" v-if="moduleType === 'sales'">
                                <bee-date-picker
                                    v-model="date"
                                    input-label="Date"
                                    elevation="2"
                                    menu-picker
                                    dense
                                    hide-details
                                    :input-props="{
                                        outlined: true,
                                        dense: true
                                    }"
                                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                                    min="1940-01-01"
                                    :disabled="loading"
                                    :rules="rules.required"
                                ></bee-date-picker>
                            </v-col>

                            <!-- file input -->
                            <v-col>
                                <v-file-input
                                    v-model="file"
                                    counter
                                    clearable
                                    show-size
                                    truncate-length="15"
                                    label="Excel File"
                                    hint="Select excel file"
                                    persistent-hint
                                    :prepend-icon="file? 'mdi-file-table':'mdi-file-table-outline'"
                                    :error-count="errors.length || 1"
                                    :error-messages="errors"
                                    accept=".xlsx,.xls"
                                    :rules="[val => (val !== null) || 'Select excel file']"
                                    dense
                                    outlined
                                ></v-file-input>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <!-- Donwload template -->
                        <v-btn
                            text
                            small
                            color="green darken-1"
                            @click="downloadExcelTemplate(`/Excel templates/${moduleType}-template.xlsx`)"
                        >
                            Donwload Excel file template
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            type="submit"
                            :loading="loading"
                            :disabled="loading"
                            color="success white--text"
                            elevation="0"
                            text
                        >
                            import
                        </v-btn>
                        <v-btn
                            @click="dialog = false"
                            elevation="0"
                            text
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- snackbar will appear after load medical rep image -->
        <v-snackbar
          v-model="snackbar"
          transition="slide-y-reverse-transition"
          color="primary"
          timeout="-1"
        >
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success lighten-2"
                    text
                    v-bind="attrs"
                    :disabled="loading"
                    @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import rules from '../validation rules'
import { downloadExcelTemplate } from '../helpers'

export default {
    data(){
        return {
            // helpers
            title: null,
            snackbar: false,
            loading: false,
            message: null,
            dialog: false,
            rules,
            
            id: null,
            moduleType: null,
            errors: [],

            activePickerDate: null,
            menuOfDate:false,

            // Data
            date: null,
            file: null,
        }
    },

    watch:{
        menuOfDate (val) {
            val && setTimeout(() => (this.activePickerDate = 'YEAR'))
        },

        dialog(){
            if (!this.dialog){
                this.$refs.form.resetValidation();
                this.id = null;
                this.date = null;
                this.file = null;
            }
        }
    },

    methods: {
        saveDate (date) {
            this.$refs.menuOfDate.save(date);
        },

        resetInputs(){
            if (this.file) this.$refs.form.resetValidation();
            this.id = null;
            this.file = null;
        },

        import(moduleType, id){
            this.resetInputs();
            this.title = 'import excel file';
            this.id = id;
            this.moduleType = moduleType;
            this.dialog = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;

                this.$store.dispatch(`${this.moduleType}/import`, { 
                    id: this.id,
                    date: this.date + '-01',
                    excelFile: this.file
                })
                .then((data) => {
                    this.message = 'Excel File is Imported';
                    this.$refs.dialog.isActive = false;
                    this.$emit('imported');

                    if (data.length > 0){
                        this.message = `Imported, but there is an error in rows ${data.join(', ')}`;
                    }
                })
                .catch((e) => {
                    this.message = e.response.data.message;
                })
                .finally(() => {
                    this.snackbar = true;
                    this.loading = false;
                })
            }
        },

        // import excel files
        downloadExcelTemplate,
    },
}
</script>

<style>

</style>