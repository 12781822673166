<template>
    <div>
        <!-- dialog -->
        <v-dialog v-model="dialog" width="400px">
			<v-card>
				<v-form ref="form" @submit.prevent="save">
					<div class="d-flex justify-end py-1 background-title-card">
                        <v-card-title
                            class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                        >
                            {{ classificationId ? $t('ClassManagement.edit-classification') : $t('ClassManagement.add-new-classification') }}
                        </v-card-title>

                        <v-btn icon @click="dialog = false">
                            <v-icon color="white"> mdi-close-circle-outline </v-icon>
                        </v-btn>
                    </div>

					<v-card-text class="pb-0">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model="classificationName"
                                    :label="$t('inputs.classification')"
                                    dense
                                    outlined
                                    hide-details
                                    :rules="rules.required"
                                />
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-text-field
                                    v-model.number="order"
                                    :label="$t('inputs.priority')"
                                    dense
                                    outlined
                                    hide-details
                                    :rules="rules.requiredAsNumber"
                                />
                            </v-col>
                        </v-row>
					</v-card-text>
					<v-card-actions class="pt-0">
						<v-spacer />
						<v-btn type="submit" text color="success" :loading="loading"> {{ $t('save') }} </v-btn>
						<v-btn text :disabled="loading" @click="dialog = false"> {{ $t('cancel') }} </v-btn>
					</v-card-actions>
                    
				</v-form>
			</v-card>
        </v-dialog>
        <!-- headers -->
        <v-row>
            <v-col class="d-flex justify-start ">
				<!-- add new classification -->
    			<v-btn
					@click="reset()"
					small
					class="mx-2"
					color="dark-yellow white--text"
                    :disabled="loading"
				>                    
					<v-icon class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{$t('ClassManagement.add-new-classification')}}
                </v-btn>

                <!-- BACK -->
                <v-btn
                    color="grey white--text"
                    small
                    :to="{
                        name: 'customers'
                    }"
                    :disabled="loading"
                >
                    <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                    {{ $t('back') }}
                </v-btn>
            </v-col>
        </v-row>
        <!-- table -->
        <bee-handy-table
            :items="!loading ? classification : []"
            :headers="headers"
            dense
            zebra
            :loading="loading"
            fixed-header
            :height="$vuetify.breakpoint.height - 120"
            pagination-on-scroll
            class="mt-2"
            :shown-rows-text="$t('shown-rows')"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn
                	text
                	color="edit-color"
                	x-small
                	class="px-1"
					@click="classificationId = item.id; dialog = true"
                >
                    <v-icon> mdi-pencil </v-icon>
                </v-btn>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { roleName } from '@/helpers/enums'
import rules from '@/validation rules'
export default {
    name: 'classification-management',
    data () {
        return {
            // workloads:[
            //     { id: 0, name: 'A+' },
            //     { id: 1, name: 'A' },
            //     { id: 2, name: 'A-' },
            //     { id: 3, name: 'B+' },
            //     { id: 4, name: 'B' },
            //     { id: 5, name: 'B-' },
            //     { id: 6, name: 'C' },
            // ],

            dialog: false,

            loading: false,
			classificationId: null,
            roleName,
            classificationName: null,
            rules,
            order: null
        }
    },

	watch: {
		dialog (val) {
            if (val) {
                this.classificationName = this.getClassificationById(this.classificationId)?.name
                this.order = this.getClassificationById(this.classificationId)?.order
            }
		}
	},
    
    computed: {
        ...mapState({
            classification: state => state.workload.classification,
            permissionsUser: state => state.auth.youPermissions,
			isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        ...mapGetters({
            getClassificationById: 'workload/getClassificationById'
        }),

        headers () {
            const headers = [
                {
                    text: this.$t('headers.classification'), 
                    align: 'start', 
                    sortable: true, 
                    value: 'name', 
                    name: 'name',
                    class:'text-body-2 font-weight-bold',
                },
                {
                    text: this.$t('headers.priority'), 
                    align: 'start', 
                    sortable: true, 
                    value: 'order', 
                    name: 'priority',
                    class:'text-body-2 font-weight-bold',
                    type: 'number'
                }
            ];
            if (this.isSuperAdmin || this.permissionsUser.Entities === roleName.action) {
                headers.push({
                    text: '', 
                    align: 'start', 
                    value: 'actions', 
                    name: 'actions',
                    class:'text-body-2 font-weight-bold',
                })
            }
            return headers
        } 
    },

	methods: {
        fetchAll () {
            this.loading = true
            this.$store.dispatch('workload/fetchAll').finally(() => {
                this.loading = false
            })
        },
        save () {
            if (this.$refs.form.validate()) {
                this.loading = true
                const actionsName = this.classificationId ? 'update' : 'create'
                this.$store.dispatch(`workload/${actionsName}`, { 
                    name: this.classificationName, 
                    id: this.classificationId,
                    order: this.order
                }).then(() => {
                    this.fetchAll();
                    this.dialog = false
                }).catch(() => {
                    this.loading = false
                })
            }
        },
		reset() {
			this.classificationName = null;
			this.classificationId = null;
			this.dialog = true
		}
	},

    created () {
       this.fetchAll()
    }
}
</script>
