<template>
    <div id="medPlan" class="mt-1">
        <!-- add/edit dialog -->
        <v-dialog v-model="dialog" width="600px">
            <v-form ref="form" @submit.prevent="submit">
                <v-card>
                    <v-card-title class="d-flex justify-end background-title-card mb-3 py-1">
                        <div class="flex-grow-1 text-center text-uppercase text-body-2 py-0 white--text">
                           {{ !visitId ? $t('MedicalRepPlan.add-visit') : $t('MedicalRepPlan.edit-visit') }}
                        </div>
                
                        <!-- close icon button -->
                        <v-btn icon @click="dialog = false">
                            <v-icon color="white" >
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </v-card-title>
                
                    <v-card-text class="pb-0">
                        <v-row>
                            <!-- date -->
                            <v-col v-if="!show" sm="12" md="6">
                                <bee-date-picker
                                    v-model="dateEvent"
                                    :input-label="$t('MedicalRepPlan.visit-date')"
                                    hide-details="auto"
                                    menu-picker
                                    :input-props="{
                                        readonly: true,
                                        outlined: true,
                                        dense: true,
                                    }"
                                    :min-date="moment(selectedMonth).startOf('month').format('YYYY-MM-DD')"
                                    :max-date="moment(selectedMonth).endOf('month').format('YYYY-MM-DD')"
                                    :disabled="submitLoading" 
                                    :rules="rules.date"
                                ></bee-date-picker>
                            </v-col>
                
                            <!-- entity -->
                            <v-col v-if="!show" sm="12" md="6" align-self="center">
                                <v-autocomplete
                                    v-model="entityId"
                                    :items="entitiesBrief"
                                    :label="$t('MedicalRepPlan.entity-name')"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    :disabled="submitLoading"
                                    :rules="rules.required"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" align-self="center">
                                <bee-handy-smart-table
                                    ref="table"
                                    v-model="materialInput"
                                    :headers="materialsHeaders"
                                    :items="tableMaterials"
                                    show-add-row
                                    @keypress.enter="!isEditItem ? addItem() : editItem()"
                                    pagination-on-scroll
                                    height="300"
                                    dense
                                    zebra
                                    editable-rows
					                :reset-edit.sync="resetEdit"
                                > 

                                    <template v-slot:item.actions="{ index }">
                                        <v-btn 
                                            icon 	
                                            color="edit-color"				
                                            @click="$refs.table.resetInputs(); $refs.table.activeEditForRow(index); setRowIndex({ index })"
                                        >
                                            <v-icon> mdi-pencil </v-icon>
                                        </v-btn>

                                        <v-btn 
                                            icon 	
                                            color="alert-color"				
                                            @click="deleteItem({ index })"
                                        >
                                            <v-icon> mdi-delete </v-icon>
                                        </v-btn>
                                    </template>

                                    <template v-slot:input.materialId="{ on, attr }">
                                        <v-autocomplete
                                            :items="materials"
                                            item-text="name"
                                            item-value="id"
                                            v-on="on"
                                            v-bind="attr"
                                         />
                                    </template>
                                </bee-handy-smart-table>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            text
                            type="submit"
                            color="success"
                            :loading="submitLoading"
                        >
                            {{$t('save')}}
                        </v-btn>
                
                        <v-btn
                            text
                            color="alert-color"
                            @click="dialog = false"
                            :disabled="submitLoading"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- delete dialog -->
        <v-dialog v-model="deleteDialog" width="400px">
            <v-card>
                <v-card-title class="d-flex justify-end red-light-one-bg mb-3 py-1">
                    <div class="flex-grow-1 text-center text-uppercase text-body-2 py-0 white--text">
                        {{ $t('delete') }}
                    </div>

                    <!-- close icon button -->
                    <v-btn icon @click="deleteDialog = false">
                        <v-icon color="white" >
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pb-0">
                    {{$t('are-you-sure-you-want-to-delete-this')}} {{$t('MedicalRepPlan.visit')}} {{$t('?')}}
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                    	text
                    	color="alert-color"
                    	:loading="submitLoading"
                    	@click="deleteEvent()"
                    >
                       {{$t('yes')}} 
                    </v-btn>

                    <v-btn
                    	text
                    	:disabled="submitLoading"
                    	@click="deleteDialog = false"
                    >
                        {{$t('no')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row>
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.medical-rep-plan')}}
                </h1>
            </v-col>
            <!-- button -->
            <v-col 
                align-self="center" 
                class="d-flex"
                :class="{
                    'justify-end': $vuetify.breakpoint.mdAndUp,
                    'justify-start pt-0':  $vuetify.breakpoint.smAndDown
                }"
            >
                <v-btn
                    small
                    color="green lighten-1 white--text"
                    @click="ImportExcel"
                    class="mx-1"
                    :loading="loadingExcel"
                    :disabled="loadingExcel || !medicalRepId || !medicalRepPlans.length"
                >
                    <v-icon> mdi-file-table-outline </v-icon>
                    {{ $t('export-excel') }}
                </v-btn>
                <v-divider vertical class="mx-3" />

                <!-- BACK -->
                <v-btn
                    color="grey darken-2 white--text"
                    small
                    :to="{
                        name: 'staff',
                        query: {
                            role: $route.query.role
                        }
                    }"
                >
                    <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                    Back
                </v-btn>
            </v-col>
        </v-row>

        <!-- filter -->
        <v-row align="center" class="mb-1">
            <!-- medical rep -->
            <v-col cols="12" sm="4" md="2">
                <v-autocomplete 
                    v-model="medicalRepId"
                    :label="$t('inputs.medical-rep')"
                    :items="medicalReps.filter(c => c.employeeType  === userType.medicalRep)"
                    item-value="id"
                    item-text="name"
                    outlined
                    dense
                    hide-details
                    :loading="loading"
                    :disabled="loading || Boolean($route.params.id)"
                    :append-icon="$route.params.id"
                    @change="fetchMedicalRepPlansVisits()"
                />
            </v-col>

            <!-- date input -->
            <v-col align-self="center" cols="12" sm="4" md="3">
                <bee-date-input
					v-model="selectedMonth"
					:label="selectedMonth ? `${moment(selectedMonth).format('MMMM')}` : $t('inputs.date')"
					:loading="loading"
                    :disabled="loading || medicalRepId == null"
					dense
                    hide-details
                    input-format-order="year-month-day"
                    input-type="month"
                    :append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
					:prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
                    readonly
                    fit-inputs
					@click:append="nextMonth(); matchVisitsWithPlan()"
					@click:prepend="prevMonth(); matchVisitsWithPlan()"
				></bee-date-input>
            </v-col>

            <!-- <v-col 
                align-self="center" 
                class="d-flex"
                :class="{
                    'justify-end': $vuetify.breakpoint.mdAndUp,
                    'justify-start pt-0':  $vuetify.breakpoint.smAndDown
                }"
            >

                <v-btn
                    color="grey darken-2 white--text"
                    small
                    :to="{name: 'staff'}"
                >
                    <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                    {{$t('back')}}
                </v-btn>
            </v-col> -->
        </v-row>

        <!-- calendar -->
        <v-card
            :max-height="$vuetify.breakpoint.height - 110"
            elevation="1"
            class="overflow-x-hidden"
        	:class="{'pt-3': $vuetify.breakpoint.smAndDown}"
        >
            <v-progress-linear v-if="loading" indeterminate height="2" class="progress-loader"/>
            <v-card-text class="pa-0">
                <v-calendar
                    ref="calendar"
                    v-model="calendarDate"
                    :events="events"
                    color="primary"
                    type="month"
                    :event-more="false"
                    :weekdays="[0, 1, 2, 3, 4, 5, 6]"
                    @click:event="showMenu"
                >
                    <!-- event card -->
                    <template v-slot:event="{ event }">
                        <p class="text-center"> 
                            {{
                                entitiesBrief.find(c => c.id === event.entityId)
                                    ? entitiesBrief.find(c => c.id === event.entityId).name
                                    : event.name
                            }} 
                            {{
                                event.entityType === entityType.doctor
                                    ? '.Dr'
                                    : event.entityType === entityType.pharmacy ? '.Ph' : '.C'
                            }}
                        </p>
                    </template>

                    <!-- add event button -->
                    <template v-slot:day="{ date }">
                        <v-btn
                            v-if="
                                (isSuperAdmin || permissionsUser === roleName.action) &&
                                moment(date).isSame(moment(selectedMonth), 'month')
                            "
                            icon
                            color="success"
                            class="d-block mx-auto"
                            :disabled="
                                loading ||
                                !medicalRepPlans.length ||
                                !medicalRepId ||
                                !Boolean(
                                    medicalRepPlans.find(c => moment(c.fromDate).isSame(selectedMonth, 'month'))
                                )
                                || moment(moment(date).format('YYYY-MM-DD')).isBefore(moment(), 'day')
                            "
                            @click="dateEvent = date; visited = null; dialog = true"
                        >
                            <v-icon> mdi-plus-circle </v-icon>
                        </v-btn>
                    </template> 
                </v-calendar>
            </v-card-text>
        </v-card>

        <!-- menu -->
        <v-menu
            v-model="menu"
            :activator="selectedElement"
            offset-x
        >
            <v-list dense>
                <!-- view -->
                <v-list-item @click="show = true; dialog = true">
                    <v-list-item-icon class="me-5">
                        <v-icon color="success">
                            mdi-eye
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{$t('MedicalRepPlan.view-materials-visit')}}</v-list-item-title>
                </v-list-item>

                <!-- edit -->
                <v-list-item @click="dialog = true">
                    <v-list-item-icon class="me-5">
                        <v-icon color="edit-color">
                            mdi-pencil
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{$t('MedicalRepPlan.edit-visit')}}</v-list-item-title>
                </v-list-item>

                <!-- delete -->
                <v-list-item @click="deleteDialog = true">
                    <v-list-item-icon class="me-5">
                        <v-icon color="alert-color">
                            mdi-delete
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> {{$t('MedicalRepPlan.delete-visit')}} </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import { roleName, userType, entityType } from '@/helpers/enums';
import rules from '@/validation rules';
export default {
    name: 'MedicalRepPlan',

    data: () => ({
        // helpers
        entityType,
        rowEditIndex: null,
        roleName,
        userType,
        rules,
        
        submitLoading: false,
        loading: false,

        // add/edit dialog
        dialog: false,
        dateEvent: null,
        entityId: null,

        // delete dialog
        deleteDialog: false,
        visitId: null,

        // filters
        medicalRepId: null,
        selectedMonth: null,
        medicalRepPlans: [],

        // calendar
        selectedElement: null,
        menu: false,
        calendarDate: null,
        events: [],
        materialInput: {},
        tableMaterials: [],
        resetEdit: false,
        isEditItem: false,
        show: false,
        loadingExcel: false
    }),

    watch: {
        dialog (val) {
            if (val === false) {
                this.$refs.form.reset();
                this.entityId = null;
                this.visitId = null;
                this.show = false
            }
        },
    },
    computed: {
        ...mapState({
            plan: state => state.visitsPlan.plan,
            plannedVisits: state => state.visitsPlan.plan.plannedVisits,
            medicalReps: state => state.medicalreps.medicalreps,
            plannedVisitsByEmployeeId: state => state.visitsPlan.plannedVisitsByEmployeeId,
            permissionsUser: state => state.auth.youPermissions.MedicalPlans,
            isSuperAdmin: state => state.auth.isSuperAdmin,
            entitiesBrief: state => state.entities.entitiesBrief,
            materials: state => state.materials.materials,
        }),

        ...mapGetters({
            getPlanById: 'visitsPlan/getPlanById',
            getVisitById: 'visitsPlan/getVisitById',
            getPlan: 'visitsPlan/getPlanByDate',
            getMaterialById: 'materials/getMaterialById'
        }),
        materialsHeaders() {
            return [
                {
                    text: this.$t('headers.material'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name 
                },

                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount',
                    type: 'number'
                },

                {
                    text: this.$t('headers.actions'),
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                }
            ]
        }
    },
    methods: {
        // helpers
        moment,

        showMenu ({ nativeEvent, event }) {
            // prevent adding out of 2 months
            // ![0, 1].includes(moment(this.selectedMonth).diff(moment(), 'months'))
            if (
                event.isOutOfPlan || moment(moment(event.date).format('YYYY-MM-DD')).isBefore(moment(), 'day')
                
            ) return;

            if (event.isOutOfPlan) return;
            this.visitId = event.id;
            this.dateEvent = moment(event.start).format('YYYY-MM-DD');
            this.entityId = event.entityId;
            this.tableMaterials = event.materials
            this.selectedElement = nativeEvent.target;
            this.$nextTick(() => { this.menu = true; })
        },

        nextMonth () {
            this.selectedMonth = moment(this.selectedMonth).add(1, 'month').format('YYYY-MM')
            this.$refs.calendar.next();
		},
		prevMonth () {
            this.selectedMonth = moment(this.selectedMonth).subtract(1, 'month').format('YYYY-MM')
            this.$refs.calendar.prev();
		},

        // manage planned visits
        submit() {
            if (this.$refs.form.validate()) {
                this.submitLoading = true;
                const actionName = !this.visitId ? 'create' : 'update';
                this.$store.dispatch(`visitsPlan/${actionName}`, { 
                    id:  this.visitId,
                    entityId: this.entityId, 
                    date: `${this.dateEvent}T00:00:00`,
                    visitPlanId: this.plan.map(c => ({
                        ...c,
                        fromDate: moment(c.fromDate).format('YYYY-MM')
                    })).find(c => moment(c.fromDate).isSame(this.selectedMonth, 'month'))?.id,
                    materialsAmount: this.tableMaterials.map(c => ({ materialId: c.materialId, amount: c.amount }))
                }).then(() => {
                    this.matchVisitsWithPlan();
                    this.dialog = false;
                }).finally(() => {
                    this.submitLoading = false
                })
            }
        },

        deleteEvent () { 
            this.submitLoading = true;
            this.$store.dispatch('visitsPlan/delete', {
                id: this.visitId
            }).then(() => {
                this.matchVisitsWithPlan();
                this.deleteDialog = false;
            }).finally(() => {
                this.submitLoading = false;
            })
        },

        // plan visits
        fetchMedicalRepPlansVisits() {
            this.medicalRepPlans = [];
            this.events = [];
            if (!this.medicalRepId) {
                this.selectedMonth = null;
                return;
            };
            
            this.loading = true;
            this.$store.dispatch('visitsPlan/allPlan', { employeeId: this.medicalRepId }).then((data) => {
                this.medicalRepPlans = data;

                // FIXME latest plan must be for latest plan only not for largest month
                // this is just solution for the unordered plans date
                const latestPlan = data.find(c =>
                    moment(c.fromDate).format('YYYY-MM') ===
                    moment(Math.max(...data.map(c => new Date(c.fromDate)))).format('YYYY-MM')
                );
                
                if (!latestPlan) {
                    this.events = []
                    this.$eventBus.$emit(
                        'show-snackbar',
                        this.$t('MedicalRepPlan.sorry-there-is-no-plan-created-by-the-employee'),
                        'info'
                    );
                    this.loading = false;
                    return;
                }
                
                const latestPlanMonth = moment(latestPlan.fromDate).format('YYYY-MM');
                this.$refs.calendar.move(Math.abs(moment(this.selectedMonth).diff(latestPlanMonth, 'months')));
                
                this.selectedMonth = latestPlanMonth;
                this.calendarDate = moment(latestPlanMonth).format('YYYY-MM-DD');

                this.matchVisitsWithPlan();
            })
        },

        matchVisitsWithPlan () {
            this.events = [];
            if (
                this.medicalRepPlans.length &&
                !this.medicalRepPlans.find(c => moment(c.fromDate).isSame(this.selectedMonth, 'month'))
            ) {
                this.$eventBus.$emit(
                    'show-snackbar',
                    this.$t('MedicalRepPlan.sorry-there-is-no-plan-created-for-the-selected-month'),
                    'info'
                );
                return 
            }

            this.loading = true;
            return Promise.all([
                this.$store.dispatch('visitsPlan/fetchPlannedVisitsByEmployeeId', { 
                    employeeId: this.medicalRepId,
                    fromDate: moment(this.selectedMonth).format('YYYY-MM-DD')
                }),
                this.$store.dispatch('visits/fetchVisitsWithoutPagination', { 
                    fromDate: moment(this.selectedMonth).startOf('month').format('YYYY-MM-DD'),
                    toDate: moment(this.selectedMonth).endOf('month').format('YYYY-MM-DD'),
                    employeeId: this.medicalRepId
                })
            ]).then(data => {
                const plannedVisits = data[0];
                let visits = data[1];

                for (let i = 0; i < (plannedVisits || []).length; i++) {
                    this.events.push({
                        name: plannedVisits[i].entity.name,
                        entityId: plannedVisits[i].entity.entityId,
                        id: plannedVisits[i].id,
                        color:
                            plannedVisits[i].isVisited
                                ? 'success'
                                : moment(plannedVisits[i].date).diff(moment(), 'days') >= 0
                                    ? 'grey' : 'red',
                        date: plannedVisits[i].date,
                        start: new Date(plannedVisits[i].date).getTime(),
                        end: new Date(plannedVisits[i].date).getTime(),
                        timed: false,
                        entityType: plannedVisits[i].entity.entityType,
                        materials: plannedVisits.plannedVisitMaterials
                    })
                }

                visits = (visits || []).map(c => ({
                    entity: c.entity,
                    entityId: c.entityId,
                    date: new Date(c.createdAt).getTime(),
                    entityType: c.entity.entityType
                }))

                for (let i = 0; i < visits.length; i++) {
                    const match = this.events.filter(c => c.entityId === visits[i].entityId)
                    if (!match.find(c => moment(c.date).isSame(visits[i].date, 'day'))) {
                        this.events.push({
                            name: visits[i].entity.name,
                            entityId: visits[i].entityId,   
                            start: visits[i].date,
                            end: visits[i].date,
                            isOutOfPlan: true,
                            timed: false,
                            entityType: visits[i].entityType,
                            color: 'orange'
                        })
                    }
                }
            }).finally(() => {
                this.loading = false;
            })
        },
        addItem () {
            this.tableMaterials.push({
                ...this.materialInput,
                id: Math.random()
            })
            this.$nextTick(() => {
				this.$refs.table.resetInputs();
				this.$refs.table.resetInputsFocus()
			})
        },

        editItem () {
            this.tableMaterials[this.rowEditIndex].materialId = this.materialInput.materialId
			this.tableMaterials[this.rowEditIndex].amount = this.materialInput.amount
			this.resetEdit = true
			// this.resetTableInputs()
			this.$refs.table.resetEdit()
			this.isEditItem = false
			this.resetInputsFocus = true
        },

        deleteItem ({ index }) {
			this.$refs.table.resetEdit()
			this.isEditItem = false
			this.tableMaterials.splice(index, 1)
		},

        setRowIndex (rowData) {
			this.rowEditIndex = rowData.index
			this.isEditItem = true
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},

        ImportExcel() {
            this.loadingExcel = true
            this.loading = true
            this.$store.dispatch('exporter/fetchPlanExport', { 
                fromDate: this.selectedMonth, 
                employeeId: this.medicalRepId,
            }).finally(() => {
                this.loadingExcel = false
                this.loading = false
            })
        },
    },

    created () {
        this.medicalRepId = this.$route.params.id || null;

        this.loading = true
        Promise.all([
            !this.entitiesBrief.length ? this.$store.dispatch('entities/fetchAllBrief', { type: 1 }) : null,
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null
        ]).finally(() => {
            if (this.medicalRepId) {
                this.fetchMedicalRepPlansVisits()
            } else {
                this.loading = false
            }
        })
    },

    metaInfo() {
        return {
            title: this.$t(`routes.${this.$route.meta.i18nKey}`),
            titleTemplate: '%s | Octophamra'
        }
    },
}
</script>

<style lang="scss">
#medPlan {
    .v-calendar-weekly__head {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 2;
    }
    .progress-loader {
        position: absolute;
        top:0;
        z-index: 3;
    }
}
</style>
