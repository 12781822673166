<template>
    <div>
        <h2 class="text-h5 primary--text text-capitalize"> Matching rep plan </h2>
        <!-- {{itemss.week1.saturday}} -->
        
        <!-- table -->
        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="itemss"
            :with-columns-divider="true"
            dense
            zebra
            v-if="0"
        >
            <template v-slot:item.saturday="{ on, attr }">
                <div v-for="(itemm, index) in itemss[0].week1.saturday" :key="index">
                    <!-- {{ itemm }} --> 
                    <v-text-field 
                        :value="itemm.doctor" 
                        dense
                        hide-details 
                        v-on="on"
                        v-bind="attr"
                        :disabled="!isEditItem"
                        class="my-1 pa-1 rounded-lg"
                        :class="{
                            'redClass white--text': itemm.status === 0,
                            'greyClass': itemm.status === 3,
                            'greenClass white--text': itemm.status === 1,
                            'orangeClass': itemm.status === 2,
                        }"
                    />        
                </div>
            </template>

            <template v-slot:item.wednesday="{ on, attr }">
                <div v-for="(itemm, index) in itemss[0].week1.wednesday" :key="index">
                    <!-- {{ itemm }} --> 
                    <v-text-field 
                        :value="itemm.doctor" 
                        dense
                        
                        hide-details 
                        v-on="on"
                        v-bind="attr"
                        :disabled="!isEditItem"
                        class="my-1 pa-1 text-center rounded-lg"
                        :class="{
                            'redClass white--text': itemm.status === 0,
                            'greyClass': itemm.status === 3,
                            'greenClass white--text': itemm.status === 1,
                            'orangeClass': itemm.status === 2,
                        }"
                    />        
                </div>
            </template>

            <template v-slot:item.actions="{ }">
                <tooltip text="Edit" top>
                    <v-btn
                        icon
                        small
                        color="edit-color"
                        @click="dialogPlan = true"
                    >
                        <v-icon>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
                </tooltip>
                                        
                <tooltip text="Delete contact" top>
                    <v-btn
                        icon
                        small
                        color="alert-color"
                    >
                        <v-icon>
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
        <v-row class="fill-height">
            <v-col>
                <v-sheet height="600">
                    <v-calendar
                        ref="calendar"
                        v-model="value"
                        color="primary"
                        type="4day"
                        :events="events"
                        :event-color="getEventColor"
                        :event-height="50"
                        :event-ripple="true"
                        @change="getEvents"
                        @mousedown:event="startDrag"
                        @mousedown:time="startTime"
                        @mousemove:time="mouseMove"
                        @mouseup:time="endDrag"
                        @mouseleave.native="cancelDrag"
                        @click:event="showEvent"
                    >
                        <template v-slot:event="{ event, timed, eventSummary }">
                            <div class="v-event-draggable">
                                <!-- <component :is="{ render: eventSummary }"></component> -->
                                <p v-if="eventSummary"> {{ event.name }} </p>
                            </div>
                            <div
                                v-if="timed"
                                class="v-event-drag-bottom"
                                @mousedown.stop="extendBottom(event)"
                            ></div>
                        </template>
                    </v-calendar>
                </v-sheet>
            </v-col>
        </v-row>

        <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              :color="selectedEvent.color"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.details"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>

        <!-- <component v-bind:is="currentTabComponent"></component> -->
    </div>
</template>

<script>
export default {
    data () {
        return {
            entries: [],
            tableInputs: {},
            items: [
                {
                    week: '1',
                    saturday: 'عبدالحي كنامة',
                    sunday: '',
                    monday: '',
                    tuesday: '',
                    wednesday: 'عبدالله خوري',
                    thursday: ''
                }
            ],
            itemss: [
                {
                    week: 'The first week',
                    week1: {
                        saturday: [
                            {
                                doctor: 'عبدالله موقع',
                                status: 0              
                            },
                            {
                                doctor: 'عبدالحي كنامة',
                                status: 2             
                            },
                            {
                                doctor: 'أسامة قشقش ',
                                status: 1 
                            }
                        ],
                        wednesday: [
                            {
                                doctor: 'معن حفار',
                                status: 1             
                            },
                            {
                                doctor: 'طاهر فرفوطي',
                                status: 2            
                            }
                        ]
                    }  
                }
            ],
            rowEditIndex: null,
            isEditItem: false,
            dialogPlan: false,
            weekDays: [
                {
                    name: 'Saturday',
                    id: 1
                },
                {
                    name: 'Sunday',
                    id: 2,
                },
                {
                    id: 3,
                    name: 'Monday'
                },
                {
                    id: 4,
                    name: 'Tuesday'
                },
                {
                    id: 5,
                    name: 'Wednesday'
                },
                {
                    id: 6,
                    name: 'Thursday'
                }
            ],
            value: '',
            events: [],
            colors: ['#2196F3', '#3F51B5', '#673AB7', '#00BCD4', '#4CAF50', '#FF9800', '#757575'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            dragEvent: null,
            dragStart: null,
            createEvent: null,
            createStart: null,
            extendOriginal: null,   

            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
        }      
    },
    computed: {
        headers () {
            return [
                {
                    text: 'Week',
                    name: 'Week',
                    value: 'week',
                    align: 'center'
                },
                {
                    text: 'Saturday',
                    name: 'saturday',
                    value: 'saturday',
                    align: 'center'
                },
                {
                    text: 'Sunday',
                    name: 'sunday',
                    value: 'sunday',
                    align: 'center'
                },
                {
                    text: 'Monday',
                    name: 'monday',
                    value: 'monday',
                    align: 'center'              
                },
                {
                    text: 'Tuesday',
                    name: 'tuesday',
                    value: 'tuesday',
                    align: 'center'               
                },
                {
                    text: 'Wednesday',
                    name: 'wednesday',
                    value: 'wednesday',
                    align: 'center'
                },
                {
                    text: 'Thursday',
                    name: 'thursday',
                    value: 'thursday',
                    align: 'center'                
                },
                {
                    text: '',
                    name: 'actions',
                    value: 'actions',
                    align: 'center'               
                }
            ]
        },

        header () {
            return [
                {
                    text: 'Day Name',
                    name: 'day',
                    value: 'day',
                    setValueExpr: val => this.weekDays.find(c => c.id === val)?.name
                },
                {
                    text: 'Doctor',
                    name: 'doctorName',
                    value: 'doctorName'
                }
            ]
        }
    },

    methods: {
        addItem () {
            this.entries.push({
                ...this.tableInputs
            });
            this.$nextTick(() => {
                this.$refs.smartTable.resetInputs()
                this.$refs.smartTable.resetInputsFocus()
            })
        },  
        setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},
        startDrag ({ event }) {
            if (event) {
                this.dragEvent = event
                this.dragTime = null
                this.extendOriginal = null
            }
        },
        startTime (tms) {
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start
                this.dragTime = mouse - start
            } else {
                this.createStart = this.roundTime(mouse)
                this.createEvent = {
                    name: `Event #${this.events.length}`,
                    // name: this.rndElement(this.names),
                    color: this.rndElement(this.colors),
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
                }
            this.events.push(this.createEvent)
        }
      },
      extendBottom (event) {
        this.createEvent = event
        this.createStart = event.start
        this.extendOriginal = event.end
      },
      mouseMove (tms) {
        const mouse = this.toTime(tms)

        if (this.dragEvent && this.dragTime !== null) {
          const start = this.dragEvent.start
          const end = this.dragEvent.end
          const duration = end - start
          const newStartTime = mouse - this.dragTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          this.dragEvent.start = newStart
          this.dragEvent.end = newEnd
        } else if (this.createEvent && this.createStart !== null) {
          const mouseRounded = this.roundTime(mouse, false)
          const min = Math.min(mouseRounded, this.createStart)
          const max = Math.max(mouseRounded, this.createStart)

          this.createEvent.start = min
          this.createEvent.end = max
        }
      },
      endDrag () {
        this.dragTime = null
        this.dragEvent = null
        this.createEvent = null
        this.createStart = null
        this.extendOriginal = null
      },
      cancelDrag () {
        if (this.createEvent) {
          if (this.extendOriginal) {
            this.createEvent.end = this.extendOriginal
          } else {
            const i = this.events.indexOf(this.createEvent)
            if (i !== -1) {
              this.events.splice(i, 1)
            }
          }
        }

        this.createEvent = null
        this.createStart = null
        this.dragTime = null
        this.dragEvent = null
      },
        roundTime (time, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
                ? time - time % roundDownTime
                : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            console.log(tms)
            // return tm.date
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        getEventColor (event) {
            const rgb = parseInt(event.color.substring(1), 16)
            const r = (rgb >> 16) & 0xFF
            const g = (rgb >> 8) & 0xFF
            const b = (rgb >> 0) & 0xFF

            return event === this.dragEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event === this.createEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event.color
        },
        getEvents ({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`).getTime()
            const max = new Date(`${end.date}T23:59:59`).getTime()
            const days = (max - min) / 86400000
            const eventCount = this.rnd(days, days + 20)

            for (let i = 0; i < eventCount; i++) {
                const timed = this.rnd(0, 3) !== 0
                const firstTimestamp = this.rnd(min, max)
                const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
                const start = firstTimestamp - (firstTimestamp % 900000)
                const end = start + secondTimestamp

                events.push({
                    name: this.rndElement(this.names),
                    color: this.rndElement(this.colors),
                    start,
                    end,
                    timed,
                })
            }

            this.events = events
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        rndElement (arr) {
            return arr[this.rnd(0, arr.length - 1)]
        },

        showEvent ({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event
                this.selectedElement = nativeEvent.target
                requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                requestAnimationFrame(() => requestAnimationFrame(() => open()))
            } else {
                open()
            }
            nativeEvent.stopPropagation()
        },

    }
}
</script>
<style scoped lang="scss">
.greenClass {
    background-color: rgba(0, 128, 0, 0.616);
}
.redClass {
    background-color: rgba(255, 0, 0, 0.493);
}
.orangeClass {
    background-color: rgba(255, 166, 0, 0.596);
}
.greyClass {
    background-color: grey;
} 

</style>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
