<template>
    <div>
        <!-- filter -->
        <v-row>
			<v-col cols="1" class="me-2 px-0 d-flex justify-center">
                <vue-ellipse-progress 
                    :progress="loading ? 0 : rating"
                    :angle="-90"
                    :color="loading ? '#0b5b6f' : {
                        colors: [
                            { color: '#EF476F', offset: 0 },
                            { color: '#118AB2', offset: 100 },
                        ]
                    }"
                    :size="$vuetify.breakpoint.smAndUp ? 60 : 80"
                    :emptyThickness="6"
                    :thickness="6"
                    legendClass="primary--text"
                    :loading="loading"
                >
                    <span slot="legend-value">%</span>            
                </vue-ellipse-progress>
			</v-col>
            <v-col cols="2" sm="6" md="3" lg="2" align-self="center">
                <!-- medical rep name -->
                <v-autocomplete
					v-model="medicalRepId"
                    :items="medicalReps.filter(c => c.employeeType === userType.medicalRep)"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.medical-rep')"
                    dense
                    outlined
					hide-details
					:disabled="Boolean($route.query.preview)"
					:loading="loading"
                />
            </v-col>
	
            <v-col cols="2" sm="6" md="3" lg="auto" align-self="center">
                <v-autocomplete
                    v-model="regionsName"
					:items="regionsAll"
					item-text="name"
                    item-value="name"
                    :label="$t('inputs.region')"
                    dense
                    hide-details
                    :disabled="loading || Boolean($route.query.preview)"
                    outlined
					:loading="loading"
					multiple
                >
					<template v-slot:selection="{ item, index }">
						<span v-if="index === 0" class="me-1">{{ item.name }}</span>
						<span
							v-if="index === 1"
							class="grey--text text-caption"
						>
							(+{{ regionsName.length - 1 }})
						</span>
					</template>
				</v-autocomplete>
            </v-col>

            <!-- date -->
            <v-col cols="2" sm="6" md="3" lg="2" class="pt-sm-0 pt-md-3" align-self="center">
                <bee-date-picker 
                    v-model="date"
                    :input-label="$t('inputs.date')"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true
                    }"
                    min="1940-01-01"
                    :disabled="loading || Boolean($route.query.preview)"
					:loading="loading"
                ></bee-date-picker>
            </v-col>
			
			<v-spacer />

			<v-col align-self="center" class="d-flex justify-end">
				<v-btn
					v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
					@click="$route.query.preview ? update() : submit()"
					:color="Boolean($route.query.preview) ? 'edit-color white--text' : 'success'"
					small
				>
					<v-icon v-if="$route.query.preview" color="white"> mdi-pencil </v-icon>
					{{ $route.query.preview ? $t('edit') : $t('save') }}
				</v-btn>
			</v-col>
        </v-row>

		<v-row>
			<v-col cols="6" class="pt-0">
				<!-- rate --> 
				<section>
					<span class="text-title"> {{$t('DoubleVisitReport.pre-call-analysis')}} </span>
					<v-divider class="mb-3"/>
					<v-row v-for="(item, index) in ratingArray" :key="index" class="text-center">
						<v-col cols="4" class="text-start py-1 pe-0"> 
							<span class="text-body-2"> {{ item.name }} </span> 
						</v-col>
						<v-col v-if="0" cols="12" class="ps-0 py-1">
							<v-slider
								ref="slider"
								color="primary"
								min="1"
								max="15"
								hide-details
								:value="item.rate * 3"
							></v-slider>
						</v-col>

						<v-col cols="6" lg="3" align-self="center" class="d-flex justify-start px-0 py-1">
							<v-rating
								v-model="item.rate"
								:length="lengthSlider"
								color="black"
								background-color="grey"
								:loading="loading"
							>
								<template v-slot:item="props">
									<v-icon
										color="primary"
										@click="props.click"
										:disabled="Boolean($route.query.preview)"
									>
										{{ props.isFilled ? 'mdi-circle' : 'mdi-circle-outline' }}
									</v-icon>
								</template>
							</v-rating>
						</v-col>

						<v-col cols="12" lg="5" class="py-1">
							<v-text-field
								v-model="item.note"
								:label="$t('inputs.notes')"
								dense
								outlined
								hide-details
								:disabled="loading || Boolean($route.query.preview)"
							/>
						</v-col>
						<div v-if="item.title" class="d-flex flex-column text-start text-title ps-4"  style="width: 100%">
							<span> {{ item.title }} </span>
							<v-divider />
						</div>
					</v-row>
					<v-textarea
						v-model="notes"
						outlined
						hide-details
						dense
						:label="$t('inputs.notes')"
						rows="3"
						class="mt-6"
						:disabled="loading || Boolean($route.query.preview)"
					/>
				</section>
			</v-col>

			<v-col cols="6" class="pt-0">
				<!-- call -->
				<section class="">
					<span class="text-title"> {{ $t('DoubleVisitReport.call') }} </span>
					<v-divider class="mb-3"/>
					<v-row  v-for="(item, index) in ratingArrayThree" :key="index" class="text-center">
						<v-col cols="4" class="text-start py-1 pe-0"> 
							<span class=""> {{ item.name }} </span> 
						</v-col>

						<v-col cols="6" lg="3" align-self="center" class="d-flex justify-start px-0 py-1">
							<v-rating
								v-model="item.rate"
								:length="lengthSlider"
								color="black"
								background-color="grey"
								density="compact"
							>
								<template v-slot:item="props">
									<v-icon
										color="primary"
										@click="props.click"
										:disabled="loading || Boolean($route.query.preview)"
									>
										{{ props.isFilled ? 'mdi-circle' : 'mdi-circle-outline' }}
									</v-icon>
								</template>
							</v-rating>
						</v-col>

						<v-col cols="12" lg="5" class="py-1">
							<v-text-field
								v-model="item.note"
								:label="$t('inputs.notes')"
								dense
								outlined
								hide-details
								:disabled="loading || Boolean($route.query.preview)"
							/>
						</v-col>
					</v-row>
				</section>
			</v-col>
		</v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { userType, roleName } from '@/helpers/enums'
import moment from 'moment'
export default {
    data() {
        return {
			employeeId: null,
			length: 5,
			notes: null,
			roleName,
      		// rate: 2,
            loading: false,	
            date: null,
            sectorId: null,
			colors: ['green', 'purple', 'orange', 'indigo', 'red'],
      		rate: 1,
			lengthSlider: 5,
			sliderLength: 3,
			regionsName: [],
			medicalRepId: null,
			ratingArray: [],
			userType,
			// ratingArray: [
			// 	{
			// 		name: 'Look(dress-shoes)',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Timing plan',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Objective',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'List of doctors to visit',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Bag(samples, items, studies)',
			// 		rate: 1,
			// 		note: null
			// 	},

			// 	{
			// 		name: 'Using notebook',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Progress vs. pervious calls',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Objective for next  call',
			// 		rate: 1,
			// 		note: null,
			// 		title: 'Pharmacy call'
			// 	},
			// 	// --
			// 	{
			// 		name: 'Choosing pharmacy',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Relationship with pharmacist',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Benefit of the visit(product/doctor)',
			// 		rate: 1,
			// 		note: null
			// 	}
			// ],
			// ratingArrayThree: [
			// 	{
			// 		name: 'Dealing with nurse',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Good communication(two way)',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Opening',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Probing dialogue',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Need identified',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Active listening / observing',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'FAB matching',
			// 		rate: 1,
			// 		note: null
			// 	}, 
			// 	{
			// 		name: 'Message delivered',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Handling objection',
			// 		rate: 1,
			// 		note: null
			// 	},

			// 	{
			// 		name: 'Product / competitors knowledge',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Use of promotional material',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Closing technique',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Ask for business',
			// 		rate: 1,
			// 		note: null
			// 	},
			// 	{
			// 		name: 'Good attitude(enthusiasm)',
			// 		rate: 1,
			// 		note: null
			// 	}
			// ]
			ratingArrayThree: []
        }
    },

	watch: {
		'$route.params.id'(val) {
			if (val !== null) {
				// console.log('yes')	
				// this.doubleVisits.filter(c => c.id === val)
				// console.log(this.doubleVisits.filter(c => c.id === val))
				// this.medicalRepId = 
			} else {
				// console.log('lkj')
			}
		}
	},

    computed: {
        ...mapState({
            sectors: state => state.sectors.sectors,
			medicalReps: state => state.medicalreps.medicalreps,
			entitiesBrief: state => state.entities.entitiesBrief,
			doubleVisits: state => state.doubleVisit.doubleVisits,
			regionsAll: state => state.regions.regionsAll,
			cities: state => state.cities.cities,
			permissionsUser: state => state.auth.youPermissions,
			isSuperAdmin: state => state.auth.isSuperAdmin
        }),
		rating() {
			const i = 0
			const arrayOne = this.ratingArray.map(c => c.rate)
			const arrayTwo = this.ratingArrayThree.map(c => c.rate)
			const total = arrayOne.concat(arrayTwo)
			return (Number(total.reduce((x, y) => x + y, i)) * 100) / 125
			// sum = arr.reduce((x, y) => x + y, i)	
		}
    },
	
	methods: {
		moment,
		fetchInfoDetailType() {
			this.loading = true
            this.$store.dispatch('doubleVisit/fetchInfoDetailType').then((data) => {
				// console.log(data)
				const title = data.filter(c => c.type !== 2).find(c => c.type === 1).title = 'Pharmacy call'
				this.ratingArray = data?.filter(c => c.type !== 2).map(c => ({
					...c, 
					name: c.name,
					note: null,
					rate: 1
				}))
				this.ratingArrayThree = data?.filter(c => c.type === 2).map(c => ({
					...c, 
					note: null,
					name: c.name,
					rate: 1
				}))
			}).finally(() => {
                // this.loading = false/
            })
		},

		update() {
			this.$router.replace({
				name: 'double-visit-report',
				params: {
					id: this.$route.params.id
				}
			})
		},

		submit() {
			// console.log(this.$route.params.id)
			this.loading = true
			const moduleName = this.$route.params.id ? 'update' : 'create' 
			const array = this.ratingArray.concat(this.ratingArrayThree)
			array.map(c => ({
				...c,
				detailTypeId: c.id,
				note: c.note,
				rate: c.rate,
				type: c.detailType
			}))
			this.$store.dispatch(`doubleVisit/${moduleName}`, {
				id: this.$route.params.id,
				date: this.date, 
				medicalRepId: this.medicalRepId,
				employeeId: this.employeeId,
				regionsName: this.regionsName.join(', '), 
				notes: this.notes,
				details: array.map(c => ({
					...c,
					detailTypeId: c.id,
					note: c.note,
					rate: c.rate,
					type: c.detailType
				})),
				}).then(() => {
					this.$store.dispatch('doubleVisit/fetchAll', {}).then((data) => {
						this.$router.replace({
							name: 'double-visit-report',
							params: { id: data[data.length - 1].id },
							query: {
								preview: true
							}
						})
					}).finally(() => {
						this.loading = false
					})
				})
			}
    	},

	created() {
		this.loading = true 
		Promise.all([
			this.$store.dispatch('medicalreps/fetchAll', true),
			this.$store.dispatch('entities/fetchAllBrief', { type: 1 }),
			!this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
				this.$store.dispatch('regions/fetchAllRegions', { cities: data })
                // this.$store.dispatch('sectors/fetchSectorCities', { cities: data })
            }) : null,
			this.$store.dispatch('doubleVisit/fetchAll', { medicalRepId: this.medicalRepId }),
			this.fetchInfoDetailType()
		]).then(() => {
			if (this.$route.params.id) {
				// console.log(this.doubleVisits?.find(c => c.id === this.$route.params.id).details.length)
				const report = this.doubleVisits?.find(c => c.id === this.$route.params.id)
				const detailsGroupOne = report?.details.filter(c => c.detailType !== 2)
				const detailsGroupTwo = report?.details.filter(c => c.detailType === 2)
				this.medicalRepId = report?.medicalRepId
				this.employeeId = report?.employeeId
				this.regionsName = report?.regionsName
				this.date = this.moment(report?.date).format('YYYY-MM-DD')
				this.notes = report?.notes

				for (let i = 0; i < this.ratingArray.length; i++) {
					this.ratingArray[i].detailTypeId = detailsGroupOne ? detailsGroupOne[i]?.detailTypeId : null
					this.ratingArray[i].note = detailsGroupOne[i]?.note
					this.ratingArray[i].rate = detailsGroupOne[i].rate
					this.ratingArray[i].type = detailsGroupOne[i].detailType
				}

				for (let i = 0; i < this.ratingArrayThree.length; i++) {
					this.ratingArrayThree[i].detailTypeId = detailsGroupTwo[i]?.detailTypeId
					this.ratingArrayThree[i].note = detailsGroupTwo[i].note
					this.ratingArrayThree[i].rate = detailsGroupTwo[i].rate
					this.ratingArrayThree[i].type = detailsGroupTwo[i].detailType
				}
			}
		}).finally(() => { 	
			this.loading = false
		})
	}
}
</script>

<style scoped>
button.v-icon.notranslate.v-icon--link.mdi.mdi-circle.theme--light.primary--text {
	padding: 0 !important;
}
button.v-icon.notranslate.v-icon--link.mdi.mdi-circle-outline.theme--light.primary--text {
	padding: 0 !important;
}
</style>
