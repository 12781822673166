import * as types from '../mutation-types';

export default {
    namespaced: true,
    state: {
        companies: []
    },
    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/companies').then(({ data }) => {
                commit(types.STORE_COMPANIES, data)
                return data
            })
        },

        fetchById({ commit }, { id }) {
            return window.axios.get(`/companies/${id}`).then((data) => {
                return data
            })
        },

        create({ commit }, { name }) { 
            // window.axios.defaults.headers.common['Content-Type'] = "application/json"
            // headers: { "Content-Type": "application/json" } 
            return window.axios.post('/Companies', `"${name}"`, { headers: { 'Content-Type': 'application/json' } }).then((data) => {
                return data
            })
        },
        update({ commit }, { id, newName }) {
            return window.axios.put(`/companies/${id}`, {}, { params: { newName } }).then((data) => {
                return data
            })
        },

        delete({ commit }, { id }) {
            return window.axios.delete(`/companies/${id}`)
        }

    },

    mutations: {
        [types.STORE_COMPANIES](state, companies){
			state.companies = companies;
		},  
    },

    getters: {
        getCompanyById: state => id => state.companies.find(c => c.id === id),
    }
}