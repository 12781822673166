export default {
    'new-visit': 'new visit',
    'type-visit': 'Type visit',
    'entities': 'Entities',
    'manager-name': 'Manager name',
    'entities-name': 'Entities name',
    'administrative': 'Administrative',
    'double-visit': 'Double visit',
    'visit': 'Visit',
    'activity': 'activity',
    'medical-rep-name': 'Medical rep name',
    'activities': 'Activities',
    'select-activity': 'Select activity'
}