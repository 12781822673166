<template>
    <div>
        <v-row class="mb-1">
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.products-statement')}}
                </h1>
            </v-col>
        </v-row>
        
        <!-- filter -->
        <v-form @submit.prevent="filter">
            <v-row dense align="center" class="my-0">
                <!-- search type -->
                <v-col sm="auto" cols="12">
                    <span class="me-2">{{$t('ProductsStatement.search-by')}}:</span>
                    <v-btn-toggle
                    	v-model="searchType"
                    	mandatory
                    	dense
                    	color="primary"
                    	class="rounded"
                        @change="searchId = null"
                    >
                        <v-btn>{{$t('ProductsStatement.search-type.all')}}</v-btn>
                        <v-btn>{{$t('ProductsStatement.search-type.city')}}</v-btn>
                        <v-btn>{{$t('ProductsStatement.search-type.medical-rep')}}</v-btn>
                    </v-btn-toggle>
                </v-col>

                <!-- cities -->
                <v-col v-if="searchType === 1" lg="2" md="3" sm="4" cols="12">
                    <v-autocomplete
                        v-model="searchId"
                        :items="cities"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.city')"
                    	dense
                    	outlined
                    	hide-details
                    ></v-autocomplete>
                </v-col>

                <!-- medical-rep -->
                <v-col v-else-if="searchType === 2" lg="2" md="3" sm="4" cols="12">
                    <v-autocomplete
                        v-model="searchId"
                        :items="medicalreps"
                        item-text="name"
                        item-value="accountId"
                        :label="$t('inputs.medical-rep')"
                    	dense
                    	outlined
                    	hide-details
                    ></v-autocomplete>
                </v-col>
                
                <!-- materials -->
                <v-col lg="2" md="3" sm="4" cols="12">
                    <v-autocomplete
                        v-model="materialId"
                        :items="materials"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.product-name')"
                    	dense
                    	outlined
                    	hide-details
                    ></v-autocomplete>
                </v-col>

                <v-spacer/>
                <v-btn
                	color="primary"
                	type="submit"
                	height="28"
                    :disabled="loadingExcel"
                >
                    {{$t('search')}}
                </v-btn>

                <v-divider vertical class="mx-1" />
                <v-btn
                    vpi
                    small
                    color="green lighten-1 white--text"
                    @click="ImportExcel"
                    class="mx-1"
                    :loading="loadingExcel"
                    :disabled="loadingExcel"
                >
                    <v-icon> mdi-file-table-outline </v-icon>
                    {{ $t('export-excel') }}
                </v-btn>
            </v-row>
        </v-form>
        
        <!-- table -->
        <bee-handy-table
        	:headers="headers"
        	:items="loading ? [] : transactionsStatement"
            dense
            fixed-header
            :items-per-page="20"
            :data-table-props="{ disableSort: true }"
            pagination-on-scroll
            :loading="loading"
            :height="$vuetify.breakpoint.height - 130"
        ></bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'ProductsStatement',

    data() {
        return {
            loading: false,
            searchType: 0,
            
            materialId: null,
            searchId: null,
            loadingExcel: false
        }
    },

    computed: {
        headers() {
            return [
                {
                    text: this.$t('headers.product-name'),
                    name: 'materialId',
                    value: 'materialId',
                    class: 'primary--text',
                    cellClass: 'primary--text',
                    noNotAvailableText: true,
                    setValueExpr: val => val ? this.getMaterialById(val).name : '-'
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount',
                    align: 'center',
                    noNotAvailableText: true,
                },
            ]
        },

        ...mapState({
            materials: state => state.materials.materials,
            cities: state => state.cities.cities,
            medicalreps: state => state.medicalreps.medicalreps,
            transactionsStatement: state => state.materialTransactions.transactionsStatement,
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById'
        })
    },

    methods: {
        filter() {
            this.loading = true;

            return this.$store.dispatch('materialTransactions/fetchTransactionsStatement', {
                materialId: this.materialId,
                accountId: this.searchType === 2 ? this.searchId : null,
                cityId: this.searchType === 1 ? this.searchId : null
            }).finally(() => {
                this.loading = false;
            })
        },
        ImportExcel() {
            console.log(this.searchType === 2)
            this.loadingExcel = true
            this.loading = true
            const actionName = this.searchType === 2 ? 'fetchAccountStatisticsExport' : 'fetchMaterialStatisticsExport'
            this.$store.dispatch(`exporter/${actionName}`, { 
                CityId: this.searchId,
                MaterialId: this.materialId,
                AccountId: this.searchId
            }).finally(() => {
                this.loadingExcel = false
                this.loading = false
            })
        }
    },

    created() {
        this.loading = true;
        Promise.all([
            this.filter(),
            !this.cities.length ? this.$store.dispatch('cities/fetchAll') : null,
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
        ]).finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Products statement',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>

</style>