var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"pb-0"},[_c('h1',{staticClass:"text-h6 primary--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.$t('routes.materials'))+" ")])])],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"4","md":"3","align-self":"center"}},[_c('v-autocomplete',{attrs:{"items":_vm.materials,"item-text":"name","item-value":"id","label":_vm.$t('inputs.item'),"outlined":"","dense":"","hide-details":"","multiple":"","loading":_vm.loading,"disabled":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.materialId.length - 1)+") ")]):_vm._e()]}}]),model:{value:(_vm.materialId),callback:function ($$v) {_vm.materialId=$$v},expression:"materialId"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"4","md":"2","align-self":"center"}},[_c('v-autocomplete',{attrs:{"items":_vm.medicalReps,"item-text":"name","item-value":"id","label":_vm.$t('inputs.medical-rep'),"outlined":"","dense":"","hide-details":"","multiple":"","loading":_vm.loading,"disabled":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"me-1"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.medicalRepId.length - 1)+") ")]):_vm._e()]}}]),model:{value:(_vm.medicalRepId),callback:function ($$v) {_vm.medicalRepId=$$v},expression:"medicalRepId"}})],1),_c('v-col',{staticClass:"pa-0 pb-1",attrs:{"cols":"auto","align-self":"center"}},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"row":"","hide-details":"","disabled":_vm.loading},model:{value:(_vm.dateType),callback:function ($$v) {_vm.dateType=$$v},expression:"dateType"}},[_c('v-radio',{attrs:{"label":_vm.$t('daily-date'),"value":"1"}}),_c('v-radio',{attrs:{"label":_vm.$t('from-date-to-date'),"value":"2"}})],1)],1),(_vm.dateType === '2')?_c('v-col',{staticClass:"pt-2",attrs:{"cols":"6","sm":"3","md":"2","align-self":"center"}},[_c('bee-date-picker',{attrs:{"input-label":"Date","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        disabled: _vm.loading
                    }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1):_vm._e(),(_vm.dateType === '2')?_c('v-col',{staticClass:"py-0",attrs:{"cols":"6","sm":"3","md":"2","align-self":"center"}},[_c('bee-date-picker',{attrs:{"input-label":"Date","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        disabled: _vm.loading
                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1):_vm._e(),(_vm.dateType === '1')?_c('v-col',{staticClass:"pa-1 py-0",attrs:{"cols":"6","sm":"5","md":"3","align-self":"center"}},[_c('bee-date-input',{attrs:{"append-icon":_vm.$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left',"prepend-icon":_vm.$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right',"label":_vm.$t('inputs.date'),"input-format-order":"DD-MM-YYYY","dense":"","loading":_vm.loading,"hide-details":"","input-props":{
                        disabled: _vm.loading
                    }},on:{"click:append":function($event){_vm.addDate(); _vm.getMaterial(false)},"click:prepend":function($event){_vm.subDate(); _vm.getMaterial(false)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),_c('v-spacer'),_c('v-col',{staticClass:"mb-2 mt-0",attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"color":"success","small":""},on:{"click":function($event){return _vm.getMaterial(false)}}},[_vm._v(" "+_vm._s(_vm.$t('search'))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"alert-color white--text","small":""},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")])],1)],1),_c('bee-handy-table',{attrs:{"headers":_vm.headers,"items":_vm.materialsForMedicalRep,"zebra":"","dense":"","height":_vm.$vuetify.breakpoint.height - 120,"pagination-on-scroll":"","shown-rows-text":_vm.$t('shown-rows')},on:{"click:row":_vm.goToStatement}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }