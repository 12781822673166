<template>
    <div>
        <!-- delete -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="getDoubleVisits()"
            :success-message="$t('data-is-deleted')"
        >
            {{ $t('are-you-sure-you-want-to') }} 
            <span class="red--text">{{$t('delete')}}</span> 
            {{$t('DoubleVisitsReports.this-double-visit-report') + $t('?')}}
        </delete-dialog>

        <v-btn 
            v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
            small
            class="mx-2 mb-2"
            color="dark-yellow white--text"
            :to="{
                name: 'double-visit-report'
            }"
        >
            <v-icon class="me-2"> mdi-plus-circle-outline </v-icon>
            {{$t('DoubleVisitsReports.add-new-report')}}
        </v-btn>
        <!-- filter -->
        <v-form @submit.prevent="submit">
            <v-row class="mb-1">
                <!-- medicalRepId -->
                <v-col md="2" sm="3" cols="6" >
                    <v-autocomplete
                        v-model="medicalRepId"
                        :items="medicalReps.filter(c => c.employeeType  === userType.medicalRep)"
                        item-value="id"
                        item-text="name"
                        :loading="submitLoading"
                        :disabled="loading"
                        :label="$t('inputs.medical-rep')"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>

                <v-col md="2" sm="3" cols="6" >
                    <v-autocomplete
                        v-model="employeeId"
                        :items="medicalReps.filter(c => c.employeeType  !== userType.medicalRep)"
                        item-value="id"
                        item-text="name"
                        :loading="submitLoading"
                        :disabled="loading"
                        :label="$t('inputs.employees')"
                        outlined
                        dense
                        hide-details
                    />
                </v-col>

                <!-- start date -->
                <v-col md="2" sm="3" cols="6" class="pb-sm-0 pb-md-2">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                        :loading="loading"
                        :disabled="loading"
                    ></bee-date-picker>
                </v-col>

                <!-- end date -->
                <v-col md="2" sm="3" cols="6" class="pb-sm-0 pb-md-2">
                    <bee-date-picker
                        v-model="endDate"
                        :input-label="$t('inputs.end-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>

                <v-spacer />

                <v-col align-self="center" class="d-flex justify-end">
                    <v-btn
                        color="success white--text"
                        small
                        :disabled="loading"
                        class="px-sm-4 mx-2"
                        @click="getDoubleVisits()"
                    >                        
                        <v-icon> mdi-magnify </v-icon>
                        {{ $t('search') }}
                    </v-btn>

                    <v-btn
                        color="alert-color white--text"
                        small
                        :loading="loading"
                        class="px-sm-8"
                        @click="reset(); getDoubleVisits()"
                    >                        
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
        
        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="doubleVisits"
            :loading="loading"
            dense
            fixed-header
            :height="$vuetify.breakpoint.height - 200"
            pagination-on-scroll
            zebra
            :shown-rows-text="$t('shown-rows')"
        >
             <!-- actions -->
            <template v-slot:item.actions="{ item }">
                <tooltip :text="$t('view')" top>
                    <!-- v-if="permissionsUser.WeeklyReports !== roleName.action" -->
                    <v-btn
                        icon
                        small
                        :color="isSuperAdmin || permissionsUser.WeeklyReports !== roleName.action  ? 'success' : 'edit-color'"
                        :to="{
                            name: 'double-visit-report',
                            params: {
                                id: item.id
                            },
                            query: {
                                preview: true
                            }
                        }"
                    >
                        <v-icon>
                            {{ isSuperAdmin || permissionsUser.WeeklyReports !== roleName.action ? 'mdi-eye-outline' : 'mdi-pencil'}}
                        </v-icon>
                    </v-btn>
                </tooltip>
                    
                <tooltip
                    v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
                    :text="$t('delete')"
                    top
                >
                    <v-btn
                        icon
                        small
                        @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'doubleVisit')"
                        color="alert-color"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { roleName, userType } from '@/helpers/enums'
import Tooltip from '@/components/Tooltip.vue'
import DeleteDialog from '@/components/DeleteDialog'
import moment from 'moment'
export default {
    components: {
        Tooltip,
        DeleteDialog
    },

    data() {
        return {
            startDate: null,
            endDate: null,
            doubleReports: [],
            roleName,
            loading: false,
            submitLoading: false,
            medicalRepId: null,
            employeeId: null,
            userType
        }
    },

    computed: {
        headers() {
            const headers = [
                {
                    text: this.$t('headers.date'),
                    name: 'date',
                    value: 'date',
                    setValueExpr: val => this.moment(val).format('YYYY-MM-DD')
                },
                // for role more than team lader
                {
                    text: this.$t('headers.employee-name'),
                    name: 'SupervisorName',
                    value: 'employeeId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                {
                    text: this.$t('headers.medical-rep'),
                    name: 'medicalRep',
                    value: 'medicalRepId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                {
                    text: this.$t('headers.actions'),
                    name: 'actions',
                    value: 'actions'
                }
            ]
            return headers
        },

        ...mapState({
            permissionsUser: state => state.auth.youPermissions,
            doubleVisits: state => state.doubleVisit.doubleVisits,
            medicalReps: state => state.medicalreps.medicalreps,
            isSuperAdmin: state => state.auth.isSuperAdmin,
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById'
        })
    },

    methods: {
        moment,
        getDoubleVisits() {
            this.loading = true
            this.$store.dispatch('doubleVisit/fetchAll', { 
                medicalRepId: this.medicalRepId, 
                employeeId: this.employeeId,
                fromDate: this.startDate, 
                toDate: this.endDate 
            }).finally(() => {
                this.loading = false
            })
        },
        reset() {
            this.medicalRepId = null
            this.employeeId = null
            this.startDate = null
            this.endDate = null
        }
    },

    created() {
        Promise.all([
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            // this.$store.dispatch('accounts/fetchAllBrief', {
            //     type: 0
            // }).then((data) => {
            //     this.accounts = data
            // }),
            this.getDoubleVisits()
        ])
    }
}
</script>
