var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('delete-dialog',{ref:"deleteDialog",attrs:{"success-message":_vm.$t('data-is-deleted')},on:{"on-delete":function($event){return _vm.getDoubleVisits()}}},[_vm._v(" "+_vm._s(_vm.$t('are-you-sure-you-want-to'))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('delete')))]),_vm._v(" "+_vm._s(_vm.$t('DoubleVisitsReports.this-double-visit-report') + _vm.$t('?'))+" ")]),(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports === _vm.roleName.action)?_c('v-btn',{staticClass:"mx-2 mb-2",attrs:{"small":"","color":"dark-yellow white--text","to":{
            name: 'double-visit-report'
        }}},[_c('v-icon',{staticClass:"me-2"},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" "+_vm._s(_vm.$t('DoubleVisitsReports.add-new-report'))+" ")],1):_vm._e(),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"md":"2","sm":"3","cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.medicalReps.filter(function (c) { return c.employeeType  === _vm.userType.medicalRep; }),"item-value":"id","item-text":"name","loading":_vm.submitLoading,"disabled":_vm.loading,"label":_vm.$t('inputs.medical-rep'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.medicalRepId),callback:function ($$v) {_vm.medicalRepId=$$v},expression:"medicalRepId"}})],1),_c('v-col',{attrs:{"md":"2","sm":"3","cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.medicalReps.filter(function (c) { return c.employeeType  !== _vm.userType.medicalRep; }),"item-value":"id","item-text":"name","loading":_vm.submitLoading,"disabled":_vm.loading,"label":_vm.$t('inputs.employees'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.employeeId),callback:function ($$v) {_vm.employeeId=$$v},expression:"employeeId"}})],1),_c('v-col',{staticClass:"pb-sm-0 pb-md-2",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":_vm.$t('inputs.start-date'),"elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    },"loading":_vm.loading,"disabled":_vm.loading},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{staticClass:"pb-sm-0 pb-md-2",attrs:{"md":"2","sm":"3","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":_vm.$t('inputs.end-date'),"elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"align-self":"center"}},[_c('v-btn',{staticClass:"px-sm-4 mx-2",attrs:{"color":"success white--text","small":"","disabled":_vm.loading},on:{"click":function($event){return _vm.getDoubleVisits()}}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" "+_vm._s(_vm.$t('search'))+" ")],1),_c('v-btn',{staticClass:"px-sm-8",attrs:{"color":"alert-color white--text","small":"","loading":_vm.loading},on:{"click":function($event){_vm.reset(); _vm.getDoubleVisits()}}},[_vm._v(" "+_vm._s(_vm.$t('reset'))+" ")])],1)],1)],1),_c('bee-handy-table',{attrs:{"headers":_vm.headers,"items":_vm.doubleVisits,"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.$vuetify.breakpoint.height - 200,"pagination-on-scroll":"","zebra":"","shown-rows-text":_vm.$t('shown-rows')},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('tooltip',{attrs:{"text":_vm.$t('view'),"top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports !== _vm.roleName.action  ? 'success' : 'edit-color',"to":{
                        name: 'double-visit-report',
                        params: {
                            id: item.id
                        },
                        query: {
                            preview: true
                        }
                    }}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports !== _vm.roleName.action ? 'mdi-eye-outline' : 'mdi-pencil')+" ")])],1)],1),(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports === _vm.roleName.action)?_c('tooltip',{attrs:{"text":_vm.$t('delete'),"top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"alert-color"},on:{"click":function($event){return _vm.$refs.deleteDialog.activeDeleteDialog(item.id, 'doubleVisit')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }