var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center justify-space-between"},[(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports === _vm.roleName.action)?_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","color":"dark-yellow white--text","to":{ name: 'weekly-report' }}},[_c('v-icon',{staticClass:"me-2"},[_vm._v(" mdi-plus-circle-outline ")]),_vm._v(" Create New Report ")],1):_vm._e()],1),_c('delete-dialog',{ref:"deleteDialog",attrs:{"success-message":_vm.$t('data-is-deleted')},on:{"on-delete":function($event){return _vm.filter()}}},[_vm._v(" "+_vm._s(_vm.$t('are-you-sure-you-want-to'))+" "),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.$t('delete')))]),_vm._v(" the report ? ")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.isFiltered = true; _vm.filter();}}},[_c('v-row',{staticClass:"my-1",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pb-sm-0 pb-md-2",attrs:{"md":"3","sm":"6","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":"Start date","elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    }},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{staticClass:"pb-sm-0 pb-md-2",attrs:{"md":"3","sm":"6","cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":"End date","elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
                        outlined: true,
                        dense: true,
                        clearable: true,
                    }},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-spacer'):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end mx-md-3",attrs:{"cols":"auto","sm":"auto","md":"2","lg":"4"}},[_c('v-btn',{staticClass:"px-sm-4 mx-2",attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading || _vm.isAllFieldsEmpty,"small":"","type":"submit"}},[_c('v-icon',[_vm._v(" mdi-magnify ")]),_vm._v(" Search ")],1),_c('v-btn',{staticClass:"px-sm-8",attrs:{"disabled":!_vm.isFiltered || _vm.loading,"color":"alert-color white--text","small":""},on:{"click":function($event){_vm.clearFilter(); _vm.filter();}}},[_vm._v(" Reset ")])],1)],1)],1),_c('bee-handy-table',{attrs:{"headers":_vm.headers,"items":!_vm.loading ? _vm.reports : [],"loading":_vm.loading,"dense":"","fixed-header":"","height":_vm.$vuetify.breakpoint.height - 152,"pagination-on-scroll":"","zebra":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('tooltip',{attrs:{"text":"View Report","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","to":{
                            name: 'weekly-report',
                            params: { id: item.id  },
                            query: { preview: true }
                        },"color":_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports !== _vm.roleName.action  ? 'success' : 'edit-color'}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports !== _vm.roleName.action ? 'mdi-eye-outline' : 'mdi-pencil')+" ")])],1)],1),(_vm.isSuperAdmin || _vm.permissionsUser.WeeklyReports === _vm.roleName.action)?_c('tooltip',{attrs:{"text":"Delete doctor","top":""}},[_c('v-btn',{attrs:{"icon":"","small":"","color":"alert-color"},on:{"click":function($event){return _vm.$refs.deleteDialog.activeDeleteDialog(item.id, 'supervisorReports')}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }