import i18n from '@/i18n';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import ar from 'vuetify/lib/locale/ar';
import en from 'vuetify/lib/locale/en';

import colors from 'vuetify/lib/util/colors'
Vue.use(Vuetify);

export default new Vuetify({
	rtl: i18n.locale === 'ar',
	lang: {
		locales: { en, ar },
		current: i18n.locale,
	},
    theme: {
        themes: {
          light: {
            primary: "#217073",
            secondary: "#06837F", 
            // 'primary-light': '#363b3dd6',
            success: '#13a689',
            error: '#ff3334',
            errorLight: '#eeb4b3',
            green: '#0f8145',
            'light-success':'#13a689',
            accent: colors.indigo.base, // #3F51B5,
            'alert-color': '#e94747',
            'dark-yellow': '#efa720',

            'edit-color': '#dd8b1b',
            'orange-light':'#fba819',
          },
        },
    },
});
