<template>
    <div>
        <div class="d-flex justify-space-between mb-1">
            <h2 class="text-h5 primary--text text-capitalize">warehouses</h2>

            <div>
                <!-- add new sale -->
                <v-btn small @click="addWarehouseDialog" color="primary" class="mx-1">
                    <v-icon class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    add new warehouse
                </v-btn>

                <!-- BACK -->
                <v-btn
                    color="grey white--text"
                    small
                    :to="{
                        name: 'sales'
                    }"
                >
                    <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                    Back
                </v-btn>
            </div>
        </div>
        
        <!-- warehouse dialog decleared here to used it as refs -->
        <!-- <warehouse-dialog ref="WarehouseDialog" @on-save="fetchWarehouses"/> -->
        <v-dialog
            transition="dialog-transition"
            max-width="400"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form">
                    <v-card>
                        <div class="d-flex justify-end py-1 background-title-card ">
                            <v-card-title
                                class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                            >
                                {{ title }}
                            </v-card-title>
                            <!-- close button -->
                            <v-btn icon @click="dialog.value = false; $refs.form.resetValidation()">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-card-text class="pt-5">
                            <!-- dialog content -->
                            <v-container>
                                <v-row>
                                    <v-text-field
                                        v-model="name"
                                        label="Warehouse Name"
                                        :rules="rules.required"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                :loading="loading"
                                :disabled="loading"
                                color="success"
                                elevation="0"
                                text
                            >
                                save
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                elevation="0"
                                text
                            >
                                cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar
          v-model="snackbar"
          transition="slide-y-reverse-transition"
          color="primary"
        >
            {{ message }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    color="success lighten-2"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                Close
                </v-btn>
            </template>
        </v-snackbar>

        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
          ref="deleteDialog"
          @on-delete="fetchWarehouses"
          :success-message="$t('data-is-deleted')"
        >
            <template v-slot:default>
                <p class="mb-2">
                    {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{ $t('delete') }}</span> the warhouse?
                </p>
            </template>
        </delete-dialog>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? warehouses : []"
            :loading="loading"
            dense
            :height="$vuetify.breakpoint.height - 90"
            pagination-on-scroll
            fixed-header
            zebra
        >
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Edit warehouse" top>
                        <v-btn
                          icon
                          color="edit-color"
                          small
                          @click="editWarehouseDialog(item.id)"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete warehouse" top>
                        <v-btn icon small @click="activeDeleteDialog(item.id, 'warehouses')">
                            <v-icon color="alert-color">
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
        <!-- <v-data-table
            :headers="headers"
            :items="!loading ? warehouses : []"
            :items-per-page="5"
            :loading="loading"
            multi-sort
            loading-text="Loading... Please wait"
            class="elevation-3"
        > -->
            <!-- actions -->
            <!-- <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip text="Edit warehouse" top>
                        <v-btn
                          text
                          small
                          @click="editWarehouseDialog(item.id)"
                        >
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip text="Delete warehouse" top>
                        <v-btn text small @click="activeDeleteDialog(item.id, 'warehouses')">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template> -->
        <!-- </v-data-table> -->
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
// import WarehouseDialog from '../../../components/WarehouseDialog'
import DeleteDialog from '../../../components/DeleteDialog'
import Tooltip from '../../../components/Tooltip'
import rules from '../../../validation rules'
export default {
    data(){
        return {
            // helpers
            loading: false,
            headers:[
                { text: 'Warehouse Name', value: 'name', align: 'start', sortable: true, class:'text-caption font-weight-bold' },
                { text: 'Actions', name: 'actions', value: 'actions', sortable: false, align: 'center' },
            ],

            // warehouses dialog 
            // helpers
            title: null,
            snackbar: false,
            message: null,
            warehouseId: null,

            // data
            name: null,
            rules
        }
    },

    components:{
        // WarehouseDialog,
        DeleteDialog,
        Tooltip
    },

    computed:{
        ...mapState({
            warehouses: state => state.warehouses.warehouses
        }),
        ...mapGetters({
            getWarehouseById: 'warehouses/getWarehouseById'
        })
    },

    methods:{
        fetchWarehouses(){
            this.loading = true;
            this.$store.dispatch('warehouses/fetchAll')
            .finally(() => {
                this.loading = false;
            })
        },

        // warehouse dialog fuctions
        addWarehouseDialog(){
            // this.$refs.WarehouseDialog.addWarehouseDialog();
            this.reset();
            this.title = 'add new warehouse';
            this.$refs.dialog.isActive = true;
        },

        editWarehouseDialog(id){
            // this.$refs.WarehouseDialog.editWarehouseDialog(id);
            this.reset();
            this.title = 'edit warehouse';
            this.warehouseId = id;
            this.name = this.getWarehouseById(id).name;
            this.$refs.dialog.isActive = true;
        },

        // delete dialog function
        activeDeleteDialog(id, moduleType){
            this.$refs.deleteDialog.activeDeleteDialog(id, moduleType);
        },

        submit(){
            if (this.$refs.form.validate()){
                this.loading = true;
                
                // create warehouse
                if (this.warehouseId === null){
                    this.$store.dispatch('warehouses/create', {
                        name: this.name
                    })
                    .then(() => {
                        this.message = 'Warehouse is added';
                        this.fetchWarehouses();
                        this.$refs.dialog.isActive = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.snackbar = true;
                        this.loading = false;
                    })
                }
                // edit warehouse
                else {
                    this.$store.dispatch('warehouses/update', {
                        id: this.warehouseId,
                        name: this.name
                    })
                    .then(() => {
                        this.message = 'Warehouse is edited';
                        this.fetchWarehouses();
                        this.$refs.dialog.isActive = false;
                    })
                    .catch((e) => {
                        this.message = e.response.data.message;
                    })
                    .finally(() => {
                        this.snackbar = true;
                        this.loading = false;
                    })
                }
            }
        },

        reset(){
            if (this.name !== null) this.$refs.form.resetValidation();
            this.name = null;
            this.warehouseId = null;
        },
    },

    mounted(){
        this.loading = true;
        this.$store.dispatch('warehouses/fetchAll')
        .finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Warehouses',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>

</style>