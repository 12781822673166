export default {
    'new': 'جديد',
    'modification-requests': 'طلبات التعديل',
    'specializations': 'التخصصات',
    'classification': 'تصنيف',
    'edit-doctor': 'تعديل الطبيب',
    'add-new-doctor': 'إضافة طبيب جديد',
    'resident-doctors': 'الأطباء المقيمين',
    'center-specialty': 'تخصص المركز',
    'best-time-to-visit': 'أفضل وقت للزيارة',
    'please-make-sure-to-fill-out-the-fields': 'يرجى التأكد من ملئ حقول (الاسم , الاختصاص, العنوان)'
}