<template>
    <div class="mt-2">
        <!-- filter -->
        <v-row class="">
            <v-col cols="2" class="mb-1">
                <!-- doctor name -->
                <v-autocomplete 
                    v-model="doctor"
                    :items="[]"
                    item-text="name"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    :label="$t('inputs.doctor')"
                />
            </v-col>
        </v-row>
       <bee-handy-table
            :items="[]"
            :headers="headers"
            dense
            zebra
            pagination-on-scroll
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            doctor: null,
            loading: false
        }
    },
    computed: {
        headers () {
            return [
                {
                    text: this.$t('headers.material-name'),
                    name: 'materialId',
                    value: 'materialId'
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount'
                }
            ]
        }
    },

    methods: {
        // getMaterials() {
        //     this
        // }
    },

    created () {
        this.loading = true
        this.$store.dispatch('entities/fetchAllBrief', { type: 1 }).finally(() => {
            this.loading = false
        })
    }
}
</script>

<style scoped>

</style>
