<template>
    <div>
        <!-- inputs -->
        <v-form>
            <v-row class="pt-2">
                <v-col cols="2" class="px-2">
                    <v-text-field 
                        :label="$t('inputs.doctor')"
                        dense
                        outlined
                        hide-details
                    />
                </v-col>

                <v-col cols="2" class="px-2">
                    <v-text-field 
                        label="العنوان"
                        dense
                        outlined
                        hide-details
                    />
                </v-col>

                <v-col cols="2" class="px-2">
                    <v-text-field 
                        label="الاختصاص"
                        dense
                        outlined
                        hide-details
                    />
                </v-col>

                <v-col cols="2" class="px-2">
                    <v-text-field 
                        label="رقم الهاتف"
                        dense
                        outlined
                        hide-details
                    />
                </v-col>

                <v-col class="d-flex justify-end">
                    <v-spacer />
                    <v-btn color="success" small>
                        Save
                    </v-btn>
                </v-col>
            </v-row>
            <v-radio-group row class="mt-1" hide-details>
                <v-radio
                    value="1"
                    label="عيادة"
                ></v-radio>
                <v-radio value="2" label="مركز"></v-radio>
            </v-radio-group>

            <v-radio-group row label="وضع العيادة :" class="" hide-details>
                <v-radio value="1" label="A"></v-radio>
                <v-radio value="2" label="B"></v-radio>
                <v-radio value="3" label="c"></v-radio>
            </v-radio-group>

            <v-radio-group row label="نسبة الزرع لدى الطبيب :" class="" hide-details>
                <v-radio value="1" label="A"></v-radio>
                <v-radio value="2" label="B"></v-radio>
                <v-radio value="3" label="C"></v-radio>
                <v-radio value="4" label="D"></v-radio>
            </v-radio-group>

            <v-text-field 
                label="ملاحظات عن استقبال الطبيب"
                outlined
                dense
            />
        </v-form>

        <!-- 2 -->
        <div>
            <h3> اهتمام الطبيب </h3>
            
            <v-radio-group row label="هل لديه اهتمام بالطعوم العظمية  :" class="" hide-details>
                <v-radio value="1" label="نعم"></v-radio>
                <v-radio value="2" label="لا"></v-radio>
            </v-radio-group>

            <v-radio-group row label="هل لديه اهتمامات بالدورات التدريبية  :" class="" hide-details>
                <v-radio value="1" label="نعم"></v-radio>
                <v-radio value="2" label="لا"></v-radio>
            </v-radio-group>

            <v-radio-group row label="هل يعمل بالزرع :" class="" hide-details>
                <v-radio value="1" label="نعم"></v-radio>
                <v-radio value="2" label="لا"></v-radio>
            </v-radio-group>
        </div>

        <!-- 3 -->
        <div>
            <h3> الأجهزة الطبية الموجودة </h3>
            <v-row>
                <v-col>
                    <v-text-field
                        label="وضع التجهيزات الطبية"
                        dense
                        outlined
                    />
                </v-col>

                <v-col>
                    <v-text-field
                        label="عدد الزرعات المستهلكة تقريبا بالشهر"
                        dense
                        outlined
                    />
                </v-col>

                <v-col>
                    <v-text-field
                        label="أسعار الزرعات مع الشركات التي بتعامل معها"
                        dense
                        outlined
                    />
                </v-col>

                <v-col>
                    <v-text-field
                        label="ما هي الزرعات التي يزرعها الطبيب"
                        dense
                        outlined
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {}
</script>
