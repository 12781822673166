export default {
    'the-visit': 'the visit',
    'today': 'Today',
    'doctor': 'Doctor',
    'pharmacy': 'Pharmacy',
    'center': 'Center',
    'add-visit': 'Add visit',
    'edit-visit': 'Edit visit',
    'filter': 'Filter',
    'materials': 'Materials',
    'competitors': 'Competitors',
    "unavailable-products": "Unavailable Products",
    "visit-notes": "Visit Notes",
    "next-call": "Next call",
    "minutes": "Minutes",
    "objection": "Objection"
}