export default {
    'show-regions': 'Show regions',
    'edit-city': 'Edit city',
    'city': 'City',
    'region': 'Region',
    'area': 'Area',
    'fa-this': 'this',
    'ma-this': 'this',
    'created': 'created',
    'edited': 'edited'
}