export default {
    home: 'Home',
    'scientific-office': 'Scientific office',
    visits: 'visits',
    'med-rep-plan': 'Med. Rep plan',
    'day-off-requests': 'Day off requests',
    'day-off-request': 'Day off request',
    reports: 'Reports',
    'med-rep-mark': 'Med.Rep. Mark',
    exams: 'Exams',
    salaries: 'Salaries',
    storehouse: 'Storehouse',
    'delivery-materials': 'Movement of materials',
    'items-journal': 'Warehouse journal',
    statement: 'Inventory',
    management: 'Management',
    staff: 'Staff',
    customers: 'Customers',
    'managing-roles': 'Managing roles',
    'external-entities': 'External entities',
    products: 'Products',
    'regions-management': 'Regions management',
    'my-profile': 'My profile',
    'my-visits': 'My visits',
    'my-report': 'My report',
    'my-plan': 'My plan',
    companies: 'Companies',
    logout: 'Logout',
    materials: 'Materials',
    'loaned-materials': 'Loaned materials',

    'add-day-off-request': 'Add day off request',
    'products-statement': 'Products statement',
    'minimal-interface': 'Minimal interface'
}