<template>
    <div>
        <v-form ref="form" :disabled="submitLoading || isPreview">
            <!-- dates -->
            <v-row dense class="ma-0">
                <!-- start date -->
                <v-col cols="12" sm="6" md="2">
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                        :loading="loading"
                        :rules="rules.date"
                    ></bee-date-picker>
                </v-col>

                <!-- end date -->
                <v-col cols="12" sm="6" md="2">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details="auto"
                        :days-over-max="20"
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                        :loading="loading"
                        :rules="[val => val > startDate || 'date must be after start date']"
                    ></bee-date-picker>
                </v-col>

                <v-divider vertical class="my-2"/>
                
                <!-- notes -->
                <v-col cols="12" md="8">
                    <v-text-field
                    	v-model="notes"
                    	label="Notes"
                    	dense
                    	hide-details
                    	outlined
                    />
                </v-col>
            </v-row>
        </v-form>
        <v-row dense class="ma-0">
            <!-- visits -->
            <v-col cols="12" md="6">
                <div class="d-flex justify-space-between mb-1">
                    <h3 class="text-body-1 primary--text mb-1"> Visits </h3>
                    <v-btn 
                        :to="{ name: 'administrative-visit' }" 
                        color="success" 
                        small
                        :disabled="submitLoading || isPreview"
                    >
                        Add new
                    </v-btn>
                </div>

                <bee-handy-table
                    :headers="headersVisits"
                    :items="loadingVisits || loading ? [] : visits"
                    :loading="loadingVisits"
                    :items-per-page="-1"
                    fixed-header
                    :data-table-props="{ disableSort: true, loaderHeight: 3 }"
                    hide-default-footer
                    dense
                    zebra
                    height="155"
                ></bee-handy-table>
            </v-col>

            <!-- double visits -->
            <v-col cols="12" md="6">
                <div class="d-flex justify-space-between mb-1">
                    <h3 class="text-body-1 primary--text mb-1"> Double Visits </h3>
                    <v-btn 
                        :to="{ name: 'double-visit-report' }" 
                        color="success" 
                        small
                        :disabled="submitLoading || isPreview"
                    >
                        Add new
                    </v-btn>
                </div>

                <bee-handy-table
                    :headers="headersDouble"
                    :items="doubleVisits"
                    :items-per-page="-1"
                    fixed-header
                    :data-table-props="{ disableSort: true, loaderHeight: 3 }"
                    hide-default-footer
                    dense
                    zebra
                    height="155"
                ></bee-handy-table>
            </v-col>

            <!-- activities -->
            <v-col cols="12">
                <bee-handy-smart-table
                    ref="table"
                    v-model="tableInputs"
                    :headers="headers"
                    :items="supervisorActivities"
                    dense
                    :loading="loading"
                    :items-per-page="-1"
                    :data-table-props="{
                        disableSort: true
                    }"
                    hide-no-data-area
                    pagination-on-scroll
                    stay-in-input-fields
                    fixed-header
                    :height="($vuetify.breakpoint.height - 90) / 2"
                    :show-add-row="!submitLoading && remainActivitiesDaysCount > 0 && !isPreview"
                    :editable-rows="!submitLoading && !loading"
                    :item-class="(item) => !isBetweenStartAndEnd(item.date) ? 'red lighten-4' : null"
                    zebra
                    @edit-cancelled="resetTableInputs"
                    @keypress.enter="!isEditItem ? addItem(tableInputs.date) : editItem()"
                >
                    <template v-slot:no-input.actions>
                        <div class="d-flex justify-center">
                            <tooltip text="Save" top>
                                <v-btn
                                    v-if="!isPreview"
                                    icon
                                    small
                                    color="success"
                                    @click="!isEditItem ? addItem(tableInputs.date) : editItem()"
                                >
                                    <v-icon>mdi-check-circle-outline</v-icon>
                                </v-btn>
                            </tooltip>
                            <tooltip text="Cancel" top>
                                <v-btn
                                    v-if="isEditItem"
                                    icon
                                    small
                                    color="grey darken-2"
                                    @click="$refs.table.resetEdit()"
                                >
                                    <v-icon>
                                        mdi-close-circle-outline
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                        </div>
                    </template>

                    <!-- actions -->
                    <template v-slot:item.actions="{ index }">
                        <div class="d-flex justify-center">
                            <tooltip  text="Edit Report" top>
                                <v-btn
                                    v-if="!isPreview"
                                    icon
                                    small
                                    color="edit-color"
                                    @click="
                                        $refs.table.activeEditForRow(index);
                                        rowEditIndex = index;
                                        isEditItem = true;
                                        $nextTick(() => { $refs.table.resetInputsFocus() })
                                    "
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                        
                            <tooltip text="Delete doctor" top>
                                <v-btn
                                    v-if="!isPreview"
                                    icon
                                    small
                                    @click="deleteItem(index)"
                                    color="alert-color"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                        </div>
                    </template>

                    <!-- footer -->
                    <template v-slot:pagination-on-scroll.page-text="{ shownItemsLength, totalItems }">
                        <div class="d-flex align-center px-4">
                            <div v-if="remainActivitiesDaysCount >= 0">
                                <span
                                    :class="[
                                        { 'success--text': remainActivitiesDaysCount > 0 },
                                        'text--darken-1 me-1 text-body-1'
                                    ]"
                                >
                                    {{remainActivitiesDaysCount.toString().padStart(2, 0)}}
                                </span> 
                                remaining days
                            </div>

                            <div v-else-if="startDate && endDate" class="error--text text--darken-1">
                                Out of dates range
                            </div>

                            <v-spacer/>
                            
                            <div>shown rows {{`${shownItemsLength} / ${totalItems}`}}</div>
                        </div>
                    </template>
                </bee-handy-smart-table>

                <div class="d-flex justify-end mt-1">
                    <v-btn
                        v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
                        :loading="loading"
                        :disabled="loading"
                        :color="isPreview ? 'edit-color white--text' : 'success white--text' "
                        elevation="0"
                        class="px-sm-4 mx-2 text-body-2"
                        small
                        @click="isPreview ? $router.replace({
                            name: 'weekly-report',
                            params: { id: $route.params.id }
                        }) : submit()"
                    >
                        {{ isPreview ? 'Edit' : $route.params.id ?  'Save' : 'Create report'  }}
                    </v-btn>
                    <v-btn
                        elevation="0"
                        small
                        class="text-body-2"
                        color="grey darken-2 white--text"
                        :to="{name: 'administrative-reports'}"
                        :disabled="loading || isPreview"
                    >
                        cancel
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import rules from '@/validation rules'
import Tooltip from '@/components/Tooltip'
import { roleName } from '@/helpers/enums'
import moment from 'moment'
export default {
    name: 'SupervisorReport',

    data() {
        return {
            startDate: !this.$route.params.id ? this.moment().format('YYYY-MM-DD') : null,
            endDate: !this.$route.params.id ? this.moment().add(7, 'days').format('YYYY-MM-DD') : null,
            submitLoading: false,
            loading: false,
            roleName,
            
            rules,
            test: null,
            pastOffOfficeActivities: null,
            pastOfficeActivities: null,
            plannedOfficeActivities: null,
            supervisorActivities: [],
            plannedOffOfficeActivities: null,
            tableInputs: {},
            visitedHospitals:  null,

            // helpers
            rowId: 0,

            // visits
            loadingVisits: false,
            visits: [],

            // data
            unavailableMaterials:  null,
            notes: null,
            rowEditIndex: null,
            isEditItem: false
        }
    },
    
    components: {
        Tooltip
    },

    watch: {
        'startDate'(val) {
            if (val !== null && !this.loading) {
                this.tableInputs.date = this.startDate;
                this.fetchVisits();
            }
        },
        'endDate'(val) {
            if (val && !this.loading) {
                this.fetchVisits();
            }
        }
    },

    computed: {
        isPreview() { return Boolean(this.$route.query.preview) },

        remainActivitiesDaysCount() {
            return moment(this.endDate).diff(this.latestSupervisorActivityDate || this.startDate, 'days')
        },

        headers() {
            const headers = [
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    dateFormat: 'DD-MM-YYYY',
                    type: 'date',
                    inputRules: [
                        val => this.isBetweenStartAndEnd(val) ||
                        'must be between the start and end dates'
                    ],
                    width: 180
                }, 
                {
                    text: 'Supervisor activities',
                    name: 'activity',
                    value: 'activity',
                    inputRules: rules.required
                }
            ];

            if (!this.$route.query.preview) {
                headers.push({
                    text: 'actions',
                    name: 'actions',
                    align: 'center',
                    width: 80,
                    sortable: false,
                    noInput: true,
                });
            }

            return headers
        },
        ...mapState({
            userData: state => state.auth.userData, 
            reports: state => state.supervisorReports.reports, 
            specialties: state => state.specialties.specialties, 
            isSuperAdmin: state => state.auth.isSuperAdmin,
            permissionsUser: state => state.auth.youPermissions,
            doubleVisits: state => state.doubleVisit.doubleVisits,
            medicalReps: state => state.medicalreps.medicalReps
        }),
        ...mapGetters({
            getReportById: 'supervisorReports/getReportById',
            getSpecialtyById: 'specialties/getSpecialtyById',
            isAdmin: 'auth/isAdmin',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
        }),

        latestSupervisorActivityDate () {
            return this.supervisorActivities[this.supervisorActivities.length - 1]?.date ?? null;
        },

        headersVisits() {
            const headersVisits = [
                {
                    text: 'Doctor',
                    name: 'doctor',
                    value: 'entity.name'
                },
                {
                    text: 'specialty',
                    name: 'specialty',
                    value: 'entitySpecialtyId',
                    noNotAvailableText: true,
                    setValueExpr: val => {
                        const specialties = [];
                        val && val.forEach(specialtyId => {
                            specialties.push(this.getSpecialtyById(specialtyId)?.name)
                        });

                        return specialties.length ? specialties.join(', ') : '-';
                    }
                },
                {
                    text: 'Date',
                    name: 'date',
                    value: 'createdAt',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD')
                },
                {
                    text: 'Note',
                    name: 'notes',
                    value: 'notes'
                }
            ]
            return headersVisits
        },

        headersDouble() {
            const headersDouble = [
                {
                    text: 'Medical rep name',
                    name: 'medicalRep',
                    value: 'medicalRepId',
                    setValueExpr: val => this.getMedicalRepById(val)?.name
                },
                // {
                //     text: 'Doctor',
                //     name: 'doctor',
                //     value: 'doctor'
                // },
                // {
                //     text: 'specialty',
                //     name: 'specialty',
                //     value: 'specialtyId'
                // },
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    setValueExpr: val => moment(val).format('YYYY-MM-DD')
                },
                {
                    text: 'Note',
                    name: 'note',
                    value: 'note'
                }
            ]
            return headersDouble
        }
    },

    methods: {
        addItem() {
            if (this.$refs.table.validate()) {
                this.supervisorActivities.push({
                    id: this.rowId++,
                    ...this.tableInputs
                });
                if (this.remainActivitiesDaysCount > 0) this.resetTableInputs();
            }
        },

        editItem() {
            if (this.$refs.table.validate()) {
                this.supervisorActivities[this.rowEditIndex].date = this.tableInputs.date;
                this.supervisorActivities[this.rowEditIndex].activity = this.tableInputs.activity;
                this.rowEditIndex = false;
                this.isEditItem = false;

                this.$refs.table.resetEdit()
                this.resetTableInputs();
            }
        },
        
        deleteItem(index) {
			this.$refs.table.resetEdit();
			this.isEditItem = false;
			this.supervisorActivities.splice(index, 1);
            this.rowId--;

            for (let i = 0; i < this.supervisorActivities.length; i++) {
                if (this.supervisorActivities[i].id > index) {
                    this.supervisorActivities[i].id--;
                }
            }

			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus();
            })
		},

        resetTableInputs() {
            this.$nextTick(() => {
                let date;
                if (moment(this.latestSupervisorActivityDate).isBefore((this.endDate), 'day')) {
                    date = moment(this.latestSupervisorActivityDate).add(1, 'days').format('YYYY-MM-DD')
                }
                this.$refs.table.resetInputs({ date });
                this.$refs.table.resetInputsFocus();
            })
        },

        fillData(data){
            this.startDate = moment(data.fromDate).format('YYYY-MM-DD');
            this.endDate = moment(data.toDate).format('YYYY-MM-DD');
            this.pastOfficeActivities = data.pastOfficeActivities;
            this.plannedOfficeActivities = data.plannedOfficeActivities;
            this.pastOffOfficeActivities = data.pastOffOfficeActivities;
            this.plannedOffOfficeActivities = data.plannedOffOfficeActivities;
            
            data.activities.forEach((item) => {
                this.supervisorActivities.push({
                    id: this.rowId++,
                    date: moment(item.date).format('YYYY-MM-DD'),
                    activity: item.activity
                });
            });

            this.visitedHospitals = data.visitedHospitals;
            this.unavailableMaterials = data.unavailableMaterials;
            this.notes = data.notes;
        },

        isBetweenStartAndEnd(date) {
            return moment(date).isBetween(this.startDate, this.endDate, 'days', '[]')
        },

        submit(){
            const activitiesDates = this.supervisorActivities.map(c => c.date);
            for (let i = 0; i < activitiesDates.length; i++) {
                if (!this.isBetweenStartAndEnd(activitiesDates[i])) {
                    this.$eventBus.$emit('show-snackbar', 'Some entires are out of dates range', 'error');
                    return;
                }
            }
            
            this.submitLoading = true;
            const action = this.$route.params.id ? 'update' : 'create';
            this.$store.dispatch(`supervisorReports/${action}`, {
                id: this.$route.params.id,
                fromDate: this.startDate,
                toDate: this.endDate,
                pastOfficeActivities: this.pastOfficeActivities,
                plannedOfficeActivities: this.plannedOfficeActivities,
                pastOffOfficeActivities: this.pastOffOfficeActivities,
                plannedOffOfficeActivities: this.plannedOffOfficeActivities,
                activities: this.supervisorActivities.map(c => ({ date: c.date, activity: c.activity })),
                visitedHospitals: this.visitedHospitals,
                unavailableMaterials: this.unavailableMaterials,
                notes: this.notes
            })
            .then(() => {
                this.$eventBus.$emit('show-snackbar', this.$route.params.id ? 'Report is edited' : 'Report added', 'info');
                this.$router.push({ name: 'supervisors-reports' });
            })
            .finally(() => {
                this.submitLoading = false;
            })
        },

        // visits
        fetchVisits() {
            this.loadingVisits = true;
            return this.$store.dispatch('visits/fetchVisitsWithoutPagination', {
                fromDate: this.startDate,
                toDate: this.endDate,
                employeeId: this.getReportById(this.$route.params.id)?.supervisorId
            }).then(data => {
                this.visits = data;
            }).finally(() => {
                this.loadingVisits = false;
            })
        },

        // helpers
        moment
    },

    created() {
        const promises = [
            this.fetchVisits(),
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            this.$store.dispatch('medicalreps/fetchAll', true)
        ];
        if (this.$route.query.employeeId) {
            this.$store.dispatch('supervisorReports/fetchAll', {
                employeeId: this.$route.query.employeeId
            }).then(data => {
                this.$router.replace({
                    name: 'weekly-report',
                    params: {
                        id: data[data.length - 1].id
                    }
                })
            })
        }

        if (this.$route.params.id) {
            const fillHandler = (responseData) => {
                const data = responseData || this.getReportById(this.$route.params.id);
                var employeeId = data?.supervisorId
                this.$store.dispatch('doubleVisit/fetchAll', { 
                    // medicalRepId: employeeId, 
                    employeeId: employeeId
                })
                this.fillData(data);

                const activities = data.activities;
                this.tableInputs.date =
                    this.startDate
                        ? moment(activities[activities.length - 1].date).format('YYYY-MM-DD')
                        : null;
            }

            if (this.reports.length === 0) {
                promises.push(
                    this.$store.dispatch('supervisorReports/fetchById', {
                        id: this.$route.params.id
                    }).then((data) => {
                        fillHandler(data);
                    })
                )
            } else {
                fillHandler();
            }
        } else {
            if (this.startDate !== null) this.tableInputs.date = this.startDate
            this.$store.dispatch('doubleVisit/fetchAll', { 
                // medicalRepId: employeeId, 
                employeeId: this.userData.employeeId
            })
        }

        this.loading = true;
        Promise.all(promises).finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Weekly report',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
