<template>
    <div>
        <v-form ref="form">
            <!-- dates -->
            <v-row class="ma-0">
                <!-- start date -->
                <v-col cols="12" sm="6" md="2" class="pt-0 pb-1 px-1">
                    <bee-date-picker
                        v-model="startDate"
                        input-label="Start date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                        :loading="loading"
                        :disabled="Boolean($route.query.preview)"
                        :rules="rules.date"
                    ></bee-date-picker>
                </v-col>

                <!-- end date -->
                <v-col cols="12" sm="6" md="2" class="pt-0 pb-1 px-1">
                    <bee-date-picker
                        v-model="endDate"
                        input-label="End date"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details="auto"
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                        :loading="loading"
                        :disabled="Boolean($route.query.preview)"
                        :rules="[val => val > startDate || 'Must be end date big more than start date']"
                    ></bee-date-picker>
                </v-col>

                <v-divider vertical />
                <v-col cols="12" md="8" class="pt-0 pb-1 px-1">
                    <v-text-field dense hide-details outlined label="Notes" />
                </v-col>
            </v-row>

            <v-row v-if="0" class="ma-0">
                <v-col cols="12" md="6">
                    <!-- Activities inside the office -->
                    <h3 class="text-body-1 primary--text mb-1">Activities inside the office</h3>
                    <v-row>
                        <!-- past office activities -->
                        <v-col cols="12" sm="6">
                            <v-textarea
                                v-model="pastOfficeActivities"
                                label="Past activities"
                                :disabled="Boolean($route.query.preview)"
                                :loading="loading"
                                rows="2"
                                auto-grow
                                outlined
                                dense
                                hide-details
                                no-resize
                            />
                        </v-col>

                        <!-- planned office activities -->
                        <v-col cols="12" sm="6">
                            <v-textarea
                                v-model="plannedOfficeActivities"
                                label="Planned activities"
                                rows="2"
                                :disabled="Boolean($route.query.preview)"
                                :loading="loading"
                                outlined
                                hide-details
                                no-resize
                                dense
                            />
                        </v-col>

                        <!-- Activities outside the office -->
                        <v-col cols="12" class="py-0">
                            <h3 class="text-body-1 primary--text">Activities outside the office</h3>  
                        </v-col>
                        
                        <!-- past off office activities -->
                        <v-col cols="12" sm="6">
                            <v-textarea
                                v-model="pastOffOfficeActivities"
                                label="Past activities"
                                outlined
                                hide-details
                                rows="2"
                                :disabled="Boolean($route.query.preview)"
                                no-resize
                                dense
                                class="text-body-2"
                                :loading="loading"
                            />
                        </v-col>

                        <!-- planned off office activities -->
                        <v-col cols="12" sm="6">
                            <v-textarea
                                v-model="plannedOffOfficeActivities"
                                outlined
                                hide-details
                                label="Planned activities"
                                rows="2"
                                :disabled="Boolean($route.query.preview)"
                                no-resize
                                dense
                                class="text-body-2"
                                :loading="loading"
                            />
                        </v-col>
                        
                        <!-- more details -->
                        <v-col cols="12" class="py-0">
                            <h3 class="text-body-1 primary--text">Additional details</h3>
                        </v-col>
                        
                        <!-- visited hospitals -->
                        <v-col cols="12" sm="6" class="pb-0">
                            <v-textarea
                                v-model="visitedHospitals"
                                :disabled="Boolean($route.query.preview)"
                                rows="2"
                                outlined
                                no-resize
                                dense
                                label="Visited hospitals"
                                class="text-body-2"
                                :loading="loading"
                                hide-details
                            />
                        </v-col>

                        <!-- unavailable materials -->
                        <v-col cols="12" sm="6" class="pb-0">
                            <v-textarea
                                :disabled="Boolean($route.query.preview)"
                                rows="2"
                                outlined
                                label="Unavailable materials"
                                no-resize
                                dense
                                class="text-body-2"
                                :loading="loading"
                                hide-details
                            />
                        </v-col>

                        <!-- notes -->
                        <v-col class="py-0">  
                            <v-textarea
                                v-model="notes"
                                label="Notes"
                                :loading="loading"
                                :disabled="Boolean($route.query.preview)"
                                rows="2"
                                outlined
                                no-resize
                                dense
                                class="text-body-2 mt-2"
                            />
                        </v-col>
                    </v-row>
                </v-col>

                <v-divider vertical class="my-2 " />

                <v-col cols="12" md="6">
                    <h3 class="text-body-1 primary--text ">Supervisor activities</h3>
                    <bee-handy-smart-table
                        ref="table"
                        v-model="tableInputs"
                        :headers="headers"
                        :items="supervisorActivities"
                        dense
                        :loading="loading"
                        :items-per-page="-1"
                        :data-table-props="{
                            disableSort: true
                        }"
                        hide-no-data-area
                        pagination-on-scroll
                        stay-in-input-fields
                        fixed-header
                        height="350px"
                        :show-add-row="this.remainActivitiesDaysCount > 0 && !Boolean($route.query.preview)"
                        :editable-rows="!loading"
                        :item-class="(item) => !isBetweenStartAndEnd(item.date) ? 'red lighten-4' : null"
                        zebra
                        @edit-cancelled="resetTableInputs"
                        @keypress.enter="!isEditItem ? addItem(tableInputs.date) : editItem()"
                    >
                        <template v-slot:no-input.actions>
                             <div class="d-flex justify-center">
                                <tooltip text="Save" top>
                                    <v-btn
                                        v-if="Boolean($route.query.preview)"
                                        icon
                                        small
                                        color="success"
                                        @click="!isEditItem ? addItem(tableInputs.date) : editItem()"
                                    >
                                        <v-icon>
                                            mdi-check-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                                <tooltip text="Cancel" top>
                                    <v-btn
                                        v-if="isEditItem"
                                        icon
                                        small
                                        color="grey darken-2"
                                        @click="$refs.table.resetEdit()"
                                    >
                                        <v-icon>
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            </div>
                        </template>

                        <!-- actions -->
                        <template v-slot:item.actions="{ index }">
                            <div class="d-flex justify-center">
                                <tooltip  text="Edit Report" top>
                                    <v-btn
                                        v-if="!Boolean($route.query.preview)"
                                        icon
                                        small
                                        color="edit-color"
                                        @click="$refs.table.activeEditForRow(index); setRowIndex(index)"
                                    >
                                        <v-icon>
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            
                                <tooltip text="Delete doctor" top>
                                    <v-btn
                                        v-if="!Boolean($route.query.preview)"
                                        icon
                                        small
                                        @click="deleteItem(index)"
                                        color="alert-color"
                                    >
                                        <v-icon>
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </tooltip>
                            </div>
                        </template>

                        <!-- footer -->
                        <template v-slot:pagination-on-scroll.page-text="{ shownItemsLength, totalItems }">
                            <div class="d-flex align-center px-4">
                                <div v-if="remainActivitiesDaysCount >= 0">
                                    <span
                                    	:class="[
                                            { 'success--text': remainActivitiesDaysCount > 0 },
                                            'text--darken-1 me-1 text-body-1'
                                        ]"
                                    >
                                        {{remainActivitiesDaysCount.toString().padStart(2, 0)}}
                                    </span> 
                                    remaining days
                                </div>

                                <div v-else-if="startDate && endDate" class="error--text text--darken-1">
                                    Out of dates range
                                </div>

                                <v-spacer/>
                                
                                <div>shown rows {{`${shownItemsLength} / ${totalItems}`}}</div>
                            </div>
                        </template>
                    </bee-handy-smart-table>
                </v-col>
            </v-row>

            <!-- button -->
            <div v-if="0" class="d-flex justify-end">
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.MonthlyPlanReports === roleName.action"
                    :loading="loading"
                    :disabled="loading"
                    color="success white--text"
                    elevation="0"
                    class="px-sm-4 mx-2 text-body-2"
                    small
                    @click="submit()"
                >
                    {{ $route.query.preview ? 'Edit' : $route.params.id ?  'Save' : 'Create report'  }}
                </v-btn>
                <v-btn
                    elevation="0"
                    small
                    class="text-body-2"
                    color="grey darken-2 white--text"
                    :to="{name: 'administrative-reports'}"
                    :disabled="loading"
                >
                    cancel
                </v-btn>
            </div>
        </v-form>
        <v-row>
            <v-col cols="12" md="6">
                <div class="d-flex justify-space-between mb-1">
                    <h3 class="text-body-1 primary--text mb-1"> Visits </h3>
                    <v-btn 
                        :to="{
                            name: 'administrative-visit'
                        }" 
                        color="success" 
                        small
                    >
                        Add new
                    </v-btn>
                </div>
                <!-- <v-divider class="mt-1 mb-2" /> -->
                <!-- visit adminastive -->
                <bee-handy-table
                    :headers="headersVisits"
                    :items="[]"
                    hide-default-footer
                    dense
                    zebra
                    height="155"
                    fixed-header
                ></bee-handy-table>
            </v-col>

            <v-col cols="12" md="6">
                <div class="d-flex justify-space-between mb-1">
                    <h3 class="text-body-1 primary--text mb-1"> Double Visits </h3>
                    <v-btn 
                        :to="{
                            name: 'double-visit-report'
                        }" 
                        color="success" 
                        small
                    >
                        Add new
                    </v-btn>
                </div>

                <!-- visit adminastive -->
                <bee-handy-table
                    :headers="headersDouble"
                    :items="[]"
                    hide-default-footer
                    dense
                    zebra
                    height="155"
                    fixed-header
                ></bee-handy-table>
            </v-col>

            <v-col cols="12" class="pt-0">
                <bee-handy-smart-table
                    ref="table"
                    v-model="tableInputs"
                    :headers="headers"
                    :items="supervisorActivities"
                    dense
                    :loading="loading"
                    :items-per-page="-1"
                    :data-table-props="{
                        disableSort: true
                    }"
                    hide-no-data-area
                    pagination-on-scroll
                    stay-in-input-fields
                    fixed-header
                    height="300px"
                    :show-add-row="this.remainActivitiesDaysCount > 0 && !Boolean($route.query.preview)"
                    :editable-rows="!loading"
                    :item-class="(item) => !isBetweenStartAndEnd(item.date) ? 'red lighten-4' : null"
                    zebra
                    @edit-cancelled="resetTableInputs"
                    @keypress.enter="!isEditItem ? addItem(tableInputs.date) : editItem()"
                >
                    <template v-slot:no-input.actions>
                        <div class="d-flex justify-center">
                            <tooltip text="Save" top>
                                <v-btn
                                    v-if="Boolean($route.query.preview)"
                                    icon
                                    small
                                    color="success"
                                    @click="!isEditItem ? addItem(tableInputs.date) : editItem()"
                                >
                                    <v-icon>
                                        mdi-check-circle-outline
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                            <tooltip text="Cancel" top>
                                <v-btn
                                    v-if="isEditItem"
                                    icon
                                    small
                                    color="grey darken-2"
                                    @click="$refs.table.resetEdit()"
                                >
                                    <v-icon>
                                        mdi-close-circle-outline
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                        </div>
                    </template>

                    <!-- actions -->
                    <template v-slot:item.actions="{ index }">
                        <div class="d-flex justify-center">
                            <tooltip  text="Edit Report" top>
                                <v-btn
                                    v-if="!Boolean($route.query.preview)"
                                    icon
                                    small
                                    color="edit-color"
                                    @click="$refs.table.activeEditForRow(index); setRowIndex(index)"
                                >
                                    <v-icon>
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                        
                            <tooltip text="Delete doctor" top>
                                <v-btn
                                    v-if="!Boolean($route.query.preview)"
                                    icon
                                    small
                                    @click="deleteItem(index)"
                                    color="alert-color"
                                >
                                    <v-icon>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </tooltip>
                        </div>
                    </template>

                    <!-- footer -->
                    <template v-slot:pagination-on-scroll.page-text="{ shownItemsLength, totalItems }">
                        <div class="d-flex align-center px-4">
                            <div v-if="remainActivitiesDaysCount >= 0">
                                <span
                                    :class="[
                                        { 'success--text': remainActivitiesDaysCount > 0 },
                                        'text--darken-1 me-1 text-body-1'
                                    ]"
                                >
                                    {{remainActivitiesDaysCount.toString().padStart(2, 0)}}
                                </span> 
                                remaining days
                            </div>

                            <div v-else-if="startDate && endDate" class="error--text text--darken-1">
                                Out of dates range
                            </div>

                            <v-spacer/>
                            
                            <div>shown rows {{`${shownItemsLength} / ${totalItems}`}}</div>
                        </div>
                    </template>
                </bee-handy-smart-table>

                <div class="d-flex justify-space-between pt-1">
                    <!-- <h3 class="text-body-1 primary--text ">Activities</h3> -->
                    <div>
                        <v-btn
                            v-if="isSuperAdmin || permissionsUser.WeeklyReports === roleName.action"
                            :loading="loading"
                            :disabled="loading"
                            :color="$route.query.preview ? 'edit-color white--text' : 'success white--text' "
                            elevation="0"
                            class="px-sm-4 mx-2 text-body-2"
                            small
                            @click="$route.query.preview ? $router.push({
                                name: 'supervisors-report',
                                params: {
                                    id: $route.params.id
                                },
                                query: {}
                            }) : submit()"
                        >
                            {{ $route.query.preview ? 'Edit' : $route.params.id ?  'Save' : 'Create report'  }}
                        </v-btn>
                        <v-btn
                            elevation="0"
                            small
                            class="text-body-2"
                            color="grey darken-2 white--text"
                            :to="{name: 'administrative-reports'}"
                            :disabled="loading || Boolean($route.query.preview)"
                        >
                            cancel
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import rules from '@/validation rules'
import Tooltip from '@/components/Tooltip'
import { roleName } from '@/helpers/enums'
import moment from 'moment'
export default {
    name: 'SupervisorReport',

    data() {
        return {
            startDate: this.moment().format('YYYY-MM-DD'),
            endDate: this.moment().add(7, 'days').format('YYYY-MM-DD'),
            loading: false,
            roleName,
            
            rules,
            test: null,
            pastOffOfficeActivities: null,
            pastOfficeActivities: null,
            plannedOfficeActivities: null,
            supervisorActivities: [],
            plannedOffOfficeActivities: null,
            tableInputs: {},
            visitedHospitals:  null,

            // start date
            activePickerStartDate: null,
            menuOfStartDate:false,

            // end date
            activePickerEndDate: null,
            menuOfEndDate:false,

            // helpers
            rowId: 0,

            // data
            unavailableMaterials:  null,
            notes: null,
            rowEditIndex: null,
            isEditItem: false
        }
    },
    
    components: {
        Tooltip
    },

    watch: {
        'startDate'(val) {
            if (val !== null) {
                this.tableInputs.date = this.startDate
            }
        }
    },

    computed: {
        remainActivitiesDaysCount() {
            return moment(this.endDate).diff(this.latestSupervisorActivityDate || this.startDate, 'days')
        },

        headers() {
            const headers = [
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date',
                    dateFormat: 'DD-MM-YYYY',
                    type: 'date',
                    inputRules: [
                        val => this.isBetweenStartAndEnd(val) ||
                        'must be between the start and end dates'
                    ],
                    width: '30%'
                }, 
                {
                    text: 'Supervisor activities',
                    name: 'activity',
                    value: 'activity',
                    width: '50%',
                    inputRules: rules.required
                }
            ];

            if (!this.$route.query.preview) {
                headers.push({
                    text: 'actions',
                    name: 'actions',
                    align: 'center',
                    sortable: false,
                    noInput: true,
                });
            }

            return headers
        },
        ...mapState({
            reports: state => state.supervisorReports.reports, 
            isSuperAdmin: state => state.auth.isSuperAdmin,
            permissionsUser: state => state.auth.youPermissions,
        }),
        ...mapGetters({
            getReportById: 'supervisorReports/getReportById',
            isAdmin: 'auth/isAdmin',
        }),

        latestSupervisorActivityDate () {
            return this.supervisorActivities[this.supervisorActivities.length - 1]?.date ?? null;
        },

        headersVisits() {
            const headersVisits = [
                {
                    text: 'Doctor',
                    name: 'doctor',
                    value: 'doctor'
                },
                {
                    text: 'specialty',
                    name: 'specialty',
                    value: 'specialtyId'
                },
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date'
                },
                {
                    text: 'Note',
                    name: 'note',
                    value: 'note'
                }
            ]
            return headersVisits
        },

        headersDouble() {
            const headersDouble = [
                {
                    text: 'Medical rep name',
                    name: 'medicalRep',
                    value: 'name'
                },
                {
                    text: 'Doctor',
                    name: 'doctor',
                    value: 'doctor'
                },
                {
                    text: 'specialty',
                    name: 'specialty',
                    value: 'specialtyId'
                },
                {
                    text: 'Date',
                    name: 'date',
                    value: 'date'
                },
                {
                    text: 'Note',
                    name: 'note',
                    value: 'note'
                }
            ]
            return headersDouble
        }
    },

    methods: {
        addItem() {
            if (this.$refs.table.validate()) {
                this.supervisorActivities.push({
                    id: this.rowId++,
                    ...this.tableInputs
                });
                if (this.remainActivitiesDaysCount > 0) this.resetTableInputs();
            }
        },

        editItem() {
            if (this.$refs.table.validate()) {
                this.supervisorActivities[this.rowEditIndex].date = this.tableInputs.date;
                this.supervisorActivities[this.rowEditIndex].activity = this.tableInputs.activity;
                this.rowEditIndex = false;
                this.isEditItem = false;

                this.$refs.table.resetEdit()
                this.resetTableInputs();
            }
        },
        
        deleteItem(index) {
			this.$refs.table.resetEdit();
			this.isEditItem = false;
			this.supervisorActivities.splice(index, 1);
            this.rowId--;

            for (let i = 0; i < this.supervisorActivities.length; i++) {
                if (this.supervisorActivities[i].id > index) {
                    this.supervisorActivities[i].id--;
                }
            }

			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus();
            })
		},

        resetTableInputs() {
            this.$nextTick(() => {
                let date;
                if (moment(this.latestSupervisorActivityDate).isBefore((this.endDate), 'day')) {
                    date = moment(this.latestSupervisorActivityDate).add(1, 'days').format('YYYY-MM-DD')
                }
                this.$refs.table.resetInputs({ date });
                this.$refs.table.resetInputsFocus();
            })
        },

        fillData(data){
            this.startDate = moment(data.fromDate).format('YYYY-MM-DD');
            this.endDate = moment(data.toDate).format('YYYY-MM-DD');
            this.pastOfficeActivities = data.pastOfficeActivities;
            this.plannedOfficeActivities = data.plannedOfficeActivities;
            this.pastOffOfficeActivities = data.pastOffOfficeActivities;
            this.plannedOffOfficeActivities = data.plannedOffOfficeActivities;
            
            data.activities.forEach((item) => {
                this.supervisorActivities.push({
                    id: this.rowId++,
                    date: moment(item.date).format('YYYY-MM-DD'),
                    activity: item.activity
                });
            });

            this.visitedHospitals = data.visitedHospitals;
            this.unavailableMaterials = data.unavailableMaterials;
            this.notes = data.notes;
        },

        isBetweenStartAndEnd(date) {
            return moment(date).isBetween(this.startDate, this.endDate, 'days', '[]')
        },

        submit(){
            const activitiesDates = this.supervisorActivities.map(c => c.date);
            for (let i = 0; i < activitiesDates.length; i++) {
                if (!this.isBetweenStartAndEnd(activitiesDates[i])) {
                    this.$eventBus.$emit('show-snackbar', 'Some entires are out of dates range', 'error');
                    return;
                }
            }
            
            this.loading = true;
            const action = this.$route.params.id ? 'update' : 'create';
            this.$store.dispatch(`supervisorReports/${action}`, {
                id: this.$route.params.id,
                fromDate: this.startDate,
                toDate: this.endDate,
                pastOfficeActivities: this.pastOfficeActivities,
                plannedOfficeActivities: this.plannedOfficeActivities,
                pastOffOfficeActivities: this.pastOffOfficeActivities,
                plannedOffOfficeActivities: this.plannedOffOfficeActivities,
                activities: this.supervisorActivities.map(c => ({ date: c.date, activity: c.activity })),
                visitedHospitals: this.visitedHospitals,
                unavailableMaterials: this.unavailableMaterials,
                notes: this.notes
            })
            .then(() => {
                this.$eventBus.$emit('show-snackbar', this.$route.params.id ? 'Report is edited' : 'Report added', 'info');
                this.$router.push({ name: 'supervisors-reports' });
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // function for smart table
        setRowIndex(index) {
			this.rowEditIndex = index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},

        // helpers
        moment
    },

    created() {
        if (this.$route.params.id) {
            const fillHandler = (responseData) => {
                const data = responseData || this.getReportById(this.$route.params.id);
                this.fillData(data);

                const activities = data.activities;
                this.tableInputs.date =
                    this.startDate
                        ? moment(activities[activities.length - 1].date).format('YYYY-MM-DD')
                        : '01-01-1995';
            }

            if (this.reports.length === 0) {
                this.loading = true;
                // FIXME should use fetchById but Its not work in API
                this.$store.dispatch('supervisorReports/fetchAll', {
                    id: this.$route.params.id
                }).then((data) => {
                    fillHandler();
                }).finally(() => {
                    this.loading = false
                })
            } else {
                fillHandler();
            }
        } else {
            if (this.startDate !== null) this.tableInputs.date = this.startDate
        }
    },

    metaInfo: {
        title: 'Supervisors report',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
