<template>
    <div>
        <v-row> 
            <v-col class="">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.administrative-plan')}}
                </h1>
            </v-col>

            <v-col 
                align-self="center" 
                class="d-flex justify-end mt-1 py-1"
            >
                <v-btn
                    small
                    color="green lighten-1 white--text"
                    @click="ImportExcel"
                    class="mx-1"
                    :loading="loadingExcel"
                    :disabled="loadingExcel || !employee || !administrativePlans.length"
                >
                    <v-icon> mdi-file-table-outline </v-icon>
                    {{ $t('export-excel') }}
                </v-btn>
                <v-divider v-if="0" vertical class="mx-3" />
                <v-divider vertical />
                <v-btn 
                    :to="{
                        name: 'staff',
                        query: {
                            role: $route.query.role
                        }
                    }" 
                    color="grey darken-2 white--text" 
                    small
                >
                    <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                    Back
                </v-btn>
                
            </v-col>
        </v-row>
        <!-- dialog -->
        <v-dialog v-model="dialogAddVisit" width="450">
            <v-card>
                <div class="d-flex justify-end background-title-card">
                    <v-card-title
                        class="d-flex justify-center w-100 text-uppercase text-body-2 justify-center py-0 white--text"
                    >
                        {{ visitId ? $t('save') : $t('add') }} {{ $t('AdministrativePlan.activity') }} 
                    </v-card-title>
                    <!-- close icon button -->
                    <v-btn icon @click="dialogAddVisit = false">
                        <v-icon color="white" >
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>

                <v-card-text>
                    <h4 v-if="!visitId"> {{ $t('AdministrativePlan.select-activity') }} </h4>
                    <v-radio-group
                        v-if="!visitId"
                        v-model="visitType"
                        row
                        hide-details
                        dense
                        class="d-flex justify-start mt-0 mb-2 mx-0"
                    >
                        <v-radio :label="$t('AdministrativePlan.entities')" :value="0" class="" color="success"/>
                        <v-radio :label="$t('AdministrativePlan.double-visit')" :value="1" class="" color="success"/>
                        <v-radio :label="$t('AdministrativePlan.activity')" :value="2" class="" color="success"/>
                        
                    </v-radio-group>
                    <v-row>
                        <v-col cols="12" :sm="visitType === 2 ? '12' : '6'" class="py-1">
                            <bee-date-picker
                                v-model="dialogData.date"
                                :input-label="$t('inputs.date')"
                                elevation="2"
                                menu-picker
                                dense
                                hide-details
                                :input-props="{
                                    outlined: true,
                                    dense: true
                                }"
                                months-over-max="1"
                                days-over-max="30"
                                min="1940-01-01"
                                :disabled="loading"
                            ></bee-date-picker>
                        </v-col>
                        
                        <v-col v-if="visitType === 1 || typePlanned === 'doubleVisit'" cols="12" sm="6" class="py-1">
                            <v-autocomplete
                            	v-model="dialogData.medicalRepId"
                                :items="medicalReps.filter(c => c.employeeType === userType.medicalRep)"
                                item-text="name"
                                item-value="id"
                            	outlined
                            	dense
                            	:label="$t('AdministrativePlan.medical-rep-name')"
                            	hide-details
                            />
                        </v-col>
                        
                        <!-- <v-col v-if="visitType === 0 || typePlanned === 'visit'" cols="12" sm="6" class="py-1">
                            <v-autocomplete
                            	v-model="dialogData.type"
                                :items="customerTypes"
                                item-text="name"
                                item-value="id"
                            	outlined
                            	dense
                            	:label="$t('inputs.type')"
                            	hide-details
                            />
                        </v-col>
                         -->
                        <v-col v-if="visitType === 0 || typePlanned === 'visit'" cols="12" sm="6" class="py-1">
                            <v-autocomplete
                            	v-model="dialogData.EntityId"
                                :items="entitiesBrief"
                                item-text="name"
                                item-value="id"
                            	outlined
                            	dense
                            	:label="$t('AdministrativePlan.entities-name')"
                            	hide-details
                            />
                        </v-col>

                        <v-col v-if="visitType === 2 || typePlanned === 'work'" cols="12" sm="12" class="">
                            <v-textarea
                            	v-model="dialogData.activity"
                            	outlined
                            	dense
                            	label="Activities"
                            	hide-details
                                :rows="2"
                            />
                        </v-col>

                        <v-col v-if="visitType === 0 || typePlanned === 'visit'" cols="12" align-self="center">
                            <bee-handy-smart-table
                                ref="table"
                                v-model="materialInput"
                                :headers="materialsHeaders"
                                :items="tableMaterials"
                                show-add-row
                                @keypress.enter="!isEditItem ? addItem() : editItem()"
                                pagination-on-scroll
                                height="300"
                                dense
                                zebra
                                editable-rows
                                :reset-edit.sync="resetEdit"
                                :shown-rows-text="$t('shown-rows')"
                            > 

                                <template v-slot:item.actions="{ index }">
                                    <v-btn 
                                        icon 	
                                        color="edit-color"				
                                        @click="$refs.table.resetInputs(); $refs.table.activeEditForRow(index); setRowIndex({ index })"
                                    >
                                        <v-icon> mdi-pencil </v-icon>
                                    </v-btn>

                                    <v-btn 
                                        icon 	
                                        color="alert-color"				
                                        @click="deleteItem({ index })"
                                    >
                                        <v-icon> mdi-delete </v-icon>
                                    </v-btn>
                                </template>

                                <template v-slot:input.materialId="{ on, attr }">
                                    <v-autocomplete
                                        :items="materials"
                                        item-text="name"
                                        item-value="id"
                                        v-on="on"
                                        v-bind="attr"
                                        />
                                </template>
                            </bee-handy-smart-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="pt-0">
                    <v-spacer />
                    <v-btn
                        @click="submitVisit()"
                    	text
                    	color="success"
                    	:loading="submitLoading"
                        :disabled="!isNotEmpty"
                    >
                        {{ $t('save') }}
                    </v-btn>

                    <v-btn
                    	text
                    	color="alert-color"
                    	@click="dialogAddVisit = false"
                    	:disabled="submitLoading"
                    >
                        {{ $t('cancel') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete dialog -->
        <v-dialog v-model="deleteEventDialog" width="400px">
            <v-card>
                <div class="d-flex justify-end red-light-one-bg mb-3">
                    <v-card-title
                        class="d-flex justify-center w-100 text-uppercase text-body-2 justify-center py-0 white--text"
                    >
                        {{ $t('delete') }} 
                    </v-card-title>
                    <!-- close icon button -->
                    <v-btn icon @click="deleteEventDialog = false">
                        <v-icon color="white" >
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div>

                <v-card-text class="pb-0">
                    {{$t('are-you-sure-you-want-to-delete-this') + $t('AdministrativePlan.visit') + $t('?')}}
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn
                    	text
                    	@click="deleteEvent(typePlanned)"
                    	color="alert-color"
                    	:loading="submitLoading"
                    >
                       {{ $t('yes') }} 
                    </v-btn>

                    <v-btn
                    	text
                    	@click="deleteEventDialog = false"
                    	:disabled="submitLoading"
                    >
                        {{ $t('no') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- filter -->
        <v-form>
            <v-row class="my-1">
                <v-col cols="12" sm="4" md="3" class="py-1">
                    <v-autocomplete
                    	v-model="employee"
                    	:items="medicalReps.filter(c => c.employeeType  !== userType.medicalRep)"
                        item-text="name"
                        item-value="id"
                    	outlined
                    	dense
                    	:label="$t('AdministrativePlan.administrative')"
                        @change="$router.replace({ params: { id: employee } }); getVisitForThisMonth(employee)"
                        :loading="loading"
                        :disabled="loading"
                        hide-details
                    />
                </v-col>

                <v-col cols="12" sm="6" md="3" class="py-1">
                    <bee-date-input
                        v-model="datePlan"
                        :append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
                        @click:append="$i18n.locale === 'en' ? upDay() : downDay(); getPlanAndVisitForMep(employee, datePlan)"
                        :prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
                        @click:prepend="$i18n.locale === 'en' ? downDay() : upDay(); getPlanAndVisitForMep(employee, datePlan)"
                        :label="datePlan ? `${moment(datePlan).format('MMMM')}` : 'Date'"
                        dense
                        :loading="loading"
                        :disabled="employee == null || loading"
                        input-format-order="year-month-day"
                        input-type="month"
                        readonly
                        hide-details
				    ></bee-date-input>
                </v-col>
                
                <!-- <v-col align-self="center" class="d-flex justify-end">
                    <v-divider vertical />
                    <v-btn 
                        :to="{
                            name: 'staff'
                        }" 
                        color="grey darken-2 white--text" 
                        small
                    >
                        <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                        {{ $t('back') }}
                    </v-btn>
                </v-col> -->
            </v-row>
        </v-form>
        <v-card
            :max-height="$vuetify.breakpoint.height - 110"
            elevation="1"
            class="overflow-x-hidden"
        	:class="{'pt-3': $vuetify.breakpoint.smAndDown}"
        >
            <v-progress-linear v-if="loading" indeterminate height="2" class="progress-loader"/>
            <v-card-text class="pa-0">
                <v-calendar
                    ref="calendar"
                    v-model="value" 
                    :events="events"
                    color="primary"
                    type="month"
                    :event-more="false"
                    :weekdays="[0, 1, 2, 3, 4, 5, 6]"
                    :height-event="10"
                    @click:event="showEvent"
                >

                    <template v-slot:event="{ event }">
                        <div class="v-event-draggable">
                            <p class="text-center"> 
                                {{ event.name }}
                                <!-- {{event.name}} -->
                            </p>
                        </div>
                    </template>

                    <template v-slot:day="{ date }">
                        <div style="width: 100%" class="d-flex justify-center align-items-center">
                            <v-btn
                                icon
                                color="success"
                                class="d-flex justify-center"
                                @click="dialogData.date = date; visited = null; dialogAddVisit = true"
                                :disabled="loading 
                                || readonly 
                                || employee === null 
                                || (administrativePlans.filter(c => moment(c.fromDate).isSame(value, 'month')).length !== 0 ? false : true)
                                || !moment(date).isSame(datePlan, 'month')
                                "
                            >
                                <v-icon> mdi-plus-circle </v-icon>
                            </v-btn>
                        </div>
                    </template>
                </v-calendar>
            </v-card-text>
        </v-card>

        <v-menu
            v-model="menu"
            :activator="selectedElement"
            offset-x
        >
            <v-list>
                <v-list-item
                    dense
                    @click="dialogAddVisit = true"
                >
                    <v-row>
                        <v-col cols="3" class="py-0" align-self="center">
                            <v-list-item-icon>
                                <v-icon color="edit-color">
                                    mdi-pencil
                                </v-icon>
                            </v-list-item-icon>
                        </v-col>

                        <v-col cols="9" class="py-0" align-self="center">
                            <v-list-item-title>Edit visit</v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>

                <v-list-item dense @click="deleteEventDialog = true">
                    <v-row>
                        <v-col cols="3" class="py-0" align-self="center">
                            <v-list-item-icon>
                                <v-icon color="alert-color">
                                    mdi-delete
                                </v-icon>
                            </v-list-item-icon>
                        </v-col>

                        <v-col cols="9" class="py-0" align-self="center">
                            <v-list-item-title>Delete visit</v-list-item-title>
                        </v-col>
                    </v-row>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { userType, entityType } from '@/helpers/enums'
import moment from 'moment'

export default {
    watch: {
        dialogAddVisit (val) {
            if (val === false) {
                this.dialogData.date = null
                this.dialogData.medicalRepId = null
                this.dialogData.EntityId = null
                this.dialogData.activity = null
                this.visitId = null
                this.visitType = 0
            } else {
                if (this.visitId !== null) {
                    this.dialogData.medicalRepId = this.getWorKById(this.visitId)?.medicalRepId
                    this.dialogData.EntityId = this.getVisitById(this.visitId)?.entity?.entityId
                    this.dialogData.activity = this.getWorKById(this.visitId)?.workDescription
                    if (this.typePlanned === 'work') {
                        this.visitType = 2
                    } else if (this.typePlanned === 'doubleVisit') {
                        this.visitType = 1
                    } else {
                        this.visitType = 0
                    }
                } else {
                    this.dialogData.EntityId = null
                }
            }
        }
    },
    data() {
        return {
            loadingExcel: false,
            materialInput: {},
            tableMaterials: [],
            isEditItem: false,
            disable: false,
            typePlanned: null,
            menu: false,
            selectedElement: null,

            deleteEventDialog: false,
            employee: null,
            dialogAddVisit: false,
            loading: false,
            value: '',
            type: 'month',
            datePlan: null,
            events: [],
            visitType: 0,
            submitLoading: false,
            userType,
            dialogData: {
                date: null,
                medicalRepId: null,
                EntityId: null,
                activity: null
            },

            readonly: false,
            visitId: null,
            administrativePlans: [],
            resetEdit: false,
            customerTypes: [
                { id: entityType.center, name: this.$t('customerTypes.centers') },
                { id: entityType.doctor, name: this.$t('customerTypes.doctors') },
                { id: entityType.pharmacy, name: this.$t('customerTypes.pharmacies') }
            ],
        }
    },
     
    computed: {
        ...mapState({
            medicalReps: state => state.medicalreps.medicalreps,
            entitiesBrief: state => state.entities.entitiesBrief,
            employeeId: state => state.auth.userData.employeeId,
            plan: state => state.visitsPlan.plan,
            myPlan: state => state.visitsPlan.myPlan,
            materials: state => state.materials.materials
        }),
        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getVisitById: 'visitsPlan/getVisitById',
            getWorKById: 'plannedWork/getWorKById',
            getMaterialById: 'materials/getMaterialById'
        }),
        isNotEmpty() {
            return this.dialogData.medicalRepId !== null || this.dialogData.EntityId !== null || this.dialogData.activity !== null
        },

        materialsHeaders() {
            return [
                {
                    text: this.$t('headers.material'),
                    name: 'materialId',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name 
                },

                {
                    text: this.$t('headers.amount'),
                    name: 'amount',
                    value: 'amount',
                    type: 'number'
                },

                {
                    text: this.$t('headers.actions'),
                    name: 'actions',
                    value: 'actions',
                    noInput: true
                }
            ]
        }
    },

    methods: {
        moment,
        // add or edit visit or double visit or activity for administrative plan
        submitVisit () {
            this.submitLoading = true
            const moduleName = this.visitType === 0 ? 'visitsPlan' : 'plannedWork';
            const actionName = this.visitId === null ? 'create' : 'update'
            this.$store.dispatch(`${moduleName}/${actionName}`, { 
                id:  this.visitId,
                entityId: this.dialogData.EntityId, 
                date: `${this.dialogData.date}T00:00:00`,

                visitPlanId: (this.employeeId !== this.employee) ? this.plan.map(c => ({ ...c, fromDate: moment(c.fromDate).format('YYYY-MM') })).find(c => c.fromDate === this.datePlan)?.id : this.myPlan.map(c => ({ ...c, fromDate: moment(c.fromDate).format('YYYY-MM') })).find(c => c.fromDate === this.datePlan)?.id,

                medicalRepId: this.dialogData.medicalRepId,
                workDescription: this.dialogData.activity, 
                materials: this.visitType === 0 ? this.tableMaterials.map(c => ({ materialId: c.materialId, amount: c.amount })) : null
            }).then(() => {
                this.events = []
                this.getPlanAndVisitForMep(this.$route.params.id ? this.$route.params.id : this.employee, this.datePlan)
            }).finally(() => {
                this.submitLoading = false
                this.dialogAddVisit = false
            })
        },

        // create new plan
        createPlan(fromDate, toDate) {
            this.submitLoading = true;
            this.$store.dispatch('visitsPlan/createPlan', { 
                employeeId: this.employeeId, 
                fromDate: fromDate, 
                toDate: toDate
            }).then((data) => {
                this.getAllPlan(this.$route.params.id).then(() => {
                    this.datePlan = this.moment(data.map(c => c.fromDate)[data.length - 1]).format('YYYY-MM-DD')

                    this.value = this.moment(data.map(c => c.fromDate)[data.length - 1]).format('YYYY-MM-DD')

                    this.administrativePlans = []
                    this.administrativePlans = data
                })
            }).finally(() => {
                this.submitLoading = false
                this.loading = false
            })
        },

        getAllPlan(employeeId) {
            const actionName = employeeId === this.employeeId ? 'getPlanMine' : 'allPlan'
            return this.$store.dispatch(`visitsPlan/${actionName}`, { employeeId: employeeId })
        },

        getVisitForThisMonth(id) {
            this.loading = true
            this.employee = id
            
            this.getAllPlan(id).then((data) => {
                this.value = this.moment(data.map(c => c.fromDate)[data.length - 1]).format('YYYY-MM-DD')

                this.administrativePlans = []
                this.administrativePlans = data
                this.datePlan = this.moment(data.map(c => c.fromDate)[data.length - 1]).format('YYYY-MM-DD')
                if (
                    (this.employeeId === id) && 
                    ( 
                        (data.length === 0) || 
                        (data.length !== 0 && this.moment(data[data.length - 1].fromDate).isBefore(this.moment().add(1, 'month'), 'month'))
                    )
                ) {
                    this.createPlan(
                        this.moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'), 
                        this.moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')
                    )
                }
                else {
                    this.getPlanAndVisitForMep(id, this.datePlan)
                }
                this.administrativePlans = data
                this.value = this.datePlan
            }).catch(() => {
                this.events = []
                this.$eventBus.$emit('show-snackbar', 'Sorry, you cannot view the plan because there is no plan created by the medical rep', 'info');
                this.disable = true
            })
        },

        getPlanAndVisitForMep (employeeId, fromDate) {
            this.loading = true
            if (this.administrativePlans.find(c => moment(c.fromDate).isSame(this.datePlan, 'month'))) {
                Promise.all([
                    this.$store.dispatch('visitsPlan/fetchPlannedVisitsByEmployeeId', { 
                        employeeId: employeeId,
                        fromDate: fromDate
                    }),
                    this.$store.dispatch('visits/fetchVisitsWithoutPagination', { 
                        fromDate: this.moment(this.datePlan).startOf('month').format('YYYY-MM-DD'), toDate: this.moment(this.datePlan).endOf('month').format('YYYY-MM-DD'), employeeId: this.$route.params.id ? this.$route.params.id : this.employee
                    }),
                    this.$store.dispatch('plannedWork/fetchAllByEmployee', { 
                        employeeId: employeeId, 
                        fromDate: fromDate
                    })
                ]).then((data) => {
                    // visits plan
                    this.events = []
                    const visitsPlan = data[0];
                    const eventCount = data[0]?.length
                    const dateArray = data[0] ? data[0]?.map(c => new Date(c.date).getTime()) : []
                    for (let i = 0; i < data[0].length; i++) {
                        var start = dateArray[i];
                        const end = dateArray[i];
                        const timed = false;
                        this.events.push({
                            name: visitsPlan[i].entity.name,
                            entityId: visitsPlan[i].entity.entityId,
                            id: visitsPlan[i].id,
                            color: visitsPlan[i].isVisited ? 'success' : this.moment(visitsPlan[i].date).diff(this.moment(), 'days') >= 0 ? '#0b5b6f63' : 'red',
                            start,
                            end,
                            timed,
                            type: 'visit',
                            dateDay: visitsPlan[i].date,
                            createdAt: visitsPlan[i].createdAt
                        })
                    }
                    // visits

                    const visits = data[1]?.map(c => ({
                        ...c,
                        entityId: c.entityId,
                        date: new Date(c.createdAt).getTime(),
                        dateDay: c.createdAt,
                        createdAt: c.createdAt
                    }))

                    for (let i = 0; i < visits.length; i++) {
                        const match = this.events.filter(c => c.entityId === visits[i].entityId)
                        if (!match.find(c => this.moment(c.dateDay).isSame(visits[i].dateDay, 'day'))) {
                            this.events.push({
                                name: visits[i].entity.name,
                                entityId: visits[i].entityId,
                                start: visits[i].date,
                                end: visits[i].date,
                                timed: false,
                                color: 'orange',
                                type: 'visit',
                                createdAt: visits[i].createdAt,
                                isOutOfPlan: true
                            })
                        }
                    }
                    // work planed
                    const workPlan = data[2];
                    const dateArrayWork = data[2] ? data[2]?.map(c => new Date(c.date).getTime()) : []
                    const activities = data[2]?.map(c => ({
                        ...c,
                        date: new Date(c.createdAt).getTime(),
                        workDescription: c.workDescription,
                        medicalRepId: c.medicalRepId,
                        createdAt: c.createdAt,
                        
                    }))
                    for (let i = 0; i < activities.length; i++) {
                        var startTime = dateArrayWork[i];
                        const end = dateArrayWork[i];
                        const timed = false;
                        this.events.push({
                            name: activities[i].workDescription !== null ? 'Ac' + ' ' + activities[i].workDescription : 'D.V' + '  ' + this.getMedicalRepById(activities[i].medicalRepId)?.name,
                            workDescription: activities[i].workDescription,
                            start: startTime,
                            end: activities[i].date,
                            timed: false,   
                            id: activities[i].id,
                            type: activities[i].workDescription !== null ? 'work' : 'doubleVisit',
                            color: '#0b5b6f63',
                            createdAt: activities[i].createdAt  
                        })
                    }
                }).finally(() => {
                    this.loading = false
                })
            } else {
                this.loading = false
                this.events = []
            }
        },

        upDay(){
            this.datePlan = this.moment(this.datePlan).add(1, 'month').format('YYYY-MM-DD')
            this.$refs.calendar.next()
			return this.datePlan
        },
         
        downDay() {
            this.datePlan = this.moment(this.datePlan).subtract(1, 'month').format('YYYY-MM-DD')
            this.$refs.calendar.prev()
			return this.datePlan
        },

        showEvent ({ nativeEvent, event }) {
            this.visitId = event.id
            this.typePlanned = event.type
            this.dialogAddVisit === true ? this.dialogData.EntityId = event.entityId : this.dialogData.EntityId = null
            this.dialogData.date = this.moment(event.start).format('YYYY-MM-DD')
            if (this.employee === this.employeeId && moment(this.value).isSame(moment().add(1, 'month'), 'month') && !event.isOutOfPlan) {
                this.selectedElement = nativeEvent.target
                this.$nextTick(() => { this.menu = true; })
            } else if (this.employee !== this.employeeId && !event.isOutOfPlan) {
                this.selectedElement = nativeEvent.target
                this.$nextTick(() => { this.menu = true; })
            }
        },

        deleteEvent (type) {
            this.submitLoading = true
            const moduleName = type !== 'visit' ? 'plannedWork' : 'visitsPlan'
            this.$store.dispatch(`${moduleName}/delete`, { id: this.visitId }).then(() => {
                this.events = []
                this.getPlanAndVisitForMep(this.$route.params.id ? this.$route.params.id : this.employee, this.datePlan)
            }).finally(() => {
                this.submitLoading = false
                this.deleteEventDialog = false
            })
        },

        // sample for planned visit
        addItem () {
            this.tableMaterials.push({
                ...this.materialInput,
                id: Math.random()
            })
            this.$nextTick(() => {
				this.$refs.table.resetInputs();
				this.$refs.table.resetInputsFocus()
			})
        },

        editItem () {
            this.tableMaterials[this.rowEditIndex].materialId = this.materialInput.materialId
			this.tableMaterials[this.rowEditIndex].amount = this.materialInput.amount
			this.resetEdit = true
			// this.resetTableInputs()
			this.$refs.table.resetEdit()
			this.isEditItem = false
			this.resetInputsFocus = true
        },

        deleteItem ({ index }) {
			this.$refs.table.resetEdit()
			this.isEditItem = false
			this.tableMaterials.splice(index, 1)
		},

        ImportExcel() {
            this.loadingExcel = true
            this.loading = true
            this.$store.dispatch('exporter/fetchPlanExport', { 
                fromDate: this.datePlan, 
                employeeId: this.employee,
            }).finally(() => {
                this.loadingExcel = false
                this.loading = false
            })
        },
    },

    created() {
        this.loading = true
        Promise.all([
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.entitiesBrief.length ? this.$store.dispatch('entities/fetchAllBrief', {}) : null,
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null
        ])
    
        if (this.$route.params.id !== null) {
            this.employee = this.$route.params.id
            this.getVisitForThisMonth(this.$route.params.id)
        } else {
            this.employee = this.employeeId
            this.getVisitForThisMonth(this.employeeId)
        }
    }
}
</script>
