<template>
    <div>
        <div class="d-flex">
            <div>
                <v-btn
                    v-if="isSuperAdmin || permissionsUser.GenerateMaterials !== roleName.nothing"
                    small
                    :to="{ name: 'supervisor-remaining-samples' }"
                    color="dark-yellow white--text"
                >
                    Remaining Samples
                </v-btn>
            </div>
            
            <!-- view chart -->
            <v-btn
                v-if="isSuperAdmin || permissionsUser.Charts === roleName.action"
                small
                :to="{ name: 'statistics-chart', params: { type: 'samples' } }"
                color="dark-yellow white--text"
                class="mx-2"
            >
                <v-icon small class="me-2">mdi-chart-bar</v-icon>
                View chart
            </v-btn>
        </div>
        <!-- tabs -->
        <!-- <v-tabs v-model="tab">
            <v-tab class="text-capitalize" :to="{name: 'journal-items'}">
                Items Journal
            </v-tab>

            <v-tab v-if="false" class="text-capitalize" :to="{name: 'samples'}">
                Created
            </v-tab>

            <v-tab class="text-capitalize" :to="{name: 'samples-statement'}">
                Statement
            </v-tab>
        </v-tabs> -->
        
        <v-divider/>
        
        <router-view /> 
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { roleName } from '@/helpers/enums'
export default {
    data() {
        return {
            tab: 0,
            tabs: ['Created', 'Statement'],
            roleName
        }
    },

    computed: {
        ...mapGetters({
            getUserType: 'auth/getUserType',
        }),

        ...mapState({
            isSuperAdmin: state => state.auth.isSuperAdmin,
            permissionsUser: state => state.auth.youPermissions
        })
    }
}
</script>
