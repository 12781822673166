export default {
    'modify-details': 'تعديل التفاصيل',
    'addresses': 'العناوين',
    'modification-reason': 'سبب التعديل',
    'confirm-acceptance': 'تأكيد القبول',
    'confirm-rejection': 'تأكيد الرفض',
    'accept': 'قبول',
    'reject': 'رفض',
    'this-request': 'هذا الطلب',
    'requested-by': 'بتوصية من',
    'request-type': 'نوع الطلب',
    'request-reason': 'سبب الطلب',
    'view-modify-details': 'عرض تفاصيل التعديل',
    'accept-request': 'قيول الطلب',
    'reject-request': 'رفض الطلب'
}