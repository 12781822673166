var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"450px"},model:{value:(_vm.vacationDialog),callback:function ($$v) {_vm.vacationDialog=$$v},expression:"vacationDialog"}},[_c('v-card',[_c('div',{staticClass:"d-flex justify-space-between py-1 primary"},[_c('v-card-title',{staticClass:"justify-center flex-grow-1 text-uppercase text-subtitle-1 white--text white--text pa-0"},[_vm._v(" "+_vm._s(_vm.$t('NavigationDrawer.add-day-off-request'))+" ")]),_c('v-btn',{attrs:{"icon":"","color":"white"},on:{"click":function($event){_vm.vacationDialog = false}}},[_c('v-icon',[_vm._v(" mdi-close-circle-outline ")])],1)],1),_c('v-card-text',{staticClass:"pt-6 pb-0"},[_c('v-row',[_c('v-col',{staticClass:"py-1 px-1",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.requestTypes,"item-text":"name","item-value":"id","label":_vm.$t('inputs.paid-leave'),"outlined":"","dense":"","loading":_vm.submitLoading,"disabled":_vm.submitLoading},model:{value:(_vm.leaveData.type),callback:function ($$v) {_vm.$set(_vm.leaveData, "type", _vm._n($$v))},expression:"leaveData.type"}})],1),_c('v-col',{staticClass:"py-1 px-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('inputs.days-count'),"outlined":"","dense":"","loading":_vm.submitLoading,"disabled":_vm.submitLoading},model:{value:(_vm.leaveData.daysCount),callback:function ($$v) {_vm.$set(_vm.leaveData, "daysCount", _vm._n($$v))},expression:"leaveData.daysCount"}})],1),_c('v-col',{staticClass:"py-1 px-1",attrs:{"cols":"6"}},[_c('bee-date-picker',{attrs:{"input-label":_vm.$t('inputs.start-date'),"elevation":"2","menu-picker":"","dense":"","hide-details":"","input-props":{
									outlined: true,
									dense: true,
									clearable: true,
								},"loading":_vm.submitLoading,"disabled":_vm.submitLoading,"years-over-max":"3"},model:{value:(_vm.leaveData.date),callback:function ($$v) {_vm.$set(_vm.leaveData, "date", $$v)},expression:"leaveData.date"}})],1),_c('v-col',{staticClass:"py-1 px-1",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('inputs.reason'),"outlined":"","dense":"","loading":_vm.submitLoading,"disabled":_vm.submitLoading},model:{value:(_vm.leaveData.reason),callback:function ($$v) {_vm.$set(_vm.leaveData, "reason", $$v)},expression:"leaveData.reason"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"success","loading":_vm.submitLoading,"disabled":_vm.submitLoading},on:{"click":function($event){return _vm.createVacation()}}},[_vm._v(" "+_vm._s(_vm.$t('send'))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.submitLoading,"text":""},on:{"click":function($event){_vm.vacationDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])],1)],1)],1),_c('v-app-bar',{staticClass:"px-xs-5 elevation-0 white--text mb-1",attrs:{"app":"","color":"primary ","height":"40"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},_vm._l((_vm.list),function(item,i){return _c('v-menu',{key:i,attrs:{"bottom":"","offset-y":"","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","dark":"","text":"","dense":""},on:{"click":function($event){item.title === _vm.$t('NavigationDrawer.home') ? _vm.$router.replace({ name: 'home' }) : null}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_vm._v(" "+_vm._s(item.title)+" ")],1)]}}],null,true)},[(item.title !== _vm.$t('NavigationDrawer.home'))?_c('v-list',_vm._l((item.itemList),function(ele,index){return _c('div',{key:index},[(ele.show)?_c('v-list-item',{attrs:{"to":{ name: ele.target }}},[(ele.icon)?_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(ele.icon)+" ")])],1):_c('v-list-item-avatar',{staticClass:"py-0 pe-4 my-0"},[_c('v-img',{staticClass:"d-flex justify-end",attrs:{"src":ele.img,"min-width":"40","width":"40","max-height":"20","contain":""}})],1),_c('v-list-item-title',[_vm._v(_vm._s(ele.name))])],1):_vm._e()],1)}),0):_vm._e()],1)}),1),_c('v-col',{staticClass:"d-flex justify-end"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
								var on = ref.on;
								var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"white","dark":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-1"},[_vm._v(" mdi-account-circle ")]),_vm._v(" "+_vm._s(_vm.userName)+" ")],1)]}}])},[_c('v-list',{staticClass:"px-0 py-0"},[_c('v-list-item',{staticClass:"px-0"},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"400"}},[_c('v-card-title',{staticClass:"py-1"},[_c('v-img',{staticClass:"me-4",attrs:{"src":require("@/assets/male.jpg"),"contain":"","max-width":"50","width":"50","min-width":"50"}}),_c('span',{staticClass:"text-body"},[_vm._v(" "+_vm._s(_vm.userName)+" ")])],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-text-field',{attrs:{"label":"Vacations balance","dense":"","outlined":"","hide-details":"auto","disabled":""},model:{value:(_vm.vacations),callback:function ($$v) {_vm.vacations=$$v},expression:"vacations"}})],1)],1),_c('v-divider',{staticClass:"mx-2"}),_c('v-list-item',{on:{"click":function($event){_vm.vacationDialog = true}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-calendar ")]),_vm._v(" Day off request ")],1)],1),_c('v-divider',{staticClass:"mx-2"}),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-logout ")]),_vm._v(" Logout ")],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }