<template>
    <div>
        <v-row class="">
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.exams')}}
                </h1>
            </v-col>
            <v-spacer />
            <v-col class="d-flex justify-end mt-1">
                <v-btn
                    v-if="isSuperAdmin || permissionsUser === roleName.action"
                    small
                    color="dark-yellow white--text"
                    @click="addExam()"
                >                
                    <v-icon class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{ $t('Exam.add-new-exam') }}
                </v-btn>
            </v-col>
        </v-row>

        <!-- exam dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="500"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <v-card>
                        <div class="d-flex justify-space-between py-1 primary">
                            <v-card-title class="justify-center flex-grow-1 text-uppercase text-subtitle-1 white--text white--text pa-0">
                                {{ !id ? $t('Exam.add-new-exam') : $t('Exam.edit-exam') }}
                            </v-card-title>

                            <v-btn icon @click="dialog.value = false">
                                <v-icon color="white"> mdi-close-circle-outline </v-icon>
                            </v-btn>
                        </div>
                        <v-card-text class="pt-7">
                            <!-- dialog content -->
                            <v-row>
                                <!-- Exam Link -->
                                <v-col sm="7" cols="12" class="py-0 px-2">
                                    <v-text-field
                                        :label="$t('inputs.exam-link')"
                                        v-model="link"
                                        clearable
                                        :disabled="loading"
                                        :rules="rules.url"
                                        required
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- date -->
                                <v-col sm="5" cols="12" class="py-0 px-2">
                                    <bee-date-picker
                                        v-model="date"
                                        :input-label="$t('inputs.date')"
                                        menu-picker
                                        dense
                                        hide-details
                                        :max-date="moment().add(1, 'month').toISOString().slice(0, 10)"
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                    ></bee-date-picker>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                :loading="submitLoading"
                                :disabled="submitLoading"
                                color="success white--text"
                                elevation="0"
                                text
                            >
                                {{$t('save')}}
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                elevation="0"
                                text
                            >
                                {{$t('cancel')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- delete-dialog decleared here to use it as refs -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="needToResetItems = true; filter()"
            :success-message="$t('data-is-deleted')"
        >
            {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{ $t('delete') }}</span> the exam ?
        </delete-dialog>

        <!-- import-dialog decleared here to use it as refs -->
        <import-dialog ref="ImportDialog"></import-dialog>
        <!------------->

        <!-- filter -->
        <v-form @submit.prevent="needToResetItems = true; isFiltered = true; filter();">
            <v-row class="my-1" align="center">
                <!-- start date -->
                <v-col md="3" sm="6" cols="6" class="">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        menu-picker
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col md="3" sm="6" cols="6" class="">
                    <bee-date-picker
                        v-model="endDate"
                        :input-label="$t('inputs.end-date')"
                        menu-picker
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
            
                <!-- buttons -->
                <v-col class="d-flex justify-start justify-md-end pt-0 pt-sm-2">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        small
                        type="submit"
                    >
                        <v-icon> mdi-magnify </v-icon>
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        class="px-sm-8"
                        @click="clearFilter(); filter();"
                        :disabled="!isFiltered || loading"
                        color="alert-color white--text"
                        small
                    >
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="[]"
            :items-per-page="20"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :server-items-length="totalExams"
            :pagination-on-scroll-auto-reset-shown="false"
            :data-table-props="{
                disableSort: true
            }"
            dense
            zebra
            :options-scroll.sync="scrollOptions"
            :height="$vuetify.breakpoint.height - 155"
            :loading="loading"
            @pagination-on-scroll:reach-last-row="filter"
        >
            <!-- action -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('view')" top>
                        <v-btn icon small :to="{name: 'exam-results', params:{id: item.id}}" color="primary">
                            <v-icon>
                                mdi-eye-outline
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip v-if="isSuperAdmin || permissionsUser === roleName.action" :text="$t('import-excel-file')" top>
                        <v-btn icon small @click="$refs.ImportDialog.import('exams', item.id)" >
                            <v-icon color="green lighten-1">
                                mdi-file-table-outline
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip v-if="isSuperAdmin || permissionsUser === roleName.action" :text="$t('edit')" top>
                        <v-btn icon small @click="editExam(item)" color="edit-color">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip v-if="isSuperAdmin || permissionsUser === roleName.action" :text="$t('delete')" top>
                        <v-btn icon small @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'exams')" color="alert-color">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DeleteDialog from '@/components/DeleteDialog'
import ImportDialog from '@/components/ImportDialog'
import Tooltip from '@/components/Tooltip'
import rules from '@/validation rules'
import moment from 'moment'
import { roleName } from '@/helpers/enums'
export default {
    name: 'Exams',

    components:{
        DeleteDialog,

        ImportDialog,
        Tooltip
    },

    data(){
        return {
            roleName,
            // helpers
            loading: false,
            submitLoading: false,
            isFiltered: false,
            needToResetItems: false,
            moment,
        
            // options
            scrollOptions: null,
            totalExams: null,
            
            // filter data
            startDate: null,
            endDate: null,

            // date (exam dialog)
            activePickerDate: null,
            menuOfDate:false,

            // Data
            id: null,
            link: null,
            date: null,
            rules
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return !this.startDate && !this.endDate;
        },

        ...mapState({
            exams: state => state.exams.exams,
            permissionsUser: state => state.auth.youPermissions.Exams,
            isSuperAdmin: state => state.auth.isSuperAdmin
        }),
        
        ...mapGetters({
            getExamById: 'exams/getExamById',
        }),

        headers() {
            return [
                { 
                    text: this.$t('headers.date'), 
                    value: 'date', 
                    type: 'date',
                    align: 'start',
                    class:'text-body-2 font-weight-bold',
                },
                { 
                    text: this.$t('headers.exam-link'), 
                    value: 'link', 
                    align: 'start', 
                    class:'text-body-2 font-weight-bold',
                },
                { 
                    text: this.$t('headers.actions'), 
                    value: 'action', 
                    name: 'actions',
                    align: 'center', 
                    class:'text-body-2 font-weight-bold',
                    cellClass:'text-start', 
                    width: '300'
                },
            ]
        } 
    },

    methods:{
        // filter funtions
        clearFilter(){
            this.startDate = null;
            this.endDate = null;
            this.isFiltered = null;
            
            this.needToResetItems = true;
        },

        filter(newOptionsScroll){
            this.loading = true;
            let page = 1;
            const { itemsPerPage } = this.scrollOptions;

            if (newOptionsScroll) {
                page = newOptionsScroll.page
            }

            this.$store.dispatch('exams/fetchAll', {
                fromDate: this.startDate,
                toDate: this.endDate,
                page,
                perPage: itemsPerPage,
            })
            .then(({ data, total }) => {
                if (this.needToResetItems) {
                    this.$refs.table.resetShownRows(data);
                    this.needToResetItems = false;
                }
                this.$refs.table.addScrollItems(data);
                this.totalExams = total;
            })
            .finally(() => {
                this.loading = false;
            })
        },

        // import excel files
        resetInputs(){
            if (this.link) this.$refs.form.resetValidation();
            this.id = null;
            this.link = null;
            this.date = null;
        },

        addExam(){
            this.resetInputs();
            this.id = null;
            this.$refs.dialog.isActive = true;
        },

        editExam(item){
            this.resetInputs();
            this.id = item.id;
            // const exam = this.getExamById(item.id);
            const exam = item
            this.link = exam.link;
            this.date = moment(exam.date).format('YYYY-MM-DD');
            this.$refs.dialog.isActive = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;

                const actionName = this.id ? 'update' : 'create';

                this.$store.dispatch(`exams/${actionName}`, {
                    id: this.id,
                    date: this.date,
                    link: this.link
                })
                .then(() => {
                    this.$eventBus.$emit('show-snackbar', this.id ? 'Exam is added' : 'Exam is edited')
                    this.needToResetItems = true;

                    this.filter();
                    this.$refs.dialog.isActive = false;
                })
                .finally(() => {
                    this.submitLoading = false;
                })
            }
        },
    },

    mounted(){
        this.filter();
    },

    metaInfo: {
        title: 'Exams',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>

</style>