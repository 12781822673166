export default {
    'the-visit': 'الزيارة',
    'today': 'اليوم',
    'doctor': 'طبيب',
    'pharmacy': 'صيدلية',
    'center': 'مركز',
    'add-visit': 'إضافة زيارة',
    'edit-visit': 'تعديل زيارة',
    'filter': 'فلتر',
    'materials': 'المواد',
    'competitors': 'المنافسين',
    "unavailable-products": "المنتجات الغير متوفرة",
    "visit-notes": "ملاحظات الزيارة",
    "next-call": "الزيارة التالية",
    "minutes": "الدقائق",
    "objection": "الاعتراض"
}