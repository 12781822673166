<template>
    <div>
        <v-row class="mb-1">
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.reports')}}
                </h1>
            </v-col>
        </v-row>
        <!-- tabs -->
        <v-tabs v-model="tab" show-arrows height="35">
            <v-tab
                class="text-capitalize "
                :to="{ name: 'double-visits'}"
            >
                {{ $t('tabs.double-visit-reports') }}
            </v-tab>

            <v-tab
                v-if="0"
                class="text-capitalize "
                :to="{ 
                    name: 'administrative-reports'
                }"
            >
                {{ $t('tabs.administrative-reports') }}
            </v-tab>

            <!-- <v-tab
                class="text-capitalize "
                disabled
            >
                {{ $t('tabs.administrative-plan') }}
            </v-tab> -->
          
            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-divider class="mb-2"/>

         <!-- child page -->
        <loader v-if="loading"/>
        <router-view v-else/>
    </div>
</template>

<script>
import { roleName } from '@/helpers/enums'
export default {
    data() {
        return {
            tab: 0,
            loading: false,
            roleName
        }
    }
}
</script>
