export default {
    'add-employee': 'Add employee',
    'edit-employee': 'Edit employee',
    'manage-teams': 'Manage teams',
    'manage-roles': 'Manage roles',
    'personal-information': 'Personal information',
    'employee-photo': 'Employee photo',
    'gender': 'Gender: ',
    'work-information': 'Work information',
    'type': 'Type: ',
    'administrative': 'Administrative',
    'medical-rep': 'Medical rep',
    'number-of-vacation-days': 'Number of vacation days',
    'mobile-app-account': 'Mobile app account',
    'generation': 'Generation',
    'username-on-app': 'Username on app',
    'password-on-app': 'Password on app',
    'leave-it-empty-if-you-don\'t-want-to-change': 'Leave it empty if you don\'t want to change',
    'enter-city-to-filter-areas': 'enter city to filter areas',
    'activated': 'Activated',
    'deactivated': 'Deactivated',
    "the-selected-employee-account?": "the selected employee's account",
    'toggle-employee-account-status': 'Toggle employee account status',
    'view-employee-details': 'View employee details',
    'view-the-medical-rep-is-plan': 'View the medical rep is plan',
    'activate-deactivate-employee': 'Activate / Deactivate employee',
    'delete-employee': 'Delete employee',
    'employee-account-status-has-been-toggled-successfully': 'Employee account status has been toggled successfully',
    'employee-account': 'employee account',
    'employee-is-added': 'Employee is added'
}