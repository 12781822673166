<template>
    <div>
        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; needToResetShownRows = true; fetchVisitCount()">
            <v-row class="mx-1 mt-1">
                <!-- city region filter -->
                <v-col md="5" sm="6" cols="12" class="pa-1 d-flex justify-space-between">
                    <v-row class="pa-3">
                        <!-- city name -->
                        <v-col sm="5" cols="12" class="pa-0 pe-1 d-flex">
                            <v-autocomplete
                                v-model="cityId"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                :label="$t('inputs.city')"
                                dense
                                hide-details
                                append-icon
                                :loading="filterLoading"
                                :disabled="loading"
                                @change="fetchRegionsDueCity"
                                outlined
                                prepend-icon="mdi-map-marker-multiple"
                                multiple
                            >
                                <template v-slot:prepend-item>
                                    <v-list-item
                                        ripple
                                        @click="toggleCities"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ iconCities }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('select-all') }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>

                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ cityId.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
            
                        <!-- region -->
                        <v-col sm="7" cols="12" class="pa-0">
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regionsAll.filter(c => cityId.includes(c.cityId))"
                                item-text="name"
                                item-value="id"
                                :return-object="false"
                                :label="$t('inputs.region')"
                                dense
                                multiple
                                clearable
                                hide-details
                                :loading="regionsLoading"
                                :disabled="loading || regionsLoading || cityId === []"
                                outlined
                            >
                                <template v-slot:prepend-item v-if="regions">
                                    <v-list-item
                                        ripple
                                        @click="toggle"
                                    >
                                        <v-list-item-action>
                                            <v-icon>
                                                {{ icon }}
                                            </v-icon>
                                        </v-list-item-action>
                                        
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ $t('select-all') }}
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                </template>
            
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span
                                        v-if="index === 1"
                                        class="grey--text text-caption"
                                    >
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            
                <!-- date -->
                <!-- start date -->
                <v-col md="2" sm="6" cols="12" class="pa-1 ms-md-7">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col md="2" sm="6" cols="12" class="pa-1">
                    <bee-date-picker
                        v-model="endDate"
                        :input-label="$t('inputs.end-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true,
                            clearable: true,
                        }"
                    ></bee-date-picker>
                </v-col>
            </v-row>

            <v-row class="mb-1 mx-2">
                <!-- doctor name -->
                <v-col md="2" sm="6" cols="12" class="pa-1 d-flex justify-start">
                    <v-text-field
                        dense
                        v-model="doctorName"
                        :label="$t('inputs.doctor')"
                        hide-details
                        clearable
                        :disabled="loading"
                        outlined
                        prepend-icon="mdi-doctor"
                    ></v-text-field>
                </v-col>

                <!-- classification -->
                <v-col md="2" sm="6" cols="12" class="pa-1">
                    <v-autocomplete
                        v-model="workload"
                        :items="classification"
                        item-value="id"
                        item-text="name"
                        :return-object="false"
                        :label="$t('inputs.class')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>

                <!-- specialty -->
                <v-col md="2" sm="6" cols="12" class="pa-1">
                    <v-autocomplete
                        v-model="specialtyId"
                        :items="specialties"
                        item-text="name"
                        item-value="id"
                        :return-object="false"
                        :label="$t('inputs.specialty')"
                        dense
                        clearable
                        hide-details
                        :loading="filterLoading"
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
                <!-- <v-divider vertical class="mb-2 mt-1 ms-1" /> -->
                <v-col md="2" sm="6" cols="12" class="pa-1 ms-md-7">
                    <v-autocomplete
                        v-model="medicalRepId"
                        :items="medicalReps"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.medical-rep')"
                        prepend-icon="mdi-account-tie"
                        hide-details
                        clearable
                        :loading="filterLoading"
                        :disabled="loading"
                        outlined
                        dense
                    ></v-autocomplete>
                </v-col>

                <v-spacer />

                <v-col cols="auto">
                    <v-btn
                        color="success"
                        class="px-sm-4"
                        :loading="loading"
                        :disabled="isAllFieldsEmpty || loading"
                        type="submit"
                        small
                    >
                        <v-icon> mdi-magnify </v-icon>
                        {{ $t('search') }}
                    </v-btn>
                </v-col>

                <v-col cols="auto" class="d-flex justify-end px-0">
					<v-btn
						class="px-sm-8"
						@click="clearFilter(); needToResetShownRows = true; fetchVisitCount();"
						:disabled="!isFiltered || loading"
						color="alert-color white--text"
						small
					>
						{{ $t('reset') }}
					</v-btn>
					<v-divider vertical class="mx-2" />
					<v-btn
						color="grey darken-2 white--text"
						small
						:to="{ name: 'visits' }"
					>
                		<v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                		{{ $t('back') }}
            		</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <!-- table -->
        <bee-handy-table
            ref="table"
            v-model="tableInputs"
            :headers="headers"
            :items="!loading ? visitedDoctors : []"
            :loading="loading"
            fixed-header
            :height="$vuetify.breakpoint.height - 190"
            :data-table-props="{
                disableSort: true
            }"
            dense
            zebra
            pagination-on-scroll
            :pagination-on-scroll-auto-reset-shown="false"
            pagination-on-scroll-server-items
            :items-per-page="20"
            :options-scroll.sync="optionsScroll"
            :server-items-length="serverItemsLength"
            @pagination-on-scroll:reach-last-row="fetchVisitCount"
            :shown-rows-text="$t('shown-rows')"
        >
            <!-- visits count -->
            <template v-slot:item.visitsCount="{ item }">
                <span :class="{ 'error--text text--lighten-1': item.visitsCount === 0 }">
                    {{item.visitsCount}}
                </span>
            </template>

            <!-- employee -->
            <template v-slot:item.visitEmployeeIds="{ item }">
                <v-chip
                    v-for="item in item.visitEmployeeIds"
                    :key="item.employeeId"
                	color="primary"
                    class="me-1 mb-1 px-2"
                	small
                >
                    {{`${dig('getMedicalRepById', item.employeeId, 'name')} (${item.count})`}}
                </v-chip>
                <template v-if="!item.visitEmployeeIds.length">
                    -
                </template>
            </template>

            <!-- actions -->
            <template v-slot:item.actions="{ item }">
                <tooltip text="Visit details" top>
                    <v-btn
                        v-if="0"
                        icon
                        small
                        color="light-success"
                        :loading="item.id === entityId"
                        :disabled="true || loading || item.id === entityId"
                        @click="entityId = item.id; goToMatchedVisit(item.id)"
                    >
                        <v-icon>mdi-eye-outline</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Tooltip from '@/components/Tooltip'
import { dig, findClassification } from '@/helpers/functions';
export default {
    name: 'VisitedDoctors',
    
    components: {
        Tooltip
    },
    
    data(){
        return {
            visitedDoctors: [],
            
            // filter data
            doctorName: null,
            cityId: [],
            regionIds: [],
            workload:null,
            specialtyId:null,
            startDate: null,
            endDate: null,

            // helpers
            loading: false,
            filterLoading: false,
            regionsLoading: false,
            isFiltered: false,
            tableInputs: {},
            entityId: null,

            // pagination
            needToResetShownRows: false,
            optionsScroll: {},
            serverItemsLength: 0,
            medicalRepId: null
        }
    },

    computed: {
        ...mapState({
            cities: state => state.cities.cities,
            regions: state => state.regions.regions,
            medicalReps: state => state.medicalreps.medicalreps,
            specialties: state => state.specialties.specialties,
            entities: state => state.entities.entities,
            classification: state => state.workload.classification,
            regionsAll: state => state.regions.regionsAll
        }),
        
        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getSpecialtyById: 'specialties/getSpecialtyById',
            getEntityById: 'entities/getEntityById',
            getClassificationById: 'workload/getClassificationById'
        }),

        isAllFieldsEmpty(){
            return (
                !this.doctorName && this.regionIds.length === 0 && !this.startDate && !this.endDate && !this.selectedType && !this.specialtyId && this.workload === null && !this.medicalRepId
            )
        },

        // selection helpers
        icon() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
      
        selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },
        headers() {
            const headers = [
                { 
                    text: this.$t('headers.doctor'), 
                    name: 'doctorName',
                    align: 'start', 
                    value: 'name',
                    columnClass: 'primary--text',
                },
                {
                    text: this.$t('headers.specialty'),
                    name: 'specialty',
                    value:'specialtyIds',
                    setValueExpr: val => val.map(c => this.getSpecialtyById(c)?.name).join(', '),
                },
                {
                    text: this.$t('headers.class'),
                    name: 'classification',
                    value:'entityAddresses',
                    setValueExpr: val => val.length !== 0 ? this.getClassificationById(val[0].workloadId)?.name : '-'
                },
                { 
                    text: this.$t('headers.visits-count'), 
                    name: 'visitsCount', 
                    value: 'visitsCount', 
                    align: 'center',                     
                    type: 'number',
                    noNotAvailableText: true
                },
                { 
                    text: this.$t('headers.medical-rep'), 
                    name: 'visitEmployeeIds',
                    value: 'visitEmployeeIds', 
                    setValueExpr: val => val ? val.map(c => this.getMedicalRepById(c.employeeId))?.name : null,
                    align: 'center',
                    width: 400,
                },

                // {
                //     text: 'Date of last visit',
                //     name: 'date',
                //     value: '-',
                // }
                // {
                //     text: 'Actions',
                //     name: 'actions',
                //     value: 'actions'
                // }
            ];

            const sharedClass = 'text-body-2 font-weight-bold px-2';

            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;                
                headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';                
            }

            return headers
        },

        iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

        selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },
    },

    methods: {
        // helpers
        dig,

        goToMatchedVisit(id) {
            this.$store.dispatch('entities/getById', { id }).then((data) => {
                const queryData = {
                    startDate: this.startDate,
                    endDate: this.endDate,
                    cityId: this.cityId,
                    regionIds: this.regionIds,
                    specialty: this.specialtyId,
                    workloadId: this.workload,
                    doctorName: data.name
                }
                const query = {};
                Object.keys(queryData).forEach(key => {
                    if (queryData[key]) query[key] = queryData[key];
                })

                this.$router.push({ name: 'visits', query });
            }).finally(() => {
                this.entityId = null;
            })
        },
        
        fetchRegionsDueCity(){
            this.regionIds = [];
            if (!this.cityId) return;

            // this.regionsLoading = true;
            // this.$store.dispatch('regions/fetchAll', {
            //     cityId: this.cityId
            // }).then((data) => {
            //     this.regionIds = data.map(c => c.id)
            // }).finally(() => {
            //     this.regionsLoading = false
            // })
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

        // needed functions for filter
        clearFilter(){
            this.doctorName = null;
            this.cityId = [];
            this.regionIds = [];
            this.selectedType = null;
            this.specialtyId = null
            this.workload = null
            this.visit = null;
            this.startDate = null;
            this.endDate = null
            this.medicalRepId = null

            // reset total visits
            this.serverItemsLength = 0;

            this.isFiltered = false;
        },

        // select all regions function
        toggle () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = []
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

        fetchVisitCount(newOptionsScroll) {
            this.loading = true;
            if (this.isFiltered) this.filterLoading = true;
            
            let page = 1;

            if (newOptionsScroll) {
                page = newOptionsScroll.page;
            }

            const { itemsPerPage } = this.optionsScroll;

            return this.$store.dispatch('entities/fetchVisitedDoctorsByFilter', {
                name: this.doctorName,
                workloadId: this.workload,
                specialtyId: this.specialtyId,
                regionIds: this.regionIds,
                withVisitsCountFrom: this.startDate,
                WithVisitsCountTo: this.endDate,
                IncludeAddresses: true,
                EmployeeId: this.medicalRepId,
                page: page,
                perPage: itemsPerPage || 20
            })
            .then(({ data, total }) => {
                if (this.needToResetShownRows) {
                    this.$refs.table.resetShownRows();
                    this.needToResetShownRows = false;
                }
                
                this.$refs.table.addScrollItems(data);
                this.serverItemsLength = total;
            })
            .finally(() => {
                this.loading = false;
                if (this.isFiltered) this.filterLoading = false;
            })
        },

        toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.fetchRegionsDueCity()
                }
            })
        },
    },

    created(){
        this.loading = true;
        Promise.all([
            this.$store.dispatch('workload/fetchAll'),
            // !this.cities.length ? this.$store.dispatch('cities/fetchCityMine') : null,
            !this.cities.length ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data })
            }) : null,
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll') : null,
            this.fetchVisitCount()
        ])
        .finally(() => {
            this.loading = false;
        });
    },
}
</script>