import * as types from '../mutation-types';

export default {
    namespaced: true,
	state: {
		reports: [],
    },
    
    actions:{
        fetchAll({ commit }, { fromDate, toDate, employeeId }){
            return window.axios.get('/supervisorReports', {
                params:{
                    fromDate,
                    toDate,
                    employeeId
                }
            })
            .then((response) => {
                commit(types.STORE_REPORTS, response.data);
                return response.data
            })
        },

        fetchById({ commit }, { id }) {
            return window.axios.get(`/supervisorReports/${id}`).then(({ data }) => {
                return data
            })
        },
        create({ commit }, { fromDate, toDate, pastOfficeActivities, plannedOfficeActivities, pastOffOfficeActivities, plannedOffOfficeActivities, visitedHospitals, unavailableMaterials, notes, activities }){
            return window.axios.post('/supervisorReports', {
                fromDate,
                toDate,
                pastOfficeActivities,
                plannedOfficeActivities,
                pastOffOfficeActivities,
                plannedOffOfficeActivities,
                visitedHospitals,
                unavailableMaterials,
                notes,
                activities
            })
        },

        update({ commit }, { id, fromDate, toDate, pastOfficeActivities, plannedOfficeActivities, pastOffOfficeActivities, plannedOffOfficeActivities, visitedHospitals, unavailableMaterials, notes, activities }){
            return window.axios.put(`/supervisorReports/${id}`, {
                fromDate,
                toDate,
                pastOfficeActivities,
                plannedOfficeActivities,
                pastOffOfficeActivities,
                plannedOffOfficeActivities,
                visitedHospitals,
                unavailableMaterials,
                notes,
                activities
            })
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/supervisorReports/${id}`);
        }
    },

    mutations:{
        [types.STORE_REPORTS](state, reports){
            state.reports = reports;
        },
    },

    getters:{
        getReportById: state => id => state.reports.find(c => id === c.id),
    }
}