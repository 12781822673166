<template>
    <div>
        <v-row>
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.salaries')}}
                </h1>
            </v-col>

            <v-col v-if="isSuperAdmin || permissionsUser.Salaries === roleName.action" class="d-flex flex-wrap align-center justify-end mt-2">
                <!-- add new salary -->
                <v-btn
                    small
                    @click="addSalary()"
                    color="dark-yellow white--text"
                >
                    <v-icon size="22" class="me-2">
                        mdi-plus-circle-outline
                    </v-icon>
                    {{$t('Salaries.add-new-salary')}}
                </v-btn>

                <v-divider vertical class="mx-2 my-1"/>

                <!-- excel buttons -->
                <div class="my-sm-0 my-5">
                    <!-- Import Excel -->
                    <v-btn
                        @click="$refs.ImportDialog.import('salaries')"
                        small
                        color="green lighten-1 white--text"
                    >
                        <v-icon size="22" class="me-1">
                            mdi-file-table-outline
                        </v-icon>
                        {{ $t('Salaries.use-excel-file') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>

        <!-- Salary Dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="650"
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form" :disabled="submitLoading">
                    <v-card>
                        <div class="d-flex primary py-1">
                            <v-card-title
                                class="text-uppercase flex-grow-1 justify-center text-subtitle-1 pa-0 white--text"
                            >
                                {{$t('Salaries.add-new-salary')}}
                            </v-card-title>

                            <!-- close button -->
                            <v-btn icon @click="dialog.value = false; $refs.form.resetValidation()">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>
                        <v-card-text class="pt-2 px-3 pb-0">
                            <!-- dialog content -->
                            <v-row dense class="justify-start">
                                <!-- Medical rep name -->
                                <v-col sm="6" cols="12" class="px-1 py-2">
                                    <v-autocomplete
                                        v-model="medicalRepId"
                                        :items="medicalreps"
                                        item-text="name"
                                        item-value="id"
                                        :label="$t('inputs.employee-name')"
                                        :rules="rules.required"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-autocomplete>
                                </v-col>
                                
                                <!-- date -->
                                <v-col sm="6" cols="12" class="px-1 py-2">
                                    <bee-date-picker
                                        v-model="date"
                                        :input-label="$t('inputs.date')"
                                        menu-picker
                                        dense
                                        hide-details
                                        :input-props="{
                                            outlined: true,
                                            dense: true
                                        }"
                                        :disabled="loading"
                                        :rules="rules.required"
                                    ></bee-date-picker>
                                </v-col>
                                
                                <!-- Salary -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="salary"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.salary')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Transportation Allowance -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="transAllowance"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.transportation-allowance')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Living Cost Compensation -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="livingCost"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.living-cost-compensation')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Retention Bonus -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="retentionBonus"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.retention-bonus')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Communication Allowance -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="communicateAllowance"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.communication-allowance')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                                
                                <!-- Liability Compensation -->
                                <v-col md="4" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="liabilityCompensation"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.liability-compensation')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- Rewards -->
                                <v-col md="6" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="rewards"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.rewards')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>

                                <!-- rebates -->
                                <v-col md="6" sm="6" cols="12">
                                    <v-text-field
                                        v-model.number="rebates"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.rebates')"
                                        :rules="rules.requiredAsNumber"
                                        outlined
                                        dense
                                        hide-details="auto"
                                    ></v-text-field>
                                </v-col>
                            
                                <!-- Total Salary -->
                                <v-col sm="4" cols="12">
                                    <v-text-field
                                        :value="totalSalary"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.total-salary')"
                                        outlined
                                        readonly
                                        hide-details
                                        :rules="rules.requiredAsNumber"
                                        dense
                                    ></v-text-field>
                                </v-col>

                                <!-- - Rebates -->
                                <div class="d-flex flex-sm-row flex-column justify-center align-center font-size-2">
                                    - <span class="mx-4">Rebates</span> =
                                </div>

                                <!-- Net Salary -->
                                <v-col sm="5" cols="12">
                                    <v-text-field
                                        :value="netSalary"
                                        type="number"
                                        min="0"
                                        :label="$t('inputs.net-salary')"
                                        outlined
                                        readonly
                                        hide-details
                                        :rules="rules.requiredAsNumber"
                                        dense
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                :loading="submitLoading"
                                :disabled="submitLoading"
                                color="success white--text"
                                elevation="0"
                                text
                            >
                                {{$t('save')}}
                            </v-btn>
                            <v-btn
                                @click="dialog.value = false"
                                elevation="0"
                                text
                                :disabled="submitLoading"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- import-dialog declared here to use it as refs -->
        <import-dialog ref="ImportDialog" @imported="clearFilter(); filter();"></import-dialog>

        <!-- delete-dialog declared here to use it as refs -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="needToResetItems = true; filter();"
            :success-message="$t('data-is-deleted')"
        >
            {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{ $t('delete') }}</span> the salary ?
        </delete-dialog>

        <!-- filter -->
        <v-form @submit.prevent="needToResetItems = true; isFiltered = true; filter();" :disabled="loading">
            <v-row class="my-1 mx-1" align="center">
                <!-- start date -->
                <v-col md="2" sm="4" cols="12" class="pa-1">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col md="2" sm="4" cols="12" class="pa-1">
                   <bee-date-picker
                        v-model="endDate"
                        :input-label="$t('inputs.end-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                    ></bee-date-picker>
                </v-col>
            
                <v-spacer />
            
                <v-col class="d-flex justify-start justify-md-end px-0">
                    <v-btn
                        color="success"
                        class="px-sm-4 mx-2"
                        :loading="loading"
                        :disabled="loading || isAllFieldsEmpty"
                        small
                        type="submit"
                    >
                        <v-icon> mdi-magnify </v-icon>
                        {{$t('search')}}
                    </v-btn>
                    <v-btn
                        color="alert-color white--text"
                        class="px-sm-8"
                        @click="clearFilter(); filter();"
                        :disabled="!isFiltered || loading"
                        small
                    >
                        {{$t('reset')}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="[]"
            :loading="loading"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :server-items-length="totalSalaries"
            :pagination-on-scroll-auto-reset-shown="false"
            :options-scroll.sync="scrollOptions"
            :items-per-page="20"
            fixed-header
            dense
            zebra
            not-available-text="0"
            :height="$vuetify.breakpoint.height - 150"
            :data-table-props="{
                disableSort: true
            }"
            @pagination-on-scroll:reach-last-row="filter"
        >
            <!-- action -->
            <template v-slot:item.action="{ item }">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('edit')" top>
                        <v-btn icon small @click="editSalary(item)" color="edit-color">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>

                    <tooltip :text="$t('delete')" top>
                        <v-btn icon small @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'salaries')" color="alert-color">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import DeleteDialog from '@/components/DeleteDialog'
import ImportDialog from '@/components/ImportDialog'
import Tooltip from '@/components/Tooltip'
import rules from '@/validation rules'
import moment from 'moment'
import { dig } from '@/helpers/functions'
import { roleName } from '@/helpers/enums'

export default {
    name: 'Salaries',

    components:{
        DeleteDialog,
        ImportDialog,
        Tooltip,
    },

    data() {
        return {
			roleName,
            // options
            scrollOptions: null,
            totalSalaries: null,

            // helpers
            loading: false,
            submitLoading: false,
            isFiltered: false,
            needToResetItems: false,

            // filter data
            sectorId: null,
            startDate: null,
            endDate: null,

            // helpers
            salaryId: null,
            rules,

            // date
            date: null,
            medicalRepId: null,
            salary: null,
            transAllowance: null,
            livingCost: null,
            retentionBonus: null,
            communicateAllowance: null,
            liabilityCompensation: null,
            rewards: null,
            rebates: null,
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return (!this.startDate && !this.endDate && !this.sectorId)
        },

        ...mapState({
            sectors: state => state.sectors.sectors,
            medicalreps: state => state.medicalreps.medicalreps,
			permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin:  state => state.auth.isSuperAdmin,
        }),

        ...mapGetters({
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getSectorById: 'sectors/getSectorById',
        }),
    
        headers() {
            const headers = [
                { 
                    
                    text: this.$t('headers.employee'), 
                    value: 'employeeId', 
                    name:'employeeId',
                    setValueExpr: val => this.dig('getMedicalRepById', val, 'name') || '-',
                    class: 'primary--text',
                    cellClass: 'primary--text',
                    width: 120
                },
                // { 
                //     text: 'Sector', 
                //     value: 'sector',
                //     setValueExpr: val => this.dig('getSectorById', this.dig('getMedicalRepById', this.tableInputs.employeeId, 'sectorId')
                //     , 'name') || '-',
                //     align: 'center',
                //     width: 120,
                // },
                { 
                    text: this.$t('headers.salary'), 
                    name: 'baseSalary',
                    value: 'baseSalary', 
                    type: 'number-format',
                    align: 'center', 
                },
                { 
                    text:  this.$t('headers.transportation-allowance'), 
                    name: 'transportation',
                    value: 'transportation', 
                    type: 'number-format',
                    align: 'center',
                },
                { 
                    text: this.$t('headers.living-cost-compensation'), 
                    name: 'livingExpenses',
                    value: 'livingExpenses', 
                    type: 'number-format',
                    align: 'center', 
                },
                { 
                    text: this.$t('headers.retention-bonus'), 
                    name: 'retentionBonus',
                    value: 'retentionBonus', 
                    type: 'number-format',
                    align: 'center', 
                },
                { 
                    text: this.$t('headers.communication-allowance'), 
                    name: 'communications',
                    value: 'communications', 
                    type: 'number-format',
                    align: 'center',
                },
                { 
                    text: this.$t('headers.liability-compensation'), 
                    name: 'liabilityCompensation',
                    value: 'liabilityCompensation', 
                    type: 'number-format',
                    align: 'center', 
                },
                { 
                    text: this.$t('headers.rewards'), 
                    name: 'rewards',
                    value: 'rewards',
                    type: 'number-format',
                    align: 'center',
                },
                // { 
                //     text: 'Total Salary', 
                //     value: 'total', 
                //     align: 'center',
                // },
                { 
                    text: this.$t('headers.rebates'), 
                    name: 'rebates',
                    value: 'rebates', 
                    type: 'number-format',
                    align: 'center', 
                },
                { 
                    text: this.$t('headers.net-salary'),
                    name: 'finalTotal',
                    value: 'finalTotal', 
                    type: 'number-format',
                    align: 'center', 
                },
                { 
                    text: this.$t('headers.date'), 
                    name: 'date', 
                    value: 'date', 
                    type: 'date', 
                    align: 'center', 
                    cellClass:'fixed-width-date',
                },
            ];

            const sharedClass = 'text-body-2 font-weight-bold';

            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
                headers[i].columnClass = headers[i].columnClass ? headers[i].columnClass + ' px-2' : 'px-2';
            }
            if (this.isSuperAdmin || this.permissionsUser.Salaries === roleName.action) {
                headers.push({
                    text: this.$t('headers.actions'), 
                    name: 'action',
                    value: 'action', 
                    align: 'center',
                })
            }

            return headers
        },
         
        totalSalary(){
            return (
                Number(this.salary) +
                Number(this.transAllowance) +
                Number(this.livingCost) +
                Number(this.retentionBonus) +
                Number(this.communicateAllowance) +
                Number(this.liabilityCompensation) +
                Number(this.rewards)
            );
        },

        netSalary(){
            return this.totalSalary - this.rebates;
        },
    },

    methods:{
        // filter functions
        clearFilter(){
            this.startDate = null;
            this.endDate = null;
            this.sectorId = null;
            this.isFiltered = false;
            
            this.needToResetItems = true;
        },

        filter(newScrollOptions, isFirstLoad = false){
            this.loading = true;
            let page = 1;

            const { itemsPerPage } = this.scrollOptions;

            if (newScrollOptions) {
                page = newScrollOptions.page
            }

            return this.$store.dispatch('salaries/fetchAll', { 
                fromDate: this.startDate,
                toDate: this.endDate,
                sectorId: this.sectorId,
                perPage: itemsPerPage,
                page
            })
            .then(({ data, total }) => {
                if (this.needToResetItems) {
                    this.$refs.table.resetShownRows();
                    this.needToResetItems = false;
                }
                this.$refs.table.addScrollItems(data);
                this.totalSalaries = total;
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        // dialog function
        addSalary(){
            if (this.medicalRepId !== null) this.$refs.form.reset();
            this.salaryId = null;
            this.$refs.dialog.isActive = true;
        },

        editSalary(salary){
            this.salaryId = salary.id;
            this.date = moment(salary.date).format('YYYY-MM-DD');
            this.medicalRepId = salary.employeeId;
            this.salary = salary.baseSalary;
            this.transAllowance = salary.transportation;
            this.livingCost = salary.livingExpenses;
            this.retentionBonus = salary.retentionBonus;
            this.communicateAllowance = salary.communications;
            this.liabilityCompensation = salary.liabilityCompensation;
            this.rewards = salary.rewards;
            this.rebates = salary.rebates;
            this.$refs.dialog.isActive = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                const actionName = this.salaryId ? 'update' : 'create';

                this.$store.dispatch(`salaries/${actionName}`, {
                    id: this.salaryId,
                    date: this.date,
                    employeeId: this.medicalRepId,
                    baseSalary: this.salary,
                    transportation: this.transAllowance,
                    livingExpenses: this.livingCost,
                    retentionBonus: this.retentionBonus,
                    communications: this.communicateAllowance,
                    liabilityCompensation: this.liabilityCompensation,
                    rewards: this.rewards,
                    rebates: this.rebates 
                })
                .then(() => {
                    this.message = this.salaryId ? 'Salary is edited' : 'Salary is added';
                    this.$eventBus.$emit('show-snackbar', this.message, 'info');

                    this.needToResetItems = true;
                    this.filter();
                    this.$refs.dialog.isActive = false;
                })
                .finally(() => {
                    this.submitLoading = false;
                })
            }
        },

        // helpers
        dig,
    },

    mounted(){
        this.loading = true;
        Promise.all([
            !this.sectors.length ? this.$store.dispatch('sectors/fetchAll') : null,
            !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            this.filter(null, true)
        ]).finally(() => {
            this.loading = false
        })
    },

    metaInfo: {
        title: 'Salaries',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>

</style>