export default {
    'visit-date': "تاريخ الزيارة",
    "entity-name": "اسم الجهة",
    'edit-visit': 'تعديل الزيارة',
    "delete-visit": 'حذف زيارة',
    'add-new-visit': "إضافة زيارة جديدة",
    'visit': 'الزيارة',
    'are-you-sure-you-want-to-delete-this': 'هل أنت متأكد أنك تريد حذف هذه ',
    'view-materials-visit': 'مواد الزيارة',
    'sorry-there-is-no-plan-created-by-the-employee': 'عذرًا، -لا توجد خطة تم إنشاؤها بواسطة الموظف',
    'sorry-there-is-no-plan-created-for-the-selected-month': 'عذرًا، لم يتم إنشاء خطة للشهر المحدد'
}