<template> 
    <div>
        <!-- add vacation -->
		<v-dialog v-model="vacationDialog" width="450px">
			<v-card>
				<div class="d-flex justify-space-between py-1 primary">
					<v-card-title 
						class="justify-center flex-grow-1 text-uppercase text-subtitle-1 white--text white--text pa-0"
					>
						{{$t('NavigationDrawer.add-day-off-request')}}
					</v-card-title>

					<v-btn icon color="white" @click="vacationDialog = false">
						<v-icon> mdi-close-circle-outline </v-icon>
					</v-btn>
				</div>

				<v-card-text class="pt-6 pb-0">
					<v-row>
						<v-col cols="6" class="py-1 px-1">
							<v-autocomplete
								v-model.number="leaveData.type"
								:items="requestTypes"
								item-text="name"
								item-value="id"
								:label="$t('inputs.paid-leave')"
								outlined
								dense
								:loading="submitLoading"
								:disabled="submitLoading"
							/>
						</v-col>

						<v-col cols="6" class="py-1 px-1">
							<v-text-field
								v-model.number="leaveData.daysCount"
								:label="$t('inputs.days-count')"
								outlined
								dense
								:loading="submitLoading"
								:disabled="submitLoading"
							/>
						</v-col>

						<v-col cols="6" class="py-1 px-1">
							<bee-date-picker
								v-model="leaveData.date"
								:input-label="$t('inputs.start-date')"
								elevation="2"
								menu-picker
								dense
								hide-details
								:input-props="{
									outlined: true,
									dense: true,
									clearable: true,
								}"
								:loading="submitLoading"
								:disabled="submitLoading"
								years-over-max="3"
							></bee-date-picker>
						</v-col>

						<v-col cols="6" class="py-1 px-1">
							<v-text-field 
								v-model="leaveData.reason"
								:label="$t('inputs.reason')"
								outlined
								dense
								:loading="submitLoading"
								:disabled="submitLoading"
							/>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn
						text
						color="success"
						:loading="submitLoading"
						:disabled="submitLoading"
						@click="createVacation()"
					>
						{{$t('send')}}
					</v-btn>

					<v-btn
						@click="vacationDialog = false"
						:disabled="submitLoading"
						text
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
        <v-app-bar
            app
            color="primary "
            class="px-xs-5 elevation-0 white--text mb-1"
            height="40"
        >   
            <v-row>
                <v-col cols="10">
                    <v-menu
                        v-for="(item, i) in list"
                        :key="i"
                        bottom
                        offset-y
                        dense
                        
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="white"
                                dark
                                v-bind="attrs"
                                v-on="on"
                                text
                                dense
                                @click="item.title === $t('NavigationDrawer.home') ? $router.replace({ name: 'home' }) : null"
                            >
                                <v-icon small> {{item.icon}} </v-icon>
                                {{ item.title }}
                            </v-btn>
                        </template>

                        <v-list v-if="item.title !== $t('NavigationDrawer.home')">
                            <div  
                                v-for="(ele, index) in item.itemList"
                                :key="index"
                            >
                                <v-list-item
                                    v-if="ele.show"
                                    :to="{ name: ele.target }"
                                >
                                
                                    <v-list-item-icon v-if="ele.icon">
                                        <v-icon color="primary"> {{ele.icon}} </v-icon>
                                    </v-list-item-icon>

                                    <v-list-item-avatar v-else class="py-0 pe-4 my-0">
                                        <v-img
                                            :src="ele.img"
                                            min-width="40"		
                                            width="40"		
                                            max-height="20"	
                                            contain
                                            class="d-flex justify-end"
                                        />
                                    </v-list-item-avatar>
                                    <v-list-item-title>{{ ele.name }}</v-list-item-title>
                                </v-list-item>
                            </div>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col class="d-flex justify-end">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="white"
                                dark
                                text
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon class="me-1"> mdi-account-circle </v-icon>
                                {{userName}}
                            </v-btn>
                        </template>

                        <v-list class="px-0 py-0">
                            <v-list-item class="px-0" >
                                <v-card
                                    max-width="400"
                                    class="mx-auto"
                                >
                                    <v-card-title class="py-1">
                                        <v-img 
                                            src="@/assets/male.jpg" 
                                            contain
                                            max-width="50"
                                            width="50"
                                            min-width="50"  
                                            class="me-4"
                                        />
                                        <span class="text-body"> {{userName}} </span>
                                    </v-card-title>
                                    <v-divider></v-divider>

                                    <v-list>
                                        <v-list-item >
                                            <v-list-item-content>
                                                <v-text-field
                                                    v-model="vacations"
                                                    label="Vacations balance"
                                                    dense
                                                    outlined
                                                    hide-details="auto"
                                                    disabled
                                                />
                                            </v-list-item-content>
                                        </v-list-item>
                                        
                                        <v-divider class="mx-2"></v-divider>
                                        
                                        <v-list-item @click="vacationDialog = true">
                                            <v-list-item-title>
                                                <v-icon color="orange">
                                                    mdi-calendar
                                                </v-icon>
                                                Day off request
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-divider class="mx-2"></v-divider>

                                        <v-list-item @click="logout()" >
                                            <v-list-item-title>
                                                <v-icon color="orange">
                                                    mdi-logout
                                                </v-icon>
                                                Logout
                                            </v-list-item-title>
                                        
                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </v-list-item>
                        </v-list>
                        
                    </v-menu>
                </v-col>
            </v-row>
            <!-- {{img}} -->
        
        </v-app-bar>
    </div>
</template>

<script>
import routes from '@/router/routes';
import { mapState } from 'vuex'
import { requestTypes, roleName } from '@/helpers/enums'
export default {
    name:'navbar',

    watch: {
		vacationDialog (val) {
			if (val === false) {
				this.leaveData.type = null;
				this.leaveData.daysCount = null;
				this.leaveData.date = null;
				this.leaveData.reason = null
			}
		}
	},

    data(){
        return {
            roleName,
            submitLoading: false,
            selectedItem: -1,
            items:[
                { title: 'Logout' },
            ],
            on: false,
            vacationDialog: false,
            Days: [],
            vacations: null,
            requestTypes,
            leaveData: {
				type: null,
				daysCount: null,
				date: null,
				reason: null
			},
        }
    },

    computed:{
        ...mapState({
            userName: state => state.auth.userData.username,
            employeeId: state => state.auth.userData.employeeId,
            permissionsUser: state => state.auth.youPermissions,
			isSuperAdmin: state => state.auth.isSuperAdmin,
        }),

        list () {
            return [
                {
                    title: this.$t('NavigationDrawer.home'),
                    icon: 'mdi-home'
                },
                {
                    title: this.$t('NavigationDrawer.scientific-office'),
                    icon: 'mdi-bullhorn',
                    itemList: [
                        {
                            name: this.$t('NavigationDrawer.visits'),
                            target: 'visits',
                            show: this.isSuperAdmin || this.permissionsUser.MedicalVisits !== roleName.nothing,
                            icon: 'mdi-map-marker'
                        },
                        {
                            name: this.$t('NavigationDrawer.med-rep-plan'),
                            target: 'medical-rep-plan',
                            show: this.isSuperAdmin || this.permissionsUser.MedicalPlans !== roleName.nothing,
                            icon: 'mdi-calendar'
                        },
                        {
                            name: this.$t('NavigationDrawer.day-off-requests'),
                            target: 'day-off-requests',
                            show: this.isSuperAdmin || this.permissionsUser.Leaves !== roleName.nothing,
                            img: require('@/assets/sidebar-icons/calendar.png')
                        },

                        {
                            name: this.$t('NavigationDrawer.reports'),
                            target: 'double-visits',
                            show: this.isSuperAdmin || this.permissionsUser.WeeklyReports !== roleName.nothing,
                            img: require('@/assets/sidebar-icons/report.png')
                        },

                        {
                            name: this.$t('NavigationDrawer.med-rep-mark'),
                            target: 'visit-frequency-report',
                            show: true,
                            icon: 'mdi-chart-waterfall mdi-rotate-90'
                        },

                        {
                            name: this.$t('NavigationDrawer.exams'),
                            target: 'Exams',
                            show: this.isSuperAdmin || this.permissionsUser.Exams !== roleName.nothing,
                            img: require('@/assets/sidebar-icons/exam.png')
                        },
                        
                        {
                            name: this.$t('NavigationDrawer.salaries'),
                            target: 'salaries',
                            show: this.isSuperAdmin || this.permissionsUser.Salaries !== roleName.nothing,
                            img: require('@/assets/sidebar-icons/money.png')
                        },
                    ]
                },
                {
                    title: this.$t('NavigationDrawer.storehouse'),
                    icon: 'mdi-warehouse',
                    itemList: [
                        {
                            name: this.$t('NavigationDrawer.delivery-materials'),
                            target: 'journal-delivery-of-samples',
                            show: true,
                            img: require('@/assets/sidebar-icons/blood-sample.png')

                        },
                        {
                            name: this.$t('NavigationDrawer.items-journal'),
                            target: 'journal-items',
                            show: true,
                            img: require('@/assets/sidebar-icons/credit.png')
                        },
                        {
                            name: this.$t('NavigationDrawer.statement'),
                            target: 'samples-statement',
                            show: true,
                            img: require('@/assets/sidebar-icons/delivery (1).png')
                        },
                        {
                            name: this.$t('NavigationDrawer.materials'),
                            target: 'materials',
                            show: true,
                            img: require('@/assets/sidebar-icons/option.png')
                        },

                        {
                            name: this.$t('NavigationDrawer.loaned-materials'),
                            target: 'loaned-items',
                            show: true,
                            img: require('@/assets/sidebar-icons/option.png')
                        },
                        {
                            name: this.$t('NavigationDrawer.products-statement'),
                            target: 'products-statement',
                            show: this.isSuperAdmin || this.isAreaManager,
                            icon: 'mdi-script-text<'
                        },
                        {
                            name: this.$t('NavigationDrawer.minimal-interface'),
                            target: 'minimal-interface',
                            show: true,
                            img: require("@/assets/sidebar-icons/option2.png")
                        },
                        {
                            name: this.$t('NavigationDrawer.warehouses-statistics'),
                            target: 'warehouses-statistics',
                            show: true,
                            img: require("@/assets/sidebar-icons/option2.png")
                        }
                        
                    ]
                },
                {
                    title: this.$t('NavigationDrawer.management'),
                    icon: 'mdi-cogs',
                    itemList: [
                        {
                            name: this.$t('NavigationDrawer.companies'),
                            target: 'companies',
                            show: true,
                            icon: 'mdi-domain'
                        },

                        {
                            name: this.$t('NavigationDrawer.staff'),
                            target: 'staff',
                            show: true,
                            img: require('@/assets/sidebar-icons/suitcase.png')
                        },
                        {
                            name: this.$t('NavigationDrawer.customers'),
                            target: 'customers',
                            show: true,
                            icon: 'mdi-account-multiple'
                        },
                        
                        {
                            name: this.$t('NavigationDrawer.managing-roles'),
                            target: 'Roles',
                            show: true,
                            img: require("@/assets/sidebar-icons/setting.png")
                        },

                        {
                            name: this.$t('NavigationDrawer.external-entities'),
                            target: 'accounts-tree',
                            show: true,
                            icon: 'mdi-account-group'
                        },
                        
                        {
                            name: this.$t('NavigationDrawer.products'),
                            target: 'products',
                            show: true,
                            icon: 'mdi-package-variant-closed'
                        },

                        {
                            name: this.$t('NavigationDrawer.regions-management'),
                            target: 'regions-management',
                            show: this.isSuperAdmin || this.permissionsUser.Regions !== roleName.nothing,
                            icon: 'mdi-city-variant-outline'
                        },
                        
                    ]
                    
                },
                {
                    title: this.$t('NavigationDrawer.my-profile'),
                    icon: 'mdi-file-account',
                    itemList: [
                        {
                            name: this.$t('NavigationDrawer.my-visits'),
                            target: 'administrative-visit',
                            show: this.isSuperAdmin || this.permissionsUser.MedicalPlans !== roleName.nothing,
                            icon: 'mdi-map-marker'
                        },
                        {
                            name: this.$t('NavigationDrawer.my-plan'),
                            target: 'administrative-plan',
                            show: true,
                            icon: 'mdi-file'
                        }
                    ]
                }
            ]
        } 
    },

    methods:{
		getRouteTitle() {
            const translateKey = this.$route.meta.i18nKey;
            const title = translateKey ? this.$t(`routes.${translateKey}`) : null;
            if (this.$route.params.type) {
                return this.$route.params.type + ' ' + title;
            } else {
                return title
            }
		},

        logout(){
            this.$store.dispatch('auth/logout');
            this.$router.replace({ name:'login' })
        },

        createVacation() {
            this.submitLoading = true
            this.$store.dispatch('leaveManagement/create', { 
                date: this.leaveData.date, 
                days: this.leaveData.daysCount,
                type: this.leaveData.type,
                reason: this.leaveData.reason
            }).then(() => {
               this.vacationDialog = false
            }).finally(() => {
                this.submitLoading = false
            })
        },
    },

    created() {
        this.$store.dispatch('leaveManagement/fetchCount', { employeeId: this.employeeId }).then((data) => {
            this.vacations = data.remainingNumberOfLeaves
        })
    }
    
}
</script>

<style lang="scss">
@import '../scss/variables.scss';
#companey-name{
    font-weight: bolder;
    // line-height: 20px;
    h1{
        span:nth-child(1){
            font-size: 25px ;
            margin-bottom: -5px;
            line-height: 30px;
        }
        span:nth-child(2){
            font-size: 16px;
        }
    }
}
</style>