<template>
    <div>
        <!-- dialog -->
        <v-dialog v-model="dialog" width="400px">
            <v-card>
                <div class="d-flex justify-end py-1 background-title-card ">
                    <v-card-title
                        class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                    >
                        {{ employeeId ? 'Edit employee' : 'Add new employee' }}
                    </v-card-title>
                    <!-- close button -->
                    <v-btn icon @click="dialog = false">
                        <v-icon color="white">
                            mdi-close-circle-outline
                        </v-icon>
                    </v-btn>
                </div> 

                <v-card-text>
                    <v-row class="mt-2">
                        <v-col cols="12" class="py-1">
                            <v-text-field v-model="dialogData.name" label="name" dense outlined hide-details="auto" />
                        </v-col>
                        <v-col cols="12" class="py-1">
                            <v-autocomplete
                            	v-model="dialogData.JobTitle"
                                :items="jobTitle"
                                item-text="name"
                                item-value="id"
                            	label="Job title"
                            	dense
                            	outlined
                            	hide-details="auto"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn text color="success">
                        Save
                    </v-btn>

                    <v-btn text @click="dialog = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- delete dialog -->
        <delete-dialog
            ref="deleteDialog"
            @on-delete="isFiltered ? filter() : fetchEntitiesForCurrentTab()"
            :success-message="$t('data-is-deleted')"
        >
            <template v-slot:default>
                <p class="mb-2">
                    {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{ $t('delete') }}</span> {{tabs.labels[tab]}} data?
                </p>
            </template>
        </delete-dialog>

         <delete-dialog
            ref="deleteDialog"
            @on-delete="isFiltered ? filter() : fetchEntitiesForCurrentTab()"
            :success-message="$t('data-is-deleted')"
        >
            <template v-slot:default>
                <p class="mb-2">
                    {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{ $t('delete') }}</span> {{tabs.labels[tab]}} data?
                </p>
            </template>
        </delete-dialog>
        <!-- header -->
        <v-row>
            <!-- title -->
            <v-col>
                <h2 class="text-h5 primary--text text-capitalize"> Manage users </h2>
            </v-col>
            <v-spacer />
            <!-- add button -->
            <v-col class="d-flex justify-end">
                <v-btn
                	color="dark-yellow white--text"
                	small
                	@click="dialog = true"
                >
                    <v-icon class="me-1"> 
                        mdi-plus-circle-outline 
                    </v-icon>
                    Add new employee 
                </v-btn>
            </v-col>
        </v-row>
        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="array"
            class="mt-2"
            dense
            zebra
        >
            <template v-slot:item.actions="{ index }">
                <v-btn color="edit-color" text x-small @click="employeeId = array[index].id; dialog = true">
                    <v-icon> mdi-pencil </v-icon>
                </v-btn>

                <v-btn color="alert-color" text x-small>
                    <v-icon> mdi-delete </v-icon>
                </v-btn>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import deleteDialog from '../../components/DeleteDialog.vue';
export default {
    name: 'Users',

    components: {
        deleteDialog
    },

    data () {
        return {
            array: [
                {
                    id: 1,
                    name: 'Ahmad',
                    JobTitle: 'Team lader'
                },
                {
                    id:3,
                    name: 'Yaser',
                    JobTitle: 'Medical rep'
                },
                {
                    id: 7,
                    name: 'Bana',
                    JobTitle: 'supervisor'
                }
            ],
            employeeId: null,
            dialog: false,
            dialogData: {
                name: null,
                JobTitle: null
            },

            jobTitle: [
                'regional manager',
                'supervisor',
                'Team Leader',
                'Medical rep'
            ]
        }
    },

    watch: {
        dialog (val) {
            if (val) {
                this.dialogData.name = this.array.find(c => c.id === this.employeeId).name
                this.dialogData.JobTitle = this.array.find(c => c.id === this.employeeId).JobTitle
            } else {
                this.dialogData.name = null;
                this.dialogData.JobTitle = null;
                this.employeeId = null
            }
        }
    },

    computed: {
        headers () {
            return [
                {
                    text: 'Employee name',
                    name: 'name',
                    value: 'name'
                },
                {
                    text: 'Job title',
                    name: 'Job-title',
                    value: 'JobTitle'
                },
                {
                    text: '',
                    name: 'actions',
                    value: 'actions'
                }
            ]
        }
    }

}
</script>

<style>

</style>