export default {
    'delete-request': 'حذف الطلب',
    'reject': 'مرفوض',
    'accept': 'مقبول',
    'are-you-sure-you-want-to': 'هل أنت متأكد من أنك تريد',
    'this-request': 'هذا الطلب',
    'n-accept': 'قبول',
    'n-reject': 'رفض',
    'confirm-acceptance': 'تأكيد القبول',
    'confirm-rejection': 'تأكيد الرفض'
}