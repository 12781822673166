export default {
    'new-visit': 'زيارة جديدة',
    'type-visit': 'نوع الزيارة',
    'entities': 'الجهات',
    'manager-name': 'اسم المدير',
    'entities-name': 'اسم الجهات',
    'administrative': 'إداري',
    'double-visit': 'الزيارة المزدوجة',
    'visit': 'الزيارة',
    'activity': 'النشاط',
    'medical-rep-name': 'اسم المندوب الطبي',
    'activities': 'النشاطات',
    'select-activity': 'اختر النشاط'
}