<template>
    <div>
        <!-- dialog -->
        <v-dialog
            v-model="dialog"
            max-width="350"
        >
            <v-form @submit.prevent="submit" ref="form">
                <v-card>
                    <div class="d-flex justify-end py-1 background-title-card ">
                        <v-card-title
                            class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                        >
                            {{ $t('Companies.add-new-company') }}
                        </v-card-title>
                        <!-- close button -->
                        <v-btn icon @click="dialog = false; companyId = null; $refs.form.resetValidation()">
                            <v-icon color="white">
                                mdi-close-circle-outline
                            </v-icon>
                        </v-btn>
                    </div>

                    <v-card-text class="pa-3 pb-0">
                        <!-- company name -->
                        <v-text-field
                            v-model="companyName"
                            :label="$t('inputs.company-name')"
                            clearable
                            :rules="rules.required"
                            :disabled="loading"
                            required
                            dense
                            outlined
                            hide-details="auto"
                        ></v-text-field>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            type="submit"
                            color="success"
                            :loading="loading"
                            :disabled="loading"
                            text
                        >
                            {{ $t('save') }}
                        </v-btn>
                        <v-btn
                            @click="dialog = false; companyId = null; $refs.form.resetValidation()"
                            elevation="0"
                            text
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <!-- delete dialog declaered here to use it as ref -->
            <!--  -->
        <delete-dialog
            ref="deleteDialog"
            :success-message="$t('data-is-deleted')"
            @on-delete="fetchAll"
        >
            {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{$t('delete')}}</span> {{$t('Companies.this-company')}} {{$t('?')}}
        </delete-dialog>   

        <v-row class="mb-1">
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.companies')}}
                </h1>
            </v-col>

            <v-col class="d-flex justify-end mt-1">
                <v-btn 
                    @click="companyName = null; dialog = true" 
                    small 
                    color="dark-yellow white--text" 
                    class="mb-1" 
                    :disabled="loading"
                >
                    <v-icon color=""> mdi-plus-circle </v-icon>
                    {{ $t('Companies.add-new-company') }}
                </v-btn>
            </v-col>
        </v-row>

        <bee-handy-table
            :headers="headers"
            :items="!loading ? companies : []"
            :height="$vuetify.breakpoint.height - 94"
            fixed-header
            pagination-on-scroll
            dense
            :loading="loading"
        >
            <template v-slot:item.actions="{ item }">
                <div class="d-flex justify-center">
                    <tooltip :text="$t('view')" top>
                        <v-btn
                            icon
                            small
                            @click="companyId = item.id; dialog = true"
                            color="edit-color"
                        >
                            <v-icon>
                              mdi-pencil
                            </v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip
                    	:text="$t('delete')"
                    	top
                    >
                        <v-btn
                        	icon
                        	small
                        	color="alert-color"
                            @click="companyId = item.id; $refs.deleteDialog.activeDeleteDialog(item.id, 'companies')"
                        >
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>
<script>
import DeleteDialog from '@/components/DeleteDialog.vue'
import Tooltip from '@/components/Tooltip.vue'
import rules from '@/validation rules'
import { mapGetters, mapState } from 'vuex'

export default {
    components: {
        DeleteDialog,
        Tooltip
    },

    watch: {
        dialog(val) {
            if (val && this.companyId !== null) {
                this.companyName = this.getCompanyById(this.companyId)?.name
            } else {
                this.companyName = null
            }
        }
    },

    data () {
        return {
            rules,
            dialog: false,
            companyName: null,
            loading: false,
            headers:[
                { 
                    text: this.$t('headers.company-name'),
                    name: 'name',
                    align: 'start',
                    value: 'name',
                    class:'text-body-2 font-weight-bold',
                    columnClass: 'primary--text'
                },
                {
                    text: this.$t('headers.actions'),
                    name: 'actions',
                    align: 'center',
                    value: 'actions',
                    class:'text-body-2 font-weight-bold',
                    columnClass: 'primary--text'
                }
            ],
            companyId: null
        }
    },

    computed: {
        ...mapState({
            companies: state => state.companies.companies
        }),
        ...mapGetters({
            getCompanyById: 'companies/getCompanyById'
        })
    },

    methods: {
        fetchAll() {
            return this.$store.dispatch('companies/fetchAll')
        },
        submit() {
            this.loading = true
            const actionName = this.companyId ? 'update' : 'create'
            this.$store.dispatch(`companies/${actionName}`, { id: this.companyId, name: this.companyName, newName: this.companyName }).then(() => {
                this.fetchAll().then(() => {
                    this.dialog = false
                })
            }).finally(() => {
                this.loading = false
            })
        }
    },

    created() {
        this.loading = true
        this.fetchAll().finally(() => {
            this.loading = false
        })
    }
}
</script>
