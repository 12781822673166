import i18n from './i18n';

const messages = {
    en: {
        required: 'This field is required',
        name: 'This field is required',
        number: 'This number should be non-negative.',
        requiredAsNumber: 'This field is required',
        url: 'Invalid URL.',
        date: 'Invalid date. Date format is \'yyyy-mm-dd\'.',
        year: 'Invalid year.',
        month: 'Invalid month.',
        quarter: 'must be between 1-4.',
        password: 'password should be 4 characters at least',
        email: 'Invalid e-mail.',
    },
    ar: {
        required: 'الحقل مطلوب',
        name: 'الحقل مطلوب',
        number: 'مطلوب رقم صحيح غير سالب',
        requiredAsNumber: 'الحقل مطلوب',
        url: 'رابط غير صالح',
        date: 'تاريخ غير صالح. مطلوب تاريخ بالشكل \'yyyy-mm-dd\'.',
        year: 'سنة غير صالحة',
        month: 'شهر غير صالح',
        quarter: 'مطلوب ربع بين 1-4',
        password: 'مطلوب كلمة مرور 4 محارف على الأقل',
        email: 'بريد إلكتروني غير صالح',
    },
}
const $t = (key) => messages[i18n.locale][key];

// this is validations rules for vuetify component validation
export default {
    required: [val => (val || '').length > 0 || $t('required')],
    name: [val => (val || '').length > 0 || $t('name')],
    number: [val => (val >= 0 && val !== null) || $t('number')],
    requiredAsNumber: [val => (val >= 0 && val !== null) || $t('requiredAsNumber')],
    url: [
        val => {
            const regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
            return regexp.test(val) || $t('url')
        }
    ],
    date: [
        val => {
            const date = /^(19|20)[0-9]{2}[-](1[0-2]|0[1-9])[-](0[1-9]|(1|2)[0-9]|3(0|1))$/;
            return date.test(val) || $t('date')
        }
    ],
    year: [
        val => {
            const date = /^(19|20)[0-9]{2}$/;
            return date.test(val) || $t('year')
        }
    ],
    month: [val => (val >= 1 && val <= 12) || $t('month')],
    quarter: [val => (val >= 1 && val <= 4) || $t('quarter')],
    password: [val => (val || '').length >= 4 || $t('password')],
    email: [
        val => {
            const pattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(val) || $t('email')
        }
    ],
}