import * as types from '../mutation-types'

export default {
    namespaced: true,
    
    state:{
        requests:[]
    },

    actions:{
        fetchAll({ commit }, { employeeId, sectorId, type, status, fromDate, endDate, perPage, page }){
            return window.axios.get('/requests/daysoff', {
                params:{
                    employeeId, sectorId, type, status, fromDate, endDate, perPage, page
                }
            })
            .then((response) => {
                commit(types.STORE_MEDICALREP_REQUESTS, response.data)
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                };
            })
        },

        accept({ commit }, { id }){
            return window.axios.post(`/requests/daysoff/${id}/accept`);
        },

        reject({ commit }, { id }){
            return window.axios.post(`/requests/daysoff/${id}/reject`);
        },

        delete({ commit }, { id }){
            return window.axios.delete(`/requests/daysoff/${id}`);
        }
    },

    mutations:{
        [types.STORE_MEDICALREP_REQUESTS](state, requests){
            state.requests = requests;
        }
    },

    getters:{
        getRequestById: state => id => state.requests.find(c => c.id === id)
    }
}