export default {
    'new': 'New',
    'modification-requests': 'Modification requests',
    'specializations': 'Specializations',
    'classification': 'Classification',
    'edit-doctor': 'Edit doctor',
    'add-new-doctor': 'Add new doctor',
    'resident-doctors': 'Resident doctors',
    'center-specialty': "Center's specialty",
    'best-time-to-visit': 'Best time to visit',
    'please-make-sure-to-fill-out-the-fields': 'Please make sure to fill out the fields (name, specialty, address)'
}