<template>
    <div>
        <v-row> 
            <v-col class="">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.administrative-visit')}}
                </h1>
            </v-col>
        </v-row>
        
        <!-- visit dialog is declared here to use it as ref -->
        <visit-dialog ref="VisitDialog" @on-save="fetchVisits" :create="date" />
        
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="fetchVisits">
            <p class="mb-0 text-body-1">
                {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{$t('delete')}}</span> {{ $t('AdministrativeVisit.the-visit') + $t('?')}}
            </p>
        </delete-dialog>

        <!-- <view-visit-sheet ref="ViewVisitSheet"/> -->

        <!-- filter -->
        <v-row no-gutters class="justify-space-between align-center">
            <!-- month dialog -->
            <v-col md="auto" cols="12">
                <v-dialog
                    ref="dialog"
                    v-model="monthDialog"
                    :return-value.sync="selectedMonth"
                    persistent
                    max-width="300px"
                >
                    <!-- date text -->
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex justify-space-between align-center primary--text"
                        >
                            <div class="d-flex align-center">
                                <v-icon class="me-2" color="primary">mdi-calendar</v-icon>
                                <span class="text-h5 me-2">
                                    {{ moment(date, 'YYYY-MM-DD').format('MMMM')}}
                                </span>
                                <span class="text-subtitle-2 align-self-end">
                                    {{moment(date, 'YYYY-MM-DD').format('YYYY')}}
                                </span>
                            </div>

                            <v-btn
                                v-if="$vuetify.breakpoint.smAndDown"
                                color="primary"
                                small
                                outlined
                                @click="goToTodayDate"
                            >
                                {{ $t('AdministrativeVisit.today') }}
                            </v-btn>
                        </div>
                    </template>

                    <!-- dialog date picker -->
                    <v-date-picker
                        v-model="selectedMonth"
                        full-width
                        picker-date
                        type="month"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="monthDialog = false"
                        >
                            cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="
                                $refs.dialog.save(selectedMonth);
                                date = moment(selectedMonth, 'YYYY-MM').format('YYYY-MM-DD');
                                fetchVisits();
                            "
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <!-- days picker -->
            <v-col md="auto" cols="12" class="flex-grow-1">
                <v-month-days
                    v-model="date"
                    :month="selectedMonth"
                    :max-width="$vuetify.breakpoint.mdAndUp ? $vuetify.breakpoint.width - 400 : null"
                    :disabled="loading"
                    class="mx-auto"
                    @input="fetchVisits"
                />
            </v-col>

            <!-- today button -->
            <v-col cols="auto" v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                    color="primary"
                    small
                    outlined
                    @click="goToTodayDate"
                >
                    {{ $t('AdministrativeVisit.today') }}
                </v-btn>
            </v-col>
        </v-row>

        <div class="d-flex flex-wrap align-center pt-0">
            <span class="text-body-1 me-6">{{ $t('AdministrativeVisit.filter') }}</span>
            <v-chip-group
                v-model="selectedFilter"
                show-arrows
                center-active
                active-class="success"
            >
                <v-chip filter outlined :value="1">
                    {{ $t('AdministrativeVisit.doctor') }}
                </v-chip>
                <v-chip filter outlined :value="2">
                    {{ $t('AdministrativeVisit.pharmacy') }}
                </v-chip>
                <v-chip filter outlined :value="0">
                    {{ $t('AdministrativeVisit.center') }}
                </v-chip>
            </v-chip-group>

            <v-spacer/>

            <!-- add visit -->
            <v-btn
                small
                color="dark-yellow white--text"
                :disabled="!isDateMatchTodayDate"
                @click="$refs.VisitDialog.addVisit()"
            >
                <v-icon class="me-1" size="22">mdi-plus-circle-outline</v-icon>
                {{ $t('AdministrativeVisit.add-visit') }}
            </v-btn>

            <v-divider vertical class="mx-1 my-2" />

            <v-btn
                small
                color="green lighten-1 white--text"
                @click="ImportExcel"
                class="mx-1"
                :loading="loadingExcel"
                :disabled="loadingExcel || !employeeId"
            >
                <v-icon> mdi-file-table-outline </v-icon>
                {{ $t('export-excel') }}
            </v-btn>
        </div>
    
        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="loading ? [] : filteredVisits"
            :loading="loading"
            zebra
            dense
            :data-table-props="{ disableSort: true }"
            :items-per-page="-1"
            hide-default-footer
            fixed-header
            class="mt-1"
            @click:row="goToStatement"
        >
            <template v-slot:item.actions="{ item }">
                <!-- edit -->
                <tooltip :text="$t('edit')" top>
                    <v-btn
                        icon
                        small
                        color="success"
                        class="me-2"
                        @click="isEditOrDelete = true; $refs.VisitDialog.editVisit(item.id)"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>

                <!-- delete -->
                <tooltip :text="$t('delete')" top>
                    <v-btn
                        icon
                        small
                        color="red lighten-1"
                        @click="isEditOrDelete = true; $refs.DeleteDialog.activeDeleteDialog(item.id, 'mineVisits')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import Tooltip from '@/components/Tooltip'
import VisitDialog from '@/components/VisitDialog'
// import VisitCard from '@/components/VisitCard'
// import ViewVisitSheet from '../components/ViewVisitSheet'
import VMonthDays from '@/components/VMonthDays.vue'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { userType } from '@/helpers/enums'
export default {
	data() {
		return {
            loadingExcel: false,
			monthDialog: false,
			loading: true,
			selectedFilter: null,
			selectedMonth: moment().format('YYYY-MM'),
			date: moment().format('YYYY-MM-DD'),
            isEditOrDelete: false
        }
	},

    components: {
		DeleteDialog,
		Tooltip,
		VisitDialog,
		VMonthDays
	},

	computed: {
		filteredVisits() {
			if (this.selectedFilter !== null && this.selectedFilter !== undefined) {
				return this.visits.filter(c => c.entity.entityType === this.selectedFilter);
			} else {
				return this.visits;
			}
		},

		...mapState({
			visits: state => state.mineVisits.visits,
            regions: state => state.regions.regions,
            cities: state => state.cities.cities,
            employeeId: state => state.auth.userData.employeeId
		}),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById'
        }),
        
        isDateMatchTodayDate() {
            return (this.date === moment().format('YYYY-MM-DD')) || (this.date === moment().subtract(1, 'days').format('YYYY-MM-DD')) || (this.date === moment().subtract(2, 'days').format('YYYY-MM-DD')) || (this.date === moment().subtract(3, 'days').format('YYYY-MM-DD'));
        },

        // isDateMatchTodayDateOrThreeDayPre() {

        // },

        headers() {
            const headers = [
                {   
                    text: this.$t('headers.entity-name'),
                    name: 'entityName',
                    value: 'entity.name',
                },
                {
                    text: this.$t('headers.specialty'),
                    name: 'entitySpecialty',
                    value: 'entitySpecialty.name'
                },
                {
                    text: this.$t('headers.sample-name'),
                    name: 'sample',
                    value: 'materialAmounts',
                    setValueExpr: val => val.map(c => this.getMaterialById(c.materialId)?.name).join(', ')
                },
                {
                    text: this.$t('headers.sample-amount'),
                    name: 'amount',
                    value: 'materialAmounts',
                    setValueExpr: val => val.map(c => c.amount).join(', ')
                },
                {
                    text: this.$t('headers.other-materials'),
                    name: 'otherSamples',
                    value: 'otherSamples'
                },
                {
                    text: this.$t('headers.core-message'),
                    name: 'coreMessage',
                    value: 'coreMessage'
                },
                {
                    text: this.$t('headers.objection'),
                    name: 'objections',
                    value: 'objections'
                },
                {
                    text: this.$t('headers.duration'),
                    name: 'durationInMinutes',
                    value: 'durationInMinutes',
                    noNotAvailableText: true,
                    setValueExpr: val => val + ' min' 
                },
            ];

            if (this.isDateMatchTodayDate) {
                headers.push({
                    text: this.$t('headers.actions'),
                    name: 'actions',
                    value: 'actions',
                    width: 100,
                    sortable: false
                })
            }
            return headers
        }
	},

    methods: {
		moment,

        // goToStatement(item) {
        //     if (this.isEditOrDelete === false) {
        //         this.$router.replace({
        //             name: 'samples-statement',
        //             query: {
        //                 id: item.materialAmounts[0].materialId
        //             }
        //         })
        //     }
        // },
		
		fetchVisits() {
            this.loading = true;
			this.$store.dispatch('mineVisits/fetchAllData', { day: this.date }).finally(() => {
                this.loading = false;
            })
		},

		editPrevent(message) {
			this.$eventBus.$emit('show-snackbar', message, 'info');
		},

		goToTodayDate() {
			const todayDate = moment().format('YYYY-MM-DD');
			if (this.date !== todayDate) {
                this.selectedMonth = moment().format('YYYY-MM');
                this.date = todayDate;
                this.fetchVisits();
			}
		},

        ImportExcel() {
            this.loadingExcel = true
            this.$store.dispatch('exporter/fetchVisitsExport', { 
                FromDate: this.date, 
                ToDate: this.date, 
                EmployeeId: this.employeeId,
            }).finally(() => {
                this.loadingExcel = false
            })
        },
	},

	created() {
		this.loading = true;
		Promise.all([
			this.$store.dispatch('mineVisits/fetchAllData', { day: moment().format('YYYY-MM-DD') }),
			this.$store.dispatch('materials/fetchAll', {}),
			this.$store.dispatch('materialTransactions/fetchAll', { queryType: userType.supervisor }),
			this.$store.dispatch('materialForms/fetchAll'),
            this.$store.dispatch('cities/fetchCityMine').then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data });
            })
		]).finally(() => {
            this.loading = false;
        })
	},

    metaInfo: {
        title: 'Administrative visit',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style lang="scss">
</style>