export default {
    'modify-details': 'Modify details',
    'addresses': 'Addresses',
    'modification-reason': 'Modification reason',
    'confirm-acceptance': 'Confirm acceptance',
    'confirm-rejection': 'Confirm rejection',
    'accept': 'Accept',
    'reject': 'Reject',
    'this-request': 'this request',
    'request-reason': 'Request reason',
    'requested-by': 'Requested by',
    'request-type': 'Request Type',
    'view-modify-details': 'View modify details',
    'accept-request': 'Accept request',
    'reject-request': 'Reject request'
}