<template>
    <div>
        <div class="d-flex justify-space-between mb-1">
            <!-- activate dialog button -->
            <v-btn
                v-if="isSuperAdmin || permissionsUser.GenerateMaterials === roleName.action"
                small
                color="dark-yellow white--text"
                elevation="2"
                class="me-2"
                @click="addPharmaceuticalForm"
            >
                <v-icon class="me-2">
                    mdi-plus-circle-outline
                </v-icon>
                {{$t('PharmaceuticalForms.add-category')}}
            </v-btn>

            <!-- BACK -->
            <v-btn
                color="grey darken-2 white--text"
                small
                :to="{ name: 'products' }"
            >
                <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                {{$t('back')}}
            </v-btn>
        </div>

        <!-- dialog is decleared here to use it as ref -->
        <v-dialog
            transition="dialog-transition"
            max-width="350"
            persistent
            ref="dialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form">
                    <v-card>
                        <div class="d-flex justify-end py-1 background-title-card">
                            <v-card-title
                                class="text-uppercase text-subtitle-1 d-flex justify-center w-100 px-0 py-0 white--text"
                            >
                                {{
                                    !itemId
                                        ? $t('PharmaceuticalForms.add-category')
                                        : $t('PharmaceuticalForms.edit-category')
                                }}
                            </v-card-title>
                            <!-- close button -->
                            <v-btn icon @click="itemId = null; dialog.value = false; $refs.form.resetValidation()">
                                <v-icon color="white">
                                    mdi-close-circle-outline
                                </v-icon>
                            </v-btn>
                        </div>

                        <v-card-text class="pa-3 pb-0">
                            <!-- specialty name -->
                            <v-text-field
                                v-model="materialFormName"
                                :label="$t('inputs.name')"
                                clearable
                                :rules="rules.required"
                                :disabled="submitLoading"
                                required
                                dense
                                outlined
                                hide-details="auto"
                            ></v-text-field>
                        </v-card-text>
                        <v-card-actions class="justify-end">
                            <v-btn
                                type="submit"
                                color="success"
                                :loading="submitLoading"
                                :disabled="submitLoading"
                                text
                            >
                                {{$t('save')}}
                            </v-btn>
                            <v-btn
                                @click="itemId = null; dialog.value = false; $refs.form.resetValidation()"
                                elevation="0"
                                text
                            >
                                {{$t('cancel')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog
            ref="deleteDialog"
            :success-message="$t('data-is-deleted')"
            @on-delete="fetchMaterialForms"
        >
            {{$t('are-you-sure-you-want-to')}} <span class="red--text">{{$t('delete')}}</span> {{$t('PharmaceuticalForms.the-category')}}
        </delete-dialog>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? pharmaceuticalForms : []"
            :loading="loading"
            :height="$vuetify.breakpoint.height - 115"
            :items-per-page="20"
            fixed-header
            pagination-on-scroll
            dense
        >
            <!-- action column will appaer only for admin -->
            <template v-slot:item.action = {item}>
                <v-btn icon small @click="itemId = item.id; editPharmaceuticalForm(item.id)">
                    <v-icon color="edit-color">
                        mdi-pencil  
                    </v-icon>
                </v-btn>
                <v-btn icon small @click="$refs.deleteDialog.activeDeleteDialog(item.id, 'materialForms')">
                    <v-icon color="alert-color">
                        mdi-delete  
                    </v-icon>
                </v-btn>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import rules from '@/validation rules'
import { mapState, mapGetters } from 'vuex'
import { roleName } from '@/helpers/enums'

export default {
    name: 'PharmaceuticalForms',

    components:{
        DeleteDialog
    },

    data(){
        return {
            rules,
            roleName,
            loading: false,
            submitLoading: false,
            itemId: false,

            // data
            materialFormId: null,
            materialFormName: null
        }
    },

    computed:{
        ...mapState({
            pharmaceuticalForms: state => state.materialForms.materialForms,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        ...mapGetters({
            isAdmin: 'auth/isAdmin',
            getMaterialFormById: 'materialForms/getMaterialFormById',
        }),

        headers() {
            const headers = [
                { 
                    text: this.$t('headers.category'), 
                    align: 'start',
                    value: 'name',
                    class:'text-body-2 font-weight-bold',
                    columnClass: 'primary--text'
                },
            ]

            if (this.isSuperAdmin || this.permissionsUser.GenerateMaterials === roleName.action){
                headers.push({
                    text: this.$t('headers.actions'), 
                    name: 'action',
                    value: 'action',
                    align: 'center',
                    class:'text-body-2 font-weight-bold',
                    sortable: false
                });
            }

            return headers;
        },
    },

    methods:{
        fetchMaterialForms() {
            this.loading = true;
            this.$store.dispatch('materialForms/fetchAll')
            .finally(() => {
                this.loading = false;
            });
        },
        
        // dialog functions
        reset(){
            if (this.$refs.form) this.$refs.form.resetValidation();

            this.materialFormId = null;
            this.materialFormName = null;
        },

        addPharmaceuticalForm(){
            this.reset();
            this.$refs.dialog.isActive = true;
        },

        editPharmaceuticalForm(id){
            this.reset();
            this.materialFormId = id;
            this.materialFormName = this.getMaterialFormById(id).name;

            this.$refs.dialog.isActive = true;
        },

        submit(){
            if (this.$refs.form.validate()){
                this.submitLoading = true;
                
                const actionName = !this.materialFormId ? 'create' : 'update';
                this.$store.dispatch(`materialForms/${actionName}`, {
                    id: this.materialFormId,
                    name: this.materialFormName
                }).then(() => {
                    const message = 'The pharmaceutical form is ' + (!this.materialFormId ? 'created' : 'edited');
                    this.$eventBus.$emit('show-snackbar', message, 'info');
                    this.fetchMaterialForms();
                    this.$refs.dialog.isActive = false;
                }).finally(() => {
                    this.submitLoading = false;
                })
            }
        },
    },

    mounted(){
        this.fetchMaterialForms();
    },

    metaInfo: {
        title: 'Pharmaceutical forms',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

<style>
#pharmaceutical-forms-table thead > tr > th{
    font-size: .9rem;
}
</style>