<template>
    <div>
        <v-row>
            <v-col class="pb-0">
                <h1 class="text-h6 primary--text text-capitalize">
                    {{$t('routes.materials')}}
                </h1>
            </v-col>
        </v-row>
        <!-- filter -->
        <v-row class="mt-1">
            <v-col cols="6" sm="4"  md="3" align-self="center" class="py-0">
                <v-autocomplete
                    v-model="materialId"
                    :items="materials"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.item')"
                    outlined
                    dense
                    hide-details
                    multiple
                    :loading="loading"
                    :disabled="loading"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ materialId.length - 1 }})
                        </span>
                    </template>   
                </v-autocomplete>
            </v-col>

            <v-col cols="6" sm="4"  md="2" align-self="center" class="py-0">
                <v-autocomplete
                    v-model="medicalRepId"
                    :items="medicalReps"
                    item-text="name"
                    item-value="id"
                    :label="$t('inputs.medical-rep')"
                    outlined
                    dense
                    hide-details
                    multiple
                    :loading="loading"
                    :disabled="loading"
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ medicalRepId.length - 1 }})
                        </span>
                    </template>   
                </v-autocomplete>
            </v-col>

            <v-col cols="auto" class="pa-0 pb-1" align-self="center">
                <v-radio-group
                    v-model="dateType"
                    row
                    class="mt-0"
                    hide-details
                    :disabled="loading"
                >
                <v-radio
                    :label="$t('daily-date')"
                    value="1"
                ></v-radio>
                <v-radio
                    :label="$t('from-date-to-date')"
                    value="2"
                ></v-radio>
                </v-radio-group>
            </v-col>

            <v-col v-if="dateType === '2'" cols="6" sm="3"  md="2"  class="pt-2" align-self="center">
                <bee-date-picker
                    v-model="startDate"
                    input-label="Date"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true,
                        disabled: loading
                    }"
                ></bee-date-picker>
            </v-col>

            <!-- end date -->
            <v-col v-if="dateType === '2'" cols="6" sm="3"  md="2"  class="py-0" align-self="center">
                <bee-date-picker
                    v-model="endDate"
                    input-label="Date"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true,
                        disabled: loading
                    }"
                ></bee-date-picker>
            </v-col>

            <!-- filter -->
            <v-col v-if="dateType === '1'" cols="6" sm="5"  md="3" class="pa-1 py-0" align-self="center">
                <bee-date-input
                    v-model="date"
            	    :append-icon="$i18n.locale === 'en' ? 'mdi-menu-right' : 'mdi-menu-left'"
					:prepend-icon="$i18n.locale === 'en' ? 'mdi-menu-left' : 'mdi-menu-right'"
                    :label="$t('inputs.date')"
                    input-format-order="DD-MM-YYYY"
                    dense
                    :loading="loading"
                    hide-details
                    @click:append="addDate(); getMaterial(false)"
                    @click:prepend="subDate(); getMaterial(false)"
                    :input-props="{
                        disabled: loading
                    }"
                ></bee-date-input>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="mb-2 mt-0" align-self="center">
                <v-btn @click="getMaterial(false)" color="success" small>
                    {{$t('search')}}
                </v-btn>
                <v-btn @click="resetFilter()" color="alert-color white--text" class="mx-1" small>
                    {{$t('reset')}}
                </v-btn>
            </v-col>
        </v-row>    
        <bee-handy-table
            :headers="headers"
            :items="materialsForMedicalRep"
            zebra
            dense
            :height="$vuetify.breakpoint.height - 120"
            pagination-on-scroll
            :shown-rows-text="$t('shown-rows')"
            @click:row="goToStatement"
        ></bee-handy-table>
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
export default {
    data() {
        return {
            dateType: '1',
            date: moment().format('YYYY-MM-DD'),
            startDate: moment().startOf('month').format('YYYY-MM-DD'),
            endDate: moment().endOf('month').format('YYYY-MM-DD'),  
            loading: false,
            materialId: [],
            medicalRepId: [],
            items: [
                {
                    material: 'Voltarin 200k',
                    amount: 2,
                    medicalRepName: 'Youssef'
                },
                {
                    material: 'Setamol',
                    amount: 10,
                    medicalRepName: 'Mohammad'
                },
                {
                    material: 'Cortizol',
                    amount: 5,
                    medicalRepName: 'Malak'
                },
            ],
            materialsForMedicalRep: []
        }
    },

    computed: {
        ...mapState({
            materials: state => state.materials.materials,
            medicalReps: state => state.medicalreps.medicalreps,
            // materialsForMedicalRep: state => state.visitsPlan.materialsForMedicalRep
        }),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
            getMedicalRepById: 'medicalreps/getMedicalRepById'
        }),

        headers () {
            return [
                {
                    text: this.$t('headers.material'),
                    name: 'material',
                    value: 'materialId',
                    setValueExpr: val => this.getMaterialById(val)?.name
                },
                {
                    text: this.$t('headers.amount'),
                    name: 'totalAmount',
                    value: 'totalAmount'
                }
                // {
                //     text: this.$t('headers.medical-rep-name'),
                //     name: 'employeeId',
                //     value: 'employeeId',
                //     setValueExpr: val => this.getMedicalRepById(val)?.name
                // }
            ]
        }
    },

    methods: {
        moment,

        goToStatement(item) {
            this.$router.replace({
                name: 'samples-statement',
                query: {
                    id: item.materialId
                }
            })
        },
        
        addDate () {
            this.date = moment(this.date).add(1, 'day').format('YYYY-MM-DD');
            return this.date
        },

        subDate() {
            this.date = moment(this.date).subtract(1, 'day').format('YYYY-MM-DD')
            return this.date
        },

        getMaterial (isFirst) {
            this.loading = true
            this.$store.dispatch('visitsPlan/fetchMateriaForMedicalRep', { 
                materialIds: this.materialId, 
                employeeIds: this.medicalRepId, 
                FromDate: this.dateType === '2' ? this.startDate : moment(this.date).format('YYYY-MM-DD'), 
                ToDate: this.dateType === '2' ? this.endDate : moment(this.date).format('YYYY-MM-DD'),
                IsConsumed: false
            }).then((data) => {
                // console.log(data.data)
                this.materialsForMedicalRep = data.data
            }).finally(() => {
                if (!isFirst) this.loading = false
            })
        },
        resetFilter() {
            this.materialId = []
            this.medicalRepId = []
            this.startDate = moment().startOf('month').format('YYYY-MM-DD')
            this.date = moment().format('YYYY-MM-DD')
            this.endDate = moment().endOf('month').format('YYYY-MM-DD')  
        }
    },

    created() {
        this.loading = true
        Promise.all([
            this.getMaterial(true),
            !this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
        ]).finally(() => {
            this.loading = false
        })
    }
}
</script>
