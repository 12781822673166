import * as types from '../mutation-types';

export default {
	namespaced: true,
	state: {
        regions: [],
        regionsAll: []
	},

	actions: {
		fetchAll({ commit }, { cityId }) {
            return window.axios.get(`/cities/${cityId}/regions`).then(response => {
                commit(types.STORE_REGIONS, response.data);
                return response.data;
            });
        },

        fetchAllRegions({ commit }, { cityId }) {
            return window.axios.get('/Regions/Mine', { query: { cityId } }).then((response) => {
                commit(types.STORE_REGIONS_ALL, response.data)
            })
        },

        clearRegions({ commit }){
            commit(types.CLEAR_REGIONS);
        },
        
		// fetchAllRegions({ commit }, { cities }) {
        //     var cityIds = cities.map(city => city.id);
        //     var promises = []
        //     cityIds.forEach(id => {
        //         promises.push(
        //             window.axios.get(`/cities/${id}/regions`).then(response => { 
        //                 commit(types.STORE_REGIONS_ALL, response.data);
        //             })
        //         )
        //     });
        //     return Promise.all(promises);
		// },
	
        create({ commit }, { name, cityId }) {
            return window.axios.post(`/cities/${cityId}/regions`, {
                name,
                cityId
            });
        },

        update({ commit }, { id, name, cityId }) {
            return window.axios.put(`/cities/${cityId}/regions/${id}`, {
                name,
                cityId
            });
        },

        delete({ commit }, { cityId, id }){
            return window.axios.delete(`/cities/${cityId}/regions/${id}`);
        },
	},

    mutations: {
		[types.STORE_REGIONS](state, regions){
			state.regions = regions;
        },

		[types.CLEAR_REGIONS](state){
			state.regions = [];
        },
        
		[types.STORE_REGIONS_ALL](state, regionsAll){
            state.regionsAll.push(regionsAll);
            state.regionsAll = state.regionsAll.flat();
		},
	},

	getters: {
		getRegionById: state => id => state.regions.find(c => c.id === id),
		getRegionAllById: state => id => state.regionsAll.find(c => c.id === id),
        getRegionAllByName: state => name => state.regionsAll.find(c => c.name === name)
	},
}