export default {
    'show-regions': 'عرض المناطق',
    'edit-city': 'تعديل المدينة',
    'city': 'مدينة',
    'region': 'منطقة',
    'area': 'قطاع',
    'fa-this': 'هذه',
    'ma-this': 'هذا',
    'created': 'الإنشاء',
    'edited': 'التعديل '
}