import * as types from '../mutation-types'

export default {
    namespaced: true,

    state: {
        // plannedVisits: { plannedVisits:[] },
        plannedVisitsByEmployeeId: [],
        plan: [],
        myPlan: [],
        materialsForMedicalRep: []
    },

    actions: {
        fetchAll ({ commit }) {
            // return window.axios.get('/plannedVisits').then((data) => {
            //     commit(types.STORE_VISITS_PLAN, data)
            //     return data
            // })
        },

        fetchByDate ({ commit }, { toDate, fromDate }) {
            return window.axios.get('/visitsPlan/ByDate', { params: { toDate, fromDate } }).then(({ data }) => {
                return data
            })
        },

        fetchByRepName ({ commit }, { employeeId }) {
            return window.axios.get(`/visitsPlans/${employeeId}`).then(({ data }) => {
                return data
            })
        },

        create ({ commit }, { entityId, date, visitPlanId, materialsAmount }) {
            return window.axios.post('/plannedVisits', { entityId, date, visitPlanId, materialsAmount }).then(({ data }) => {
                return data
            })
        },

        createMultiSelect ({ commit }, { entityId, date, visitPlanId }) {
            return window.axios.post('/plannedVisits/MultiSelect', { entityId, date, visitPlanId }).then((data) => {
                return data
            })
        },

        // update visit in plan
        update({ commit }, { id, entityId, date, visitPlanId, materialsAmount }) {
            return window.axios.put(`/plannedVisits/${id}`, { entityId, date, visitPlanId, materialsAmount }).then(({ data }) => {
                return data
            })
        },

        getPlanByMedicalRep ({ commit }, { employeeId }) {
            return window.axios.get(`/visitsPlan/${employeeId}`).then(({ data }) => {
                return data
            })
        },

        fetchPlannedVisitsByEmployeeId ({ commit }, { employeeId, fromDate }) {
            return window.axios.get(`plannedVisits/employee/${employeeId}`, { params: { fromDate } }).then(({ data }) => {
                commit(types.STORE_PLANNED_VISITS_PLAN, data)
                return data
            })
        },

        delete ({ commit }, { id }) {
            return window.axios.delete(`plannedVisits/${id}`)
        },

        allPlan ({ commit }, { employeeId }) {
            return window.axios.get(`visitsPlan/${employeeId}/All`).then(({ data }) => {
                commit(types.STORE_VISITS_PLAN, data)
                return data
            })
        },

        getPlanMine({ commit }) {
            return window.axios.get('/visitsPlan/Mine').then(({ data }) => {
                commit(types.STORE_MY_PLAN, data)
                return data
            })
        },

        // fetch material to plan medical rep
        fetchMateriaForMedicalRep ({ commit }, { materialIds, employeeIds, FromDate, ToDate, IsConsumed }) {
            // return window.axios.get('plannedVisits/PlannedMaterials', { params: { materialIds, employeeIds, fromDate, toDate } }).then(({ data }) => {
            //     commit(types.STORE_MATERIALS_MEDICAL_REP, data)
            //     return data
            // })
            var urlSearchParams = new URLSearchParams();
            var params = { FromDate, ToDate, IsConsumed }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });

            materialIds.forEach(id => {
                urlSearchParams.append('materialIds', id)
            })
            employeeIds.forEach(id => {
                urlSearchParams.append('employeeIds', id)
            })

            return window.axios.get('plannedVisits/plannedMaterials', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                }
            })
        },
        
        //  fetch materials loaned
        fetchMaterialLoaned ({ commit }, { materialId, employeeIds, fromDate, toDate, IsConsumed, EntityId }) {
            // return window.axios.get('plannedVisits/PlannedMaterials', { params: { materialIds, employeeIds, fromDate, toDate } }).then(({ data }) => {
            //     commit(types.STORE_MATERIALS_MEDICAL_REP, data)
            //     return data
            // })
            var urlSearchParams = new URLSearchParams();
            var params = { materialId, fromDate, toDate, IsConsumed, EntityId }

            Object.keys(params).forEach(param => {
                if (params[param] !== null && params[param] !== undefined) urlSearchParams.append(param, params[param])
            });
        
            employeeIds.forEach(id => {
                urlSearchParams.append('employeeIds', id)
            })

            return window.axios.get('plannedVisits/LoanedMaterials', {
                params: urlSearchParams
            })
            .then((response) => {
                return {
                    data: response.data,
                    total: parseInt(response.headers['x-pagination-total-count'])
                }
            })
        },

        createPlan ({ commit }, { employeeId, fromDate, toDate }) {
            return window.axios.post('/visitsPlan', { employeeId, fromDate, toDate, active: true }).then(({ data }) => {
                return data
            })
        },

        // fetchMaterialLoaned ({ commit }, { materialIds, employeeIds, fromDate, toDate }) {
        //     return window.axios.get('plannedVisits/LoanedMaterials', { params: { materialIds, employeeIds, fromDate, toDate } }).then(({ data }) => {
        //         commit(types.STORE_MATERIALS_MEDICAL_REP, data)
        //         return data
        //     })
        // },
    },

    mutations: {
        [types.STORE_VISITS_PLAN] (state, plan) {
            state.plan = plan
        },
        [types.STORE_PLANNED_VISITS_PLAN] (state, plannedVisitsByEmployeeId) {
            state.plannedVisitsByEmployeeId = plannedVisitsByEmployeeId
        },
        [types.STORE_MY_PLAN] (state, myPlan) {
            state.myPlan = myPlan
        },

        [types.STORE_MATERIALS_MEDICAL_REP](state, materialsForMedicalRep) {
            state.materialsForMedicalRep = materialsForMedicalRep
        }
    },

    getters: {
        getPlanById: state => id => state.plan.find(c => c.id === id),
        getVisitById: state => id => state.plannedVisitsByEmployeeId.find(c => c.id === id),
        getPlanByDate: state => date => state.plan.find(c => c.createAt === date)
    }
}