export default {
    'new-administrative-requests' :'طلبات إدارية جديدة',
    'leave-requests': 'الطلبات المعلقة',
    'administrative-day': 'يوم إداري',
    'modification-requests': 'طلبات التعديل',
    'modification': 'تعديل',
    'addition': 'إضافة',
    'deletion': 'حذف',
    'office-visits-yesterday': 'زيارات المكتب أمس',
    'planned-visits': 'الزيارات المخطط لها',
    'visits-done': 'الزيارات المحققة',
    'month-visits': 'الزيارات الشهرية',
    'yesterday-visits': 'زيارات الأمس',
    'customer-addition-requests': 'طلبات إضافة عملاء',
    'customer-deletion-requests': 'طلبات حذف عملاء',
    'number-of-visits-for-the-last-week-according-to-classification': 'عدد الزيارات للأسبوع الماضي حسب التصنيف',
    'outgoing-and-remaining-materials': 'المواد الصادرة و المتبقية',
    'number-of-visits-for-the-last-week-depending-on-the-number-of-customers': 'عدد الزيارات تبعاً لعدد العملاء (الأسبوع الماضي)'
}